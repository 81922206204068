// Dependencies
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  title: {
    lineHeight: '56px',
    marginBottom: theme.spacing(2)
  }
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Dashboard';
    // Innit state
    this.state = {};
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h2" component="h2" className={classes.title}>
          Dashboard
        </Typography>
        <ul className="tools">
          <li><Link to="/admin/users">Users</Link></li>
          <li><Link to="/admin/backups">Backups</Link></li>
          <li><Link to="/admin/dev">Dev</Link></li>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/logout">Logout</Link></li>
        </ul>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Index);
