// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Table from './Table';

const styles = theme => ({
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      loading: true,
      data: [],
    };
    // bind functions
    this.getData = this.getData.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  getData() {
    const { currency } = this.state;

    axios.get('/api/currencies', { params: { currency: currency }, cancelToken: this.cancelToken.token })
      .then(res => {
        console.log(res);
        this.setState({
          loading: false,
          data: res.data.values
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          data: []
        });
      });
  }

  // custom functions


  // lifecycle methods

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { classes } = this.props;
    const { loading, data, currency } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Currencies
            </Typography>
            <Typography variant="h3" component="h3">
              Delivering real-time exchange rate data.
            </Typography>
            {loading ? (
              <CircularProgress
                color="secondary"
                size={40}
                className={classes.progress}
              />
            ):(
              <Table
                data={data}
              />
            )}
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography container="div">
              Powered by 15+ exchange rate data sources, the Fixer API is capable of delivering real-time exchange rate data for 170 world currencies.
            </Typography>
          </Container>
        </section>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
