// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as projectActions from '../../redux/actions/projects';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const styles = {
  box: {
    position: 'relative',
    paddingTop: '100%',
  },
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: '#111',
  },
  link: {
    display: 'block',
    padding: '20px',
  }
};

// Components

const BoxComponent = (props) => {
  const { classes, project } = props;

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <div className={classes.box} data-aos="fade-up">
        <div className={classes.content}>
          <Link to={`/project/${project.id}`} className={classes.link}>{project.title}</Link>
        </div>
      </div>
    </Grid>
  );
};
const Box = withStyles(styles)(BoxComponent);


const BoxList = (props) => {
  const { projects } = props;

  return (
    <Grid container spacing={0}>
      {_.map(projects, (project) =>
        <Box key={`box-${project.id}`} project={project} />
      )}
    </Grid>
  );
};


class Portfolio extends Component {
  // construct
  constructor(props, context) {
    super(props, context);
    // Innit state
    this.state = {};
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    //const { match: {params}, projects } = this.props;
    const { projects } = this.props;

    return (
      <Fragment>
        <Typography variant="h6" color="inherit">
          Portfolio
        </Typography>
        <BoxList projects={projects}/>
      </Fragment>
    );
  }
}

// props validation
Portfolio.propTypes = {
  actions: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
};

// connect Redux & related methods
const mapStateToProps = (state, ownProps) => {
  return {
    projects: state.projects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(projectActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
