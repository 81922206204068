// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';
import _ from 'lodash';

// Components
import List from './List';
import Form from './Form';
import ListTop from '../../Global/ListTop';
import DataSet from '../../Global/DataSet';
import SnackBar from '../../Global/SnackBar';
import Dialog from '../../Global/Dialog';

import CircularProgress from '@material-ui/core/CircularProgress';


export default class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Works';
    // State
    this.state = {
      loading: true,
      models: []
    };
    // create token for cancel axios request
    this.cancelToken = CancelToken.source();
    // Bind functions
    this.getModels = this.getModels.bind(this);
    this.createModel = this.createModel.bind(this);
    this.activeModel = this.activeModel.bind(this);
    this.removeModel = this.removeModel.bind(this);
    this.updateModel = this.updateModel.bind(this);

    this.viewData = this.viewData.bind(this);
    this.viewForm = this.viewForm.bind(this);
  }

  getModels() {
    axios.get('/api/works', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ models: res.data.models, loading: false });
      })
      .catch(err => {
        const error = err.response.data.message || err.message;
        this.snackbar.openSnackBar('error', error);
      });
  }

  createModel(values) {
    if (values) {
      axios.post('/api/works', values, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getModels();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Work created ok!');
        })
        .catch(err => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  activeModel(model) {
    if (model) {
      const id = model._id.toString();
      const values = { active: !model.active };

      axios.put('/api/works/'+id, values, { cancelToken: this.cancelToken.token })
        .then(res => {
          //this.getModels();
          const models = _.map(this.state.models, (o,i) => {
            if (o._id.toString() === id) {
              o = res.data.model; //!o.active;
            }
            return o;
          });
          this.setState({
            models: models
          });
          this.snackbar.openSnackBar('success', 'Work updated ok!');
        })
        .catch(err => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  updateModel(model,values) {
    console.log('update model...',values);
    if (model && values) {
      const id = model._id.toString();

      axios.put('/api/works/'+id, values, { cancelToken: this.cancelToken.token })
        .then(res => {
          //this.getModels();
          const models = _.map(this.state.models, (o,i) => {
            if (o._id.toString() === id) {
              o = res.data.model; //!o.active;
            }
            return o;
          });
          this.setState({ models: models });
          this.snackbar.openSnackBar('success', 'Work updated ok!');
        })
        .catch(err => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  removeModel(model) {
    if (model) {
      const id = model._id.toString();

      axios.delete('/api/works/'+id, { cancelToken: this.cancelToken.token })
        .then(res => {
          //this.getModels();
          this.setState({ models: _.filter(this.state.models, (o,i) => o._id.toString() !== id ) });
          this.snackbar.openSnackBar('success', 'Work deleted ok!');
        })
        .catch(err => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  /* Modals */

  viewData(model) {
    const title = 'JSON';
    const content = <DataSet data={model} />;
    this.dialog.openDialog(title, content);
  }

  viewForm(model) {
    const title = (model ? 'Update work' : 'New work');
    const content = <Form onSubmit={model ? this.updateModel.bind(this, model) : this.createModel} model={model ? model : false} />;
    this.dialog.openDialog(title, content);
  }

  // lifecycle methods

  componentDidMount() {
    // First call
    this.getModels();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  render() {
    const { models, loading } = this.state;

    const actionsTop = [
      {
        label: 'Create New',
        action: this.viewForm.bind(this,false),
      }
    ];

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <ListTop
          title='Portfolio'
          actions={actionsTop}
        />
        {loading ? (
          <CircularProgress
            color="secondary"
            size={40}
          />
        ) : (
          <List
            models={models}
            activeModel={this.activeModel}
            viewData={this.viewData}
            viewForm={this.viewForm}
            removeModel={this.removeModel}
          />
        )}
      </Fragment>
    );
  }
}
