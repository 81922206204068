// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Cropper from './Cropper';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';


const styles = theme => ({
  wrap: {
    height: '450px',
    margin: '30px 0'
  }
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      cropper: {},
      image: '/img/3.jpeg'
    };
    // Bind functions
    this.updateCropper = this.updateCropper.bind(this);
    this.updateImage = this.updateImage.bind(this);
  }

  // custom functions

  updateCropper(data) {
    this.setState({ cropper: data })
  }

  updateImage(data) {
    this.setState({ image: data })
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;
    const { image } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Crop
            </Typography>
            <Typography variant="h3" component="h3">
              Crop images
            </Typography>
            <div className={classes.wrap}>
              <FullScreen>
                <Cropper
                  image={image}
                  updateImage={this.updateImage}
                  updateCropper={this.updateCropper}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} title="State"/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
