// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
//import ReactJson from 'react-json-view';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../Global/Container';

const styles = {
  root: {
    background: '#E0E0E0',
    padding: '40px 0'
  },
  title: {
    margin: 0
  }
};


const DataSet = (props) => {
  //const { classes, data, title } = props;
  const { classes, title, data } = props;
  // render
  return (
    <Fragment>
      <section className={classes.root}>
        <Container fixed>
          <Typography variant="h5" component="h5" className={classes.title}>
            {title}
          </Typography>
          {/*<ReactJson src={data} enableClipboard={false} />*/}
        </Container>
      </section>
    </Fragment>
  );
}

DataSet.defaultProps = {
  title: 'Dataset'
};

DataSet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DataSet);
