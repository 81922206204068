// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from '@material-ui/icons/SortByAlpha';
import ListIcon from '@material-ui/icons/Reorder';
import GridIcon from '@material-ui/icons/Apps';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import Fade from '@material-ui/core/Fade';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});


const options = [
  {
    key: 'name',
    text: 'Alpha'
  },
  {
    key: 'date',
    text: 'Date'
  },
  {
    key: 'ext',
    text: 'Type'
  },
  {
    key: 'size',
    text: 'Size'
  },
];


class ListItemActions extends Component {
  // construct
  constructor(props) {
    super(props);
    // Init state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeSort = this.handleChangeSort.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
  }

  // custom functions

  handleClick(e) {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget
    });
  }

  handleClose(e) {
    e.stopPropagation();
    this.setState({
      anchorEl: null
    });
  }

  handleChangeSort(value,e) {
    const { changeSort } = this.props;
    e.stopPropagation();
    changeSort(value);
  }

  handleChangeView(value,e) {
    const { changeView } = this.props;
    e.stopPropagation();
    changeView(value);
  }

  // lifecycle methods

  render() {
    const { anchorEl } = this.state;
    const { className, view, sort, sortDirection } = this.props;

    // list item icon
    const iconItem = (key) => {
      let icon = '';
      if (sort === key) {
        if (sortDirection === 'asc') {
          icon = <UpIcon />;
        } else {
          icon = <DownIcon />;
        }
      }
      return icon;
    }

    // render
    return (
      <div className={className}>
        <IconButton
          aria-label="Item Actions"
          onClick={this.handleClick}
        >
          <SortIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          onChange={this.handleClose}
          TransitionComponent={Fade}
          PaperProps={{
            style: {
              maxHeight: 250,
              width: 150,
            },
          }}
        >
          {options.map(option => (
            <MenuItem key={option.key} onClick={(e) => this.handleChangeSort(option.key,e)}>
              {option.text}
              {iconItem(option.key)}
            </MenuItem>
          ))}
        </Menu>
        {view === 'grid' ? (
          <IconButton aria-label="Change View" onClick={(e) => this.handleChangeView('list',e)}>
            <GridIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="Change View" onClick={(e) => this.handleChangeView('grid',e)}>
            <ListIcon />
          </IconButton>
        )}
      </div>
    )
  }
}

// props validation
ListItemActions.defaultProps = {
  className: '',
  sort: 'alpha',
  sortDirection: 'asc',
  view: 'grid'
};

ListItemActions.propTypes = {
  className: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  changeSort: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListItemActions);
