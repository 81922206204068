// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactJson from 'react-json-view';

// Components
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';


const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);


const List = (props) => {
  const { collections, dropCollection, renameCollection, download, reIndex, dropIndex, createIndex } = props;

  const listItems = _.map(collections, (item, key) => {
    return (
      <ExpansionPanel key={`panel_${item.name}`}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
          <Typography>{item.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ReactJson src={item} enableClipboard={false} />
          <div>
          </div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button size="small" onClick={() => download(item.name)}>Download</Button>
          <Button size="small" onClick={() => dropCollection(item.name)}>Drop</Button>
          <Button size="small" onClick={() => renameCollection(item.name)}>Rename</Button>
          <Button size="small" onClick={() => reIndex(item.name)}>Reindex</Button>
          <Button size="small" onClick={() => dropIndex(item.name)}>Drop Index</Button>
          <Button size="small" onClick={() => createIndex(item.name)}>Create Index</Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  });

  // render
  return (
    <Fragment>
      {collections.length > 0 ? listItems : ''}
    </Fragment>
  )
}

List.propTypes = {
  collections: PropTypes.array.isRequired,
  dropCollection: PropTypes.func.isRequired,
  renameCollection: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  reIndex: PropTypes.func.isRequired,
  dropIndex: PropTypes.func.isRequired,
  createIndex: PropTypes.func.isRequired,
};

export default List;
