// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.heat';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

// styles
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

// L.Icon.Default brings a wrong image url
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import municipiData from './data/municipi';
import barrisData from './data/barris';
import sectorsData from './data/sectors';


const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [12, 41], // the same for the shadow
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  tooltipAnchor: [10, -21], // point from which the tooltip should open relative to the iconAnchor
});
L.Marker.prototype.options.icon = DefaultIcon;


const styles = {
  map: {
    width: '100%',
    height: '100%'
  }
};


const getOpacity = (d) => {
  if (d > 256) return 0.8;
  if (d > 128) return 0.65;
  if (d > 64) return 0.50;
  if (d > 32) return 0.35;
  if (d > 16) return 0.2;
  if (d > 4) return 0.15;
  if (d > 0) return 0.1;
  return 0;
};


class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  addDataMap() {
    const { points, barris, sectors } = this.props;
    // markers
    this.customLayer.clearLayers();
    this.customLayer.addData(points);
    this.markers.clearLayers();
    this.markers.addLayer(this.customLayer);
    // heatmap
    const latLngs = _.map(points, (el) => {
      const coords = el.geometry.coordinates;
      return L.latLng(coords[1],coords[0]);
    })
    this.heat.setLatLngs(latLngs);
    // color barris
    this.layerBarris.clearLayers();
    let barrisNewData = barrisData;
    barrisNewData.features = _.map(barrisData.features, (feature) => {

      const barri_id = feature.properties.ID_BARRI;
      const barri_values = _.find(barris, { '_id': barri_id });
      const density = parseInt(_.get(barri_values, 'count', 0));

      _.set(feature, 'properties.density', density);
      return feature;
    });
    this.layerBarris.addData(barrisData);
    // color sectors
    this.layerSectors.clearLayers();
    let sectorsNewData = sectorsData;
    sectorsNewData.features = _.map(sectorsData.features, (feature) => {

      const sector_id = feature.properties.ID_SECTOR;
      const sector_values = _.find(sectors, { '_id': sector_id });
      const density = parseInt(_.get(sector_values, 'count', 0));

      _.set(feature, 'properties.density', density);
      return feature;
    });
    this.layerSectors.addData(sectorsNewData);
  }

  initMap() {
    const { center, zoom } = this.props;
    const borderStyle = {
      color: '#3498db',
      weight: 2,
      opacity: 1,
      fillColor: '#3498db',
      fillOpacity: 0
    };

    // Tile layers -- https://leaflet-extras.github.io/leaflet-providers/preview/
    const layerToner = L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png', { attribution: '' });
    const layerMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '' });
    const layerImaginary = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: '' });

    // loading GeoJSON info
    this.layerBarris = L.geoJSON(null, {
      style: (feature) => {
        const density = feature.properties.density;
        const style = borderStyle;
        style.fillOpacity = getOpacity(density);
        return style;
      },
      onEachFeature: (feature, layer) => {
        if (feature.properties) {
          const props = feature.properties;
          layer.bindPopup(`Barri<h1 style="margin:2px 0 5px;">${props.NOM_COMPLE}</h1>Habitatges: ${props.density}<br/>Àrea: ${parseFloat(props.SHAPE_AREA).toFixed(2)} m<sup>2</sup><br/>Perímetre: ${parseFloat(props.SHAPE_LEN).toFixed(2)} m`);
        }
      }
    });
    this.layerSectors = L.geoJSON(null, {
      style: (feature) => {
        const density = feature.properties.density;
        let style = borderStyle;
        style.fillOpacity = getOpacity(density);
        return style;
      },
      onEachFeature: (feature, layer) => {
        if (feature.properties) {
          const props = feature.properties;
          layer.bindPopup(`Sector<h1 style="margin:2px 0 5px;">${props.NOM_COMPLE}</h1>Habitatges: ${props.density}<br/>Barri: ${props.BARRIS}<br/>Àrea: ${parseFloat(props.SHAPE_AREA).toFixed(2)} m<sup>2</sup><br/>Perímetre: ${parseFloat(props.SHAPE_LEN).toFixed(2)} m`);
        }
      }
    });

    const arrangeLayers = () => {
      if (this.map.hasLayer(this.layerBarris)) this.layerBarris.bringToFront();
      if (this.map.hasLayer(this.layerSectors)) this.layerSectors.bringToFront();
    };

    // Heatmap layer
    this.heat = L.heatLayer([], { radius: 20, maxZoom: 20, max: 0.9, blur:30 });

    // Markers cluster group
    this.markers = L.markerClusterGroup({
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: false,
      removeOutsideVisibleBounds: true,
      disableClusteringAtZoom: 18
    });

    // data layer
    this.customLayer = L.geoJson(null, {
      onEachFeature: function(feature, layer) {
        const { name, address, sector } = feature.properties;
        const lat = feature.geometry.coordinates[1];
        const lon = feature.geometry.coordinates[0];
        layer.bindPopup(`<h1 style="margin:2px 0 5px;">${name}</h1>${address}<br/>Lat: ${lat}<br/>Lon: ${lon}<br/>${sector}<br/><a href="http://www.google.com/maps?layer=c&cbll=${lat},${lon}" target="_blank">Street view</a>`);
      }
    });


    // Create map
    this.map = L.map('map', {
      center: [center.lat, center.lng + 0.015],
      zoom: zoom,
      minZoom: 12,
      maxZoom: 18,
      scrollWheelZoom: false,
      layers: [ layerToner, this.layerSectors, this.heat, this.markers ]
    });

    // Add layer controller
    this.layersCtrl = L.control.layers(
      {
        'Tóner': layerToner,
        'Map': layerMap,
        'Image': layerImaginary,
      },
      {
        'Heat': this.heat,
        'Barris': this.layerBarris,
        'Sectors': this.layerSectors,
        'Points': this.markers
      },
      {
        hideSingleBase: true,
        autoZIndex: false,
        position: 'bottomleft'
      }
    ).addTo(this.map);

    // Limits
    L.geoJSON(municipiData, {
      style: borderStyle,
      zIndex: 10,
      onEachFeature: function (feature, layer) {
        if (feature.properties) {
          const props = feature.properties;
          layer.bindPopup(`Municipi<h1 style="margin:2px 0 5px;">Girona</h1>Àrea: ${parseFloat(props.SHAPE_AREA).toFixed(2)} m<sup>2</sup><br/>Perímetre: ${parseFloat(props.SHAPE_LEN).toFixed(2)} m`);
        }
      }
    }).addTo(this.map).bringToBack();

    // Mask poligon
    // credits: https://github.com/turban/Leaflet.Mask
    L.Mask = L.Polygon.extend({
      options: {
        stroke: false,
        color: '#fff',
        fillOpacity: 0.9,
        clickable: false,
        outerBounds: new L.LatLngBounds([-90, -360], [90, 360])
      },

      initialize: function (latLngs, options) {

        var outerBoundsLatLngs = [
          this.options.outerBounds.getSouthWest(),
          this.options.outerBounds.getNorthWest(),
          this.options.outerBounds.getNorthEast(),
          this.options.outerBounds.getSouthEast()
        ];
        L.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);
      },
    });
    L.mask = function (latLngs, options) {
      return new L.Mask(latLngs, options);
    };

    // transform geojson coordinates into an array of L.LatLng
    const coordinates = municipiData.features[0].geometry.coordinates[0];
    let latLngs = [];
    for (var i=0; i<coordinates.length; i++) {
      latLngs.push(new L.LatLng(coordinates[i][1], coordinates[i][0]));
    }

    L.mask(latLngs).addTo(this.map).bringToBack();

    // Legend
    var legend = L.control({position: 'bottomright'});

    legend.onAdd = function (map) {

      let div = L.DomUtil.create('div', 'info legend');
      const color = '#3498db';

      div.innerHTML =
        `<i style="background:${color};opacity:0.8;"></i> 256+<br/>`+
        `<i style="background:${color};opacity:0.65;"></i> 128-256<br/>`+
        `<i style="background:${color};opacity:0.5;"></i> 64-128<br/>`+
        `<i style="background:${color};opacity:0.35;"></i> 32-64<br/>`+
        `<i style="background:${color};opacity:0.2;"></i> 16-32<br/>`+
        `<i style="background:${color};opacity:0.15;"></i> 4-16<br/>`+
        `<i style="background:${color};opacity:0.1;"></i> 0-4`;

      return div;
    };

    legend.addTo(this.map);

    // add marker center
    //this.marker = L.marker(center).addTo(this.map);

    // Map actions

    // last center
    this.map.lastValidCenter = this.map.getCenter();
    // define bounds
    const southWest = L.latLng(41.9, 2.72);
    const northEast = L.latLng(42.1, 3);
    const limit_bounds = L.latLngBounds(southWest, northEast);

    this.map.on('moveend', function (event) {
      const newCenter = this.getCenter();
      if (limit_bounds.contains(newCenter)) {
        this.lastValidCenter = newCenter;
      } else {
        console.log('notinbounds');
        this.panTo(this.lastValidCenter, 300);
      }
    });

    // toggle overlays --> Sectors always up
    this.map.on('overlayadd overlayremove', function (event) {
      this.closePopup();
      arrangeLayers();
    });

    // change zoom --> close popups
    this.map.on('zoomstart', function (event) {
      this.closePopup();
    });

    // click map
    this.map.on('click', function (event) {
      this.closePopup();
      console.log(`${event.latlng.lat.toString()}, ${event.latlng.lng.toString()}`);
    });

    // Load data
    this.addDataMap();
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.points !== prevProps.points) {
      this.addDataMap();
    }
    if (this.props.full !== prevProps.full) {
      this.map.invalidateSize();
    }
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        id="map"
        className={classes.map}
      >
      </div>
    );
  }
}

// props defaults
Map.defaultProps = {
  center: {
    lat: 41.981651,
    lng: 2.823610
  },
  zoom: 13,
  points: [],
  barris: [],
  sectors: []
};

// props validation
Map.propTypes = {
  classes: PropTypes.object.isRequired,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  points: PropTypes.array.isRequired,
  barris: PropTypes.array.isRequired,
  sectors: PropTypes.array.isRequired
};

export default withStyles(styles)(Map);
