// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.tooltip = React.createRef();
    this.tip = React.createRef();
    this.x = React.createRef();
    this.y = React.createRef();
    this.g = React.createRef();
    this.xAxis = React.createRef();
    this.xAxisCall = React.createRef();
    this.yAxis = React.createRef();
    this.yAxisCall = React.createRef();
  }

  // custom functions

  updateGraph() {
    /*
    const { data } = this.props;

    // Update our scales
    const x = this.x.domain([0, 10]);
    const tip = this.tip;
    */
    // Update our axis
    this.xAxis.call(this.xAxisCall);

    // Update data
  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // init main group
    this.g = this.canvas.append("g")
      .attr("transform", "translate(" + margin[0] + "," + margin[2] + ")");

    // init scales
    this.x = d3.scaleLinear().range([0, width_data]);
    this.y = d3.scaleLinear().range([height_data, 0]);

    // init axes and labels
    this.xAxisCall = d3.axisBottom(this.x).ticks(5);
    this.xAxis = this.g.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(" + 0 + "," + height_data + ")");

    // Labels
    this.xAxis.append("text")
      .attr("class", "axis-title")
      .attr("transform", "translate(" + (width_data-6) + ", 0)")
      .attr("y", -6)
      .text("X");

    // tooltip
    this.tip = d3.select(this.tooltip);

    // Put data
    this.updateGraph();
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height } = this.props;

    return (
      <Fragment>
        <div
          ref={el => this.tooltip = el}
          className="tooltip"
        />
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="none"
          />
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:800,
  height:400,
  margin:[50,30,40,50],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
