// Dependencies
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';


const styles = theme => ({
  root: {
    padding: '20px 0 0'
  },
  label: {
    margin: '0 0 10px',
    display: 'flex',
    alignItems: 'flex-start'
  },
  radio: {
    padding: '0 10px'
  },
});

// Form
class Options extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      table: `table_${this.props.selected}`
    };
    // Bind functions
    this.handleChange = this.handleChange.bind(this);
  }

  // custom functions

  handleChange(e,name) {
    const { updateSelected } = this.props;
    const value = e.target.value.replace('table_','') ;
    //
    this.setState({ table: value }, () => {
      //
      updateSelected(value);
    });
  }

  // lifecycle methods

  // render
  render() {
    const { classes, values, selected } = this.props;

    return (
      <div className={classes.root}>
        {values.length>0 ? (
          values.map((value, key) => (
            <label key={key} className={classes.label}>
              <Radio
                name="table"
                value={`table_${key}`}
                checked={parseInt(selected)===key}
                onChange={(e) => this.handleChange(e)}
                color="secondary"
                disableRipple={true}
                className={classes.radio}
              />
              <div>
                <Typography>
                  <strong>Rows: {value.rows-1}+1 | Cols: {value.cols}</strong><br/>{value.csv.substring(0, 300)}
                </Typography>
              </div>
            </label>
          ))
        ):(
          <Typography>
            <strong>No s'ha trobat cap resultat.</strong>
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Options);
