// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

// Components
import ListItemAction from './ListItemAction';


const styles = theme => ({
  item: {
    padding: '15px 15px 10px',
  },
  actionBar: {
    margin: '5px 0 0 -5px',
  }
});


const ListItem = (props) => {
  const { classes, children, item, actions } = props;

  // render
  return (
    <Paper square className={classes.item} draggable="true">
      {children}
      <div className={classes.actionBar}>
        {actions.map((action, key) =>
          <ListItemAction
            key={`action_${key}`}
            item={item}
            action={action}
          />
        )}
      </div>
    </Paper>
  )
}

// props validation
ListItem.defaultProps = {
  actions: []
};

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  item: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired
};

export default withStyles(styles)(ListItem);
