import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  list: {
    margin: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '5px'
  },
  log: {
    padding: '10px',
    borderTop: '1px dotted #555',
    '&:first-child': {
      borderTop: '0',
    }
  }
});


const LogsList = (props) => {
  const { classes, logs } = props;

  const renderItems = _.map(logs, (log, index) => {
    return (
      <div className={classes.log} key={index}>
        {log.timestamp}<br/>{log.message}
      </div>
    );
  });

  // render
  return (
    <pre className={classes.list}>
      {renderItems}
    </pre>
  );
}

export default withStyles(styles)(LogsList);
