// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  snakeFood: {
    
  }
});


class Food extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
  }

  // custom functions


  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes, dot } = this.props;

    const style = {
      left: `${dot[0]}%`,
      top: `${dot[1]}%`
    }

    return (
      <div className={classes.snakeFood} style={style}></div>
    );
  }
}

// props validation
Food.defaultProps = {
};

Food.propTypes = {
  classes: PropTypes.object.isRequired,
  dot: PropTypes.array.isRequired,
};

export default withStyles(styles)(Food);
