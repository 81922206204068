// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Components
import Typography from '@material-ui/core/Typography';
import ListItem from '../../Global/ListItem';


const List = (props) => {
  const { models, activeModel, viewData, removeModel } = props;

  const listItems = _.map(models, (item, key) => {

    const itemActions = [{
      label: (item.active ? 'Active':'Inactive'),
      handleClick: activeModel,
      icon: (item.active ? 'active':'desactive'),
      iconProps: {
        color: (item.active ? 'primary':'secondary')
      }
    },{
      label: 'JSON',
      handleClick: viewData,
      icon: 'code'
    },{
      label: 'Delete',
      handleClick: removeModel,
      buttonProps: {
        color: 'secondary'
      },
      icon: 'delete'
    }];

    return (
      <ListItem
        key={`item_${key}`}
        item={item}
        actions={itemActions}
      >
        <Typography component="p" variant="subtitle1">
          {item.name} - {item.type}
        </Typography>
        <Typography component="p" variant="subtitle2">
          {item.text}
        </Typography>
      </ListItem>
    );
  });

  return (
    <Fragment>
      {models.length > 0 ? listItems : ''}
    </Fragment>
  )
}

List.propTypes = {
  models: PropTypes.array.isRequired,
  activeModel: PropTypes.func.isRequired,
  viewData: PropTypes.func.isRequired,
  removeModel: PropTypes.func.isRequired
};

export default List;
