// Dependencies
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Left from '@material-ui/icons/Undo';
import Right from '@material-ui/icons/Redo';
import Clear from '@material-ui/icons/Delete';


const styles = theme => ({
  root: {
    margin: '0 0 30px 0',
  },
  form: {
    margin: '0 0 30px 0',
  },
  flex: {
    flexGrow: 1
  },
  buttons: {
    textAlign: 'center',
    margin: '5px 0 0'
  },
  button: {
    margin: '15px 10px 0',
  },
  iconFlipped: {
    transform: 'scaleY(-1)'
  }
});

// Form
class Form extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Bind functions
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    // Refs
    this.latInput = React.createRef();
    this.lngInput = React.createRef();
    this.elevationInput = React.createRef();
    this.addressInput = React.createRef();
    this.localadminInput = React.createRef();
    this.postalcodeInput = React.createRef();
    this.regionInput = React.createRef();
    this.countryInput = React.createRef();
  }

  // custom functions

  handleChangePosition() {
    const { updatePosition } = this.props;
    const position = {
      lat: this.latInput.value,
      lng: this.lngInput.value
    };
    updatePosition(position);
  }

  handleChangeAddress() {
    const { updateAddress } = this.props;
    const format_address = {
      address: this.addressInput.value,
      postalcode: this.postalcodeInput.value,
      locality: this.localityInput.value,
      region: this.regionInput.value,
      country: this.countryInput.value
    };
    updateAddress(format_address);
  }

  // lifecycle methods
  componentDidMount() {
    const { getElevation } = this.props;
    getElevation();
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes, position, address, elevation, clearForm, toCoords, toAddress } = this.props;
    const { format_address } = address;

    return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.form}>
          <Grid item xs={3}>
            <TextField
              inputRef={el => this.latInput = el}
              name="lat"
              label="Latitud"
              placeholder="Latitud"
              type="text"
              fullWidth
              margin="dense"
              value={position.lat}
              onChange={this.handleChangePosition}
            />
            <TextField
              inputRef={el => this.lngInput = el}
              name="lng"
              label="Longitud"
              placeholder="Longitud"
              type="text"
              fullWidth
              margin="dense"
              value={position.lng}
              onChange={this.handleChangePosition}
            />
            <TextField
              inputRef={el => this.elevationInput = el}
              name="elevation"
              label="Elevacio"
              placeholder="Elevacio"
              type="text"
              fullWidth
              margin="dense"
              value={elevation}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={1} className={classes.buttons}>
            <Fab
              size="small"
              color="primary"
              className={classes.button}
              aria-label="fullScreen"
              onClick={toAddress}
            >
              <Right/>
            </Fab>
            <Fab
              size="small"
              color="primary"
              className={classes.button}
              aria-label="fullScreen"
              onClick={toCoords}
            >
              <Left className={classes.iconFlipped}/>
            </Fab>
            <Fab
              size="small"
              color="primary"
              className={classes.button}
              aria-label="fullScreen"
              onClick={clearForm}
            >
              <Clear/>
            </Fab>
          </Grid>
          <Grid item xs={8} className={classes.flex}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                  inputRef={el => this.addressInput = el}
                  name="address"
                  label="Adreça"
                  placeholder="Adreça"
                  type="text"
                  fullWidth
                  margin="dense"
                  value={format_address?format_address.address:''}
                  onChange={this.handleChangeAddress}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  inputRef={el => this.localityInput = el}
                  name="locality"
                  label="Població"
                  placeholder="Població"
                  type="text"
                  fullWidth
                  margin="dense"
                  value={format_address?format_address.locality:''}
                  onChange={this.handleChangeAddress}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputRef={el => this.postalcodeInput = el}
                  name="postalcode"
                  label="Codi Postal"
                  placeholder="Codi Postal"
                  type="text"
                  fullWidth
                  margin="dense"
                  value={format_address?format_address.postalcode:''}
                  onChange={this.handleChangeAddress}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  inputRef={el => this.regionInput = el}
                  name="region"
                  label="Regió"
                  placeholder="Regió"
                  type="text"
                  fullWidth
                  margin="dense"
                  value={format_address?format_address.region:''}
                  onChange={this.handleChangeAddress}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputRef={el => this.countryInput = el}
                  name="country"
                  label="País"
                  placeholder="País"
                  type="text"
                  fullWidth
                  margin="dense"
                  value={format_address?format_address.country:''}
                  onChange={this.handleChangeAddress}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Form);
