// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AOS from 'aos';

// Components
import ScrollToTop from '../Global/ScrollToTop';
import LabsViewHeader from './LabsViewHeader';
//import LabsViewFooter from './LabsViewFooter';
import LabsViewFooter from '../Global/Footer';

// Import CSS
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

// MuiTheme
const theme = createMuiTheme({
  fontFamily: 'Roboto',
  fontSize: '15px',
  typography: {
    fontFamily: 'Roboto',
    useNextVariants: true,
    h1: {
      fontFamily: 'Roboto',
      fontSize: '60px',
      fontWeight: 600,
      color: '#9E9E9E',
    },
    h3: {
      fontSize: '26px',
      fontWeight: 300,
      margin: '5px 0 20px 0'
    },
    h5: {
      fontSize: '20px',
      fontWeight: 500,
      margin: '20px 0 10px 0',
      color: '#424242',
    },
  },
  palette: {
    primary: {
      main: '#1e90ff',
    },
    secondary: {
      main: '#ff4757',
    },
    black: {
      main: '#212121',
    },
  },
});
theme.shadows = []; // no shadows

const styles = {
  root: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: '50px'
  }
};

// Init AOS
AOS.init({
  once: true
});

class Layout extends Component {

  // init scroll
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // render
  render() {
    const { classes, children } = this.props;

    return (
      <ScrollToTop>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <LabsViewHeader/>
            {children}
            <LabsViewFooter/>
          </div>
        </MuiThemeProvider>
      </ScrollToTop>
    );
  }
}

// props validation
Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(Layout);
