// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  button: {
    margin: '20px 20px 0 0'
  },
  form: {
    padding: '20px 0 0'
  }
});

// Form
class FormComponent extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Refs
    this.urlInput = React.createRef();
  }

  // lifecycle methods

  componentDidMount() {
    this.urlInput.focus();
  }

  // render
  render() {
    const { classes, onSubmit, onReset } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            url: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
          }}
          onReset={() => {
            onReset();
            this.urlInput.focus();
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form className={classes.form}>
              <TextField
                id="url"
                label="URL"
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                inputRef={el => this.urlInput = el}
                fullWidth
              />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                  className={classes.button}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!dirty}
                  onClick={handleReset}
                  className={classes.button}
                >
                  Clear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FormComponent);
