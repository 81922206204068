// Dependencies
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';

// Components
import Container from './Container';
//import Bars from './Bars';

const styles = {
  intro: {
    height: '500px',
    backgroundColor: 'rgb(231, 76, 60)'
  }
};


class Header extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {};
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes } = this.props;

    return (
      <section className={classes.intro}>
        <div className={classes.bars}>
          {/*<Bars/>*/}
        </div>
        <Container fixed className={classes.text}>
          <div>
            <FormattedMessage id="intro.title" />
          </div>
        </Container>
      </section>
    )
  }
}

export default withStyles(styles)(Header);
