// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';


const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  title: {
    flex: 1,
    lineHeight: '56px'
  }
});


class ListTop extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.clickItem = this.clickItem.bind(this);
  }

  // custom functions

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(event) {
    this.setState({ anchorEl: null });
  }

  clickItem(event, action) {
    action();
    this.handleClose(event);
  }

  // lifecycle methods

  // render
  render() {
    const { classes, title, actions } = this.props;
    const { anchorEl } = this.state;

    // render
    return (
      <div className={classes.root}>
        <Typography variant="h2" component="h2"  className={classes.title}>
          {title}
        </Typography>
        <Fab size="medium" color="primary" aria-label="add" onClick={this.handleClick}>
          <MoreIcon />
        </Fab>
        {actions.length>0 &&
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            onChange={this.handleClose}
            TransitionComponent={Fade}
          >
            {actions.map((item, pos) => (
              <MenuItem key={pos} onClick={(e) => this.clickItem(e, item.action)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        }
      </div>
    )
  }
}

// props validation
ListTop.defaultProps = {
  title: 'Model',
  actions: [],
};

ListTop.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired
};

export default withStyles(styles)(ListTop);
