//CommentBox.js
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import Blob from 'blob';

// Components
import SnackBar from '../Global/SnackBar';
import Dialog from '../Global/Dialog';
import Route from './Route';
import Actions from './Actions';
import ViewOptions from './ViewOptions';
import FormName from './FormName';
import FormUpload from './FormUpload';
import List from './List';
import Select from './Select';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    background: "#f4f4f4"
  },
  bar: {
    top: "0"
  },
  files: {
    padding: "20px 0"
  }
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      loading: true,
      route: '',
      files: [],
      view: 'grid',
      sort: 'name',
      sortDirection: 'asc',
      numSelect: 0,
      modal: {
        open: false,
        title: '',
        content: ''
      }
    };
    // Bind functions
    this.getFiles = this.getFiles.bind(this);
    this.createFolder = this.createFolder.bind(this);
    this.createFolderSend = this.createFolderSend.bind(this);
    this.openFolder = this.openFolder.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.uploadFilesSend = this.uploadFilesSend.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.previewFile = this.previewFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.move = this.move.bind(this);
    this.rename = this.rename.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeView = this.changeView.bind(this);
    this.refresh = this.refresh.bind(this);
    this.select = this.select.bind(this);
    this.getSelected = this.getSelected.bind(this);
    this.clearSelect = this.clearSelect.bind(this);
    this.renameSend = this.renameSend.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  getFiles() {
    const { route } = this.state;
    // ajax call
    axios.get('/fm/list', { params: { route: route }, cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState((prevState, props) => {
          const { sort, sortDirection } = prevState;
          const files = this.sortValues(res.data, sort, sortDirection);
          return { files: files, numSelect: 0, loading: false };
        });
      });
  }

  createFolderSend(values) {
    const { route } = this.state;

    if (values.name) {
      // ajax call
      axios.put('/fm/create_folder', { name: values.name, route: route }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getFiles();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Folder created ok!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  createFolder(name) {
    const title = 'Create folder';
    const content = <FormName onSubmit={this.createFolderSend} />;
    this.dialog.openDialog(title, content);
  }

  openFolder(route) {
    this.setState({ route: route, files: [], loading: true, numSelect: 0 }, () => {
      this.getFiles();
    });
  }

  deleteFolder(route) {
    if (route) {
      axios.post('/fm/delete_folder', { route: route }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getFiles();
          this.snackbar.openSnackBar('success', 'Folder deleted ok!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  move(name, to) {
    const { route } = this.state;

    if (name) {
      axios.post('/fm/move', { name: name, from: route, to: to }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getFiles();
          this.snackbar.openSnackBar('success', 'Moved ok!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  renameSend(name, values) {
    const { route } = this.state;
    const rename = values.name || false;

    if (name && rename) {
      axios.post('/fm/rename', { route, name, rename }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getFiles();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Renamed ok!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  rename(name) {
    const title = 'Rename';
    const content = <FormName onSubmit={this.renameSend.bind(this, name)}  name={name} />;
    this.dialog.openDialog(title, content);
  }

  // File

  uploadFilesSend(values) {
    const { route } = this.state;

    this.dialog.closeDialog();
    if (values.files) {
      // config ajax
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: this.cancelToken.token,
        onUploadProgress: (e) => console.log(e.loaded, e.total)
      };
      // formdata
      const fd = new FormData();
      fd.append('route', route);
      // append files
      _.each(values.files, function(file) {
        fd.append('files', file);
      });
      // ajax call
      axios.post('/fm/upload_files', fd, config)
        .then(res => {
          this.getFiles();
          this.snackbar.openSnackBar('success', 'Upload Files!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  uploadFiles() {
    const title = 'Upload files';
    const content = <FormUpload onSubmit={this.uploadFilesSend} />;
    this.dialog.openDialog(title, content);
  }


  deleteFile(route) {
    if (route) {
      axios.post('/fm/delete_file', { route: route }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getFiles();
          this.snackbar.openSnackBar('success', 'File deleted ok!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  previewFile(route) {
    window.open(`/uploads/${route}`, '_blank'); //to open new page
  }

  downloadFile(file) {

    if (file.type === 'file') {
      // blob axios
      axios.get('/fm/download_file', { params: { route: file.route }, responseType: 'arraybuffer', cancelToken: this.cancelToken.token })
        .then((response) => {
          saveAs(new Blob([response.data]), file.name);
          this.snackbar.openSnackBar('success', 'File download start!');
        }).catch((err) => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  // Sort

  changeSort(sort) {
    if (sort !== this.state.sort) {
      this.setState((prevState, props) => {
        const files = this.sortValues(prevState.files, sort, 'asc');
        return { sort: sort, sortDirection: 'asc', files: files };
      });
    } else {
      this.setState((prevState, props) => {
        const sortDirection = ( prevState.sortDirection === 'asc' ? 'desc' : 'asc');
        const files = this.sortValues(prevState.files, prevState.sort, sortDirection);
        return { sortDirection: sortDirection, files: files };
      });
    }
  }

  sortValues(files, sort, sortDirection) {
    const sortFields = {
      'name': ['name'],
      'date': ['date'],
      'ext': ['type','ext'],
      'size': ['size'],
    };
    const sortFiles = _.orderBy(files, sortFields[sort], sortDirection);
    return sortFiles;
  }

  // View

  changeView(val) {
    if (val !== this.state.val) {
      this.setState({ view: val });
    }
  }

  // Refresh

  refresh() {
    this.setState({ files: [] }, () => {
      this.getFiles();
    });
  }

  // Select

  select(route) {
    this.setState((prevState, props) => {
      const files = prevState.files;
      const numSelect = prevState.numSelect
      const itemIndex = _.findIndex(files, { route: route });
      const isSelected = files[itemIndex].selected;
      //
      if (!isSelected && prevState.numSelect < props.maxSelect) {
        files[itemIndex].selected = true;
        return { files: files, numSelect: numSelect + 1 }
      } else if (isSelected) {
        files[itemIndex].selected = false;
        return { files: files, numSelect: numSelect - 1 }
      }
    });
  }

  clearSelect() {
    this.setState((prevState, props) => {
      const files = _.map(prevState.files, (item) => {
        item.selected = false;
        return item;
      });
      return { files: files, numSelect: 0 }
    });
  }

  getSelected(e) {
    const { getSelected } = this.props;
    const { files } = this.state;
    //
    if (typeof getSelected === 'function') {
      // selected routes
      const selected = _.filter(files, (item) => {
        return item.selected;
      }).map((item) => {
        return item.route;
      });
      //
      getSelected(selected);
    }
  }

  // lifecycle methods

  componentDidMount() {
    // First call
    this.getFiles();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { classes, maxSelect } = this.props;
    const { route, loading, files, view, sort, sortDirection, numSelect } = this.state;

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} fullscreen={false} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <div className={classes.root}>
          <AppBar className={classes.bar} position="sticky">
            <div>
              <Route
                route={route}
                openFolder={this.openFolder}
              />
              <ViewOptions
                sort={sort}
                sortDirection={sortDirection}
                changeSort={this.changeSort}
                view={view}
                changeView={this.changeView}
              />
              <Actions
                refresh={this.refresh}
                createFolder={this.createFolder}
                uploadFiles={this.uploadFiles}
              />
            </div>
            {maxSelect > 0 &&
              <Select
                maxSelect={maxSelect}
                numSelect={numSelect}
                getSelected={this.getSelected}
                clearSelect={this.clearSelect}
              />
            }
          </AppBar>
          <div className={classes.files} >
            {loading ? (
              <CircularProgress
                color="secondary"
                size={40}
                className={classes.progress}
              />
            ) : (
              <List
                route={route}
                files={files}
                openFolder={this.openFolder}
                deleteFolder={this.deleteFolder}
                deleteFile={this.deleteFile}
                previewFile={this.previewFile}
                downloadFile={this.downloadFile}
                move={this.move}
                rename={this.rename}
                select={this.select}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

// props validation
Index.defaultProps = {
  maxSelect: 0,
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  maxSelect: PropTypes.number.isRequired,
  getSelected: PropTypes.func,
};

export default withStyles(styles)(Index);
