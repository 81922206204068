// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as d3 from 'd3';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    backgroundColor: '#f4f4f4',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 40px',
    justifyContent: 'space-between'
  },
  temp: {
    color: '#fff',
    display: 'inline-block',
    float: 'right',
  },
  num: {
    fontWeight: 400,
    fontSize: '120px',
    color:'#fff'
  },
  info: {
    height: '105px',
    width: '200px',
    position: 'relative',
    fontSize: '19px',
    lineHeight: '24px',
    color: '#fff',
  },
  day: {
    position: 'absolute',
    bottom: 0,
    left: 0
  }
});


class Actual extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
  }

  // custom functions

  bgcolor() {
    const { temp } = this.props;

    if (temp.weather) {
      const val = temp.weather.main.temp;
      const colors = d3.scaleLinear()
        .domain([0, 10, 20, 30, 40])
        .range(['#3498db', '#1abc9c', '#f1c40f', '#e67e22', '#C0392B'])
        .interpolate(d3.interpolateHcl);

      return colors(val);
    }
    return '#f4f4f4';
  }

  // lifecycle methods

  // render
  render() {
    const { classes, temp } = this.props;
    const bgcolor = this.bgcolor();

    const num_temp = parseFloat(temp.weather?temp.weather.main.temp:0).toFixed(1);
    const humidity = temp.weather?temp.weather.main.humidity:'';
    const pressure = temp.weather?temp.weather.main.pressure:'';
    const wind = temp.weather?temp.weather.wind.speed:'';
    const wind_deg = temp.weather?(temp.weather.wind.deg?temp.weather.wind.deg+'º':''):'';
    const dt = temp.weather?temp.weather.dt:0;
    const day = moment.unix(dt).format('YYYY-MM-DD HH:mm');

    // render
    return (
      <div className={classes.root} style={{ background: bgcolor }}>
        <div className={classes.info}>
          <Typography>
            Humedad: <strong>{humidity}%</strong><br/>
            Presión: <strong>{pressure}mbar.</strong><br/>
            Viento: <strong>{wind}km/h <small>{wind_deg}</small></strong>
            <small className={classes.day}>{day}</small>
          </Typography>
        </div>
        <div className={classes.temp}>
          <Typography className={classes.num}>
            {num_temp}º
          </Typography>
        </div>
      </div>
    )
  }
}

// props validation
Actual.defaultProps = {
  result: []
};

Actual.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.array.isRequired
};

export default withStyles(styles)(Actual);
