// Dependencies
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../Global/Container';
import RobotError from '../Global/Svg/RobotError';

const styles = {
  error: {
    padding: '40px 0',
    minHeight: '220px',
    '& svg': {
      height: '100%',
      maxHeight: '200px',
      margin: '0 0 20px 0',
      zIndex: 10
    }
  }
};


const Index = (props) => {
  const { classes } = props;

  document.title = 'Error';

  // render
  return (
    <Fragment>
      <section className="">
        <Container fixed className={classes.error}>
          <RobotError color="#111"/>
          <Typography variant="h6" color="inherit">
            <FormattedMessage id="error.404" />
          </Typography>
        </Container>
      </section>
    </Fragment>
  );
}

export default withStyles(styles)(Index);
