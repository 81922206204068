// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import PriceGraph from './PriceGraph';
import PriceSlider from './PriceSlider';


const styles = theme => ({
  root: {
  },
  graph: {
    height: '220px',
  },
});


class Price extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes, data, total_price } = this.props;

    // render
    return (
      <Fragment>
        <Container fixedSmall>
          <Typography variant="h6" color="inherit">
            Distribució Preus {total_price}
          </Typography>
          <div className={classes.graph}>
            <PriceGraph
              data={data}
            />
          </div>
          <Typography variant="h6" color="inherit">
            Top 15 Preus
          </Typography>
          <PriceSlider
            data={data}
          />
        </Container>
      </Fragment>
    )
  }
}

// props validation
Price.defaultProps = {
  result: [],
  total_price: 0
};

Price.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.array.isRequired,
  total_price: PropTypes.number.isRequired
};

export default withStyles(styles)(Price);
