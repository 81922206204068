import * as types from './actionTypes';
import authApi from '../../api/authApi';

// Action creators
export function getLoggedUser(user) {
  return {type: types.GET_LOGGED_USER, user}
}

export function authUserBegin() {
  return {type: types.AUTH_USER_BEGIN}
}

export function authUserSuccess(data) {
  return {type: types.AUTH_USER_SUCCESS}
}

export function authUserError(error) {
  return {type: types.AUTH_USER_ERROR}
}

// Thunks
export function sendNewsletter(data) {
  return function(dispatch, getState) {
    dispatch(authUserBegin());
    return authApi.send(data)
      .then(result => {
        dispatch(authUserSuccess(data));
      })
      .catch(error => {
        dispatch(authUserError(error));
        throw(error);
      });
  }
}
