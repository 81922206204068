// Dependencies
import React, { Component } from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
  button: {
    margin: '20px 20px 20px 0'
  },
  form: {
    padding: '20px 0'
  },
  options: {
    padding: '10px 0'
  },
  checkbox: {
    height: '32px',
  }
});

// Form
class Options extends Component {

  // construct
  constructor(props) {
    super(props);
    const { actives } = this.props;
    // Innit state
    this.state = {
      image: (actives.indexOf('image')!==-1)?true:false,
      bg_image: (actives.indexOf('bg_image')!==-1)?true:false,
      svg: (actives.indexOf('svg')!==-1)?true:false
    };
    // Bind functions
    this.handleChange = this.handleChange.bind(this);
  }

  // custom functions

  handleChange(e,name) {
    const { updateValue } = this.props;
    //
    this.setState({ [name]: e.target.checked }, () => {
      // agafem les que son valides
      const activeOptions = _.reduce(this.state, (result, value, key) => {
        if (Boolean(value)) result.push(key);
        return result;
      }, []);
      //
      updateValue(activeOptions);
    });
  }

  // lifecycle methods

  // render
  render() {
    const { classes, values } = this.props;

    return (
      <div className={classes.root}>
        {values.length>0 ? (
          <FormGroup className={classes.options}>
            {values.map((value, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={Boolean(this.state[value.label])}
                    onChange={(e) => this.handleChange(e,value.label)}
                    value={value.label}
                    disableRipple={true}
                    disabled={value.results===0}
                  />
                }
                label={`${value.tag} - ${value.results} resultats`}
              />
            ))}
          </FormGroup>
        ):(
          <Typography>
            <strong>No s'ha trobat cap resultat.</strong>
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Options);
