import delay from './delay';

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
const projects = [
  {
    id: "react-flux-building-applications",
    title: "Building Applications in React and Flux"
  },
  {
    id: "clean-code",
    title: "Clean Code: Writing Code for Humans"
  },
  {
    id: "architecture",
    title: "Architecting Applications for the Real World"
  },
  {
    id: "career-reboot-for-developer-mind",
    title: "Becoming an Outlier: Reprogramming the Developer Mind"
  },
  {
    id: "web-components-shadow-dom",
    title: "Web Component Fundamentals"
  }
];

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

//This would be performed on the server in a real app. Just stubbing in.
const generateId = (course) => {
  return replaceAll(course.title, ' ', '-');
};


class ProjectsApi {

  static getAll() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.assign([], projects));
      }, delay);
    });
  }

  static save(project) {
    project = Object.assign({}, project); // to avoid manipulating object passed in.
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Simulate server-side validation
        const minTitleLength = 1;
        if (project.title.length < minTitleLength) {
          reject(`Title must be at least ${minTitleLength} characters.`);
        }

        if (project.id) {
          //Simulating update
          const existingIndex = projects.findIndex(a => a.id === project.id);
          projects.splice(existingIndex, 1, project);
        } else {
          //Just simulating creation here
          project.id = generateId(project);
          projects.push(project);
        }

        resolve(project);
      }, delay);
    });
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const indexToDelete = projects.findIndex(a => a.id === id);
        projects.splice(indexToDelete, 1);
        resolve();
      }, delay);
    });
  }
}

export default ProjectsApi;
