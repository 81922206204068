// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    margin: '20px 0 10px'
  }
});


class TableContainer extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      columns: [],
      rows: [],
      originalRows: []
    };
    // Bind functions
    this.handleGridSort = this.handleGridSort.bind(this);
  }

  // custom functions
  parseDataGrid() {
    const { table } = this.props;
    // construim data grid
    const keys = [];
    const columns = _.map(table.data[0], (value, key) => {
      let col_key = value?_.snakeCase(value):`col_${key}`;
      // evitem keys repetits
      if (!keys[col_key]) {
        keys[col_key] = 1;
      } else {
        keys[col_key] = keys[col_key] + 1;
        col_key = `${col_key}_${keys[col_key]}`;
      }
      //
      return {
        key: col_key,
        name: value,
        sortable: true,
        minColumnWidth: 120
      };
    });
    const rows = _.map(table.data.slice(1), (value, key) => {
      var result = {};
      columns.forEach((col, i) => result[col.key] = value[i]);
      return result;
    });
    // update state
    this.setState({
      columns: columns,
      rows: rows,
      originalRows: rows
    });
  }

  handleGridSort(sortColumn, sortDirection) {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
      }
    };

    const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);
    console.log('handleGridSort',rows);

    this.setState({ rows });
  }

  // lifecycle methods

  componentDidMount() {
    this.parseDataGrid();
  }

  componentDidUpdate(prevProps) {
    if (this.props.table !== prevProps.table) {
    }
  }

  // render
  render() {
    const { classes } = this.props;
    const { columns, rows } = this.state;

    // render
    return (
      <div className={classes.root}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          minHeight={400}
          onGridSort={this.handleGridSort}
        />
      </div>
    )
  }
}

// props validation
TableContainer.defaultProps = {
  table: {
    data: []
  }
};

TableContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableContainer);
