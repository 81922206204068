// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Dropzone from 'react-dropzone';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  dropzone: {
    border: '1px dashed #ddd',
    borderRadius: '5px',
    padding: '20px 30px',
  }
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit, accept } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            file: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            submitForm,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue
          }) => (
            <Form>
              <Grid container spacing={2}>
                {/*
                <Grid item xs={12}>
                  <input
                    accept={accept}
                    className={classes.input}
                    id="file"
                    name="file"
                    type="file"
                    onChange={async (e) => {
                      await setFieldValue("file", e.currentTarget.files[0]);
                      submitForm();
                    }}
                    onBlur={handleBlur}
                    required
                  />
                </Grid>
                */}
                <Grid>
                  <Dropzone
                    accept={accept}
                    onDrop={async (acceptedFiles) => {
                      if (acceptedFiles.length > 0) {
                        await setFieldValue('file', acceptedFiles[0]);
                        submitForm();
                      }
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()} className={classes.dropzone}>
                          <input {...getInputProps()} />
                          <p>Drop .zip file here, or click to select</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}


// props validation
FormComponent.defaultProps = {
  accept: "image/*"
};

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormComponent);
