//CommentBox.js
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';
import { saveAs } from 'file-saver';

// Components
import List from './List';
import ListTop from '../Global/ListTop';
import SnackBar from '../Global/SnackBar';
import Dialog from '../Global/Dialog';
import FormUpload from './FormUpload';

import CircularProgress from '@material-ui/core/CircularProgress';


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Backups';
    // Innit state
    this.state = {
      loading: true,
      files: [],
    };
    // Bind functions
    this.loadBackups = this.loadBackups.bind(this);
    this.createBackup = this.createBackup.bind(this);
    this.clearBackups = this.clearBackups.bind(this);
    this.removeBackup = this.removeBackup.bind(this);
    this.restoreBackup = this.restoreBackup.bind(this);
    this.downloadBackup = this.downloadBackup.bind(this);
    this.restoreLocal = this.restoreLocal.bind(this);
    this.restoreLocalSend = this.restoreLocalSend.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  loadBackups() {
    axios.get('/api/backups', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ files: res.data, loading: false });
      });
  }

  createBackup(e) {
    axios.post('/api/backups/create', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.loadBackups();
        this.snackbar.openSnackBar('success', 'Backup created ok!');
      })
      .catch(err => {
        this.snackbar.openSnackBar('error', err.message);
      });
  }

  clearBackups(e) {
    axios.post('/api/backups/clear', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ files: [], loading: false });
        this.snackbar.openSnackBar('success','Backups removed ok!');
      })
      .catch(err => {
        this.snackbar.openSnackBar('error', err.message);
      });
  }

  removeBackup(item) {
    if (item.name) {
      axios.post('/api/backups/remove', { file: item.name }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.loadBackups();
          this.snackbar.openSnackBar('success','Backup removed ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  restoreBackup(item) {
    if (item.name) {
      this.setState({ wait: true });
      axios.post('/api/backups/restore', { file: item.name }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.setState({ wait: false });
          this.snackbar.openSnackBar('success','Backup restored ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  downloadBackup(item) {
    if (item.name) {
      // blob axios
      axios.post('/api/backups/download', { file: item.name }, { responseType: 'arraybuffer', cancelToken: this.cancelToken.token })
        .then((res) => {
          saveAs(new Blob([res.data]), item.name);
          this.snackbar.openSnackBar('success', 'File download start!');
        }).catch((err) => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  restoreLocalSend(values) {
    const file = values.file || false;
    this.dialog.closeDialog();
    if (file) {
      // config ajax
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: this.cancelToken.token,
        onUploadProgress: (e) => console.log(e.loaded, e.total)
      };
      // formdata
      const fd = new FormData();
      fd.append('file', file)
      // ajax call
      axios.post('/api/backups/restore_local', fd, config)
        .then(res => {
          this.snackbar.openSnackBar('success', 'Upload Local Backup!');
        })
        .catch((err) => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  restoreLocal() {
    const title = 'Upload Local Backup';
    const content = <FormUpload
      onSubmit={this.restoreLocalSend}
      accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
    />;
    this.dialog.openDialog(title, content);
  }

  // lifecycle methods

  componentDidMount() {
    // First call
    this.loadBackups();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { files, loading } = this.state;

    const actionsTop = [
      {
        label: 'Create Backup',
        action: this.createBackup,
      },
      {
        label: 'Restore Local',
        action: this.restoreLocal,
      },
      {
        label: 'Clear Backups',
        action: this.clearBackups,
      }
    ];

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} fullscreen={false} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <ListTop
          title='Backups'
          actions={actionsTop}
        />
        {loading ? (
          <CircularProgress
            color="secondary"
            size={40}
          />
        ) : (
          <List
            files={files}
            downloadBackup={this.downloadBackup}
            restoreBackup={this.restoreBackup}
            removeBackup={this.removeBackup}
          />
        )}
      </Fragment>
    )
  }
}

export default Index;
