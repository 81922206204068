import * as types from '../actions/actionTypes';

const initialState = {
  pending: true,
  logged: false,
  user: {
    name: false,
    role: false,
    token: false
  }
}

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.GET_LOGGED_USER:
      return Object.assign({}, state, {
        pending: false
      });

    default:
      return state;
  }
}

export default authReducer;
