// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonBase from '@material-ui/core/ButtonBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// Components
import Container from '../Global/Container';
import Form from './Form';
import List from './List';
import LogoTwitter from '../Global/Svg/LogoTwitter';
import LogoInstagram from '../Global/Svg/LogoInstagram';
import LogoFacebook from '../Global/Svg/LogoFacebook';
import LogoLinkedIn from '../Global/Svg/LogoLinkedIn';

const styles = {
  social: {
    background: '#f4f4f4',
    padding: '40px 0',
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 10px',
  },
  grid: {
    padding: '40px 0',
    minHeight: 'calc(100vh)'
  },
  list: {
    padding: '20px 0 0',
  },
  btn: {
    height: '45px',
    borderRadius: '5px',
    padding: '0 8px',
    margin: '0 10px',
    overflow: 'hidden',
    '& svg': {
      height: '100%',
      maxHeight: '30px',
      margin: '0 10px 0 0',
      zIndex: 10
    }
  }
};


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    document.title = 'Wall';
    // Innit state
    this.state = {
      loading: true,
      models: [],
      open_search: false,
      search_values: {}
    };
    // Bind functions
    this.handleClose = this.handleClose.bind(this);
    this.sendSearch = this.sendSearch.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  getModels() {
    const { search_values } = this.state;

    // create params
    const params = {
      ...search_values,
      type: _.transform(search_values.type, (result, value, key) => {
        if (value) result.push(key);
        return result;
      }, [])
    };
    console.log('getModels',params);

    axios.get('/api/get_wall', { params, cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({
          loading: false,
          models: res.data.result
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          models: []
        });
      });
  }

  toggleSearch() {
    this.setState((prevState) => {
      return {
        open_search: !prevState.open_search
      };
    });
  }

  handleClose() {
    this.setState({
      open_search: false
    });
  }

  sendSearch(values) {
    this.setState({
      open_search: false,
      search_values: values,
      loading: true,
      models: []
    }, () => {
      this.getModels();
    });
  }

  resetSearch() {
    this.setState({
      search_values: {},
      loading: true,
      models: []
    }, () => {
      this.getModels();
    });
  }

  // lifecycle methods

  componentDidMount() {
    this.getModels();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { classes } = this.props;
    const { loading, models, open_search, search_values } = this.state;

    let content;
    if (loading) {
      content = (
        <CircularProgress
          color="secondary"
          size={40}
          className={classes.progress}
        />
      );
    } else if (models.length > 0) {
        content = (
          <List
            models={models}
          />
        );
    } else {
      content = 'No s\'ha obtingut cap resultat...';
    }

    // render
    return (
      <Fragment>
        <Dialog
          open={open_search}
          scroll="body"
          onClose={this.handleClose}
          aria-labelledby="form-dialog"
        >
          <DialogContent>
            <div className={classes.title}>
              <Typography variant="h5" component="h5">
                Buscar
              </Typography>
              <Fab
                size="small"
                color="default"
                aria-label="Search"
                className={classes.fab}
                onClick={(e) => this.toggleSearch(e)}
              >
                <CloseIcon />
              </Fab>
            </div>
            Busca per paraula clau, data o tipus.
            <Form
              formSubmit={this.sendSearch}
              formReset={this.resetSearch}
              searchValues={search_values}
            />
          </DialogContent>
        </Dialog>

        <section className={classes.social}>
          <Container fixed className={classes.links}>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://twitter.com/analogicemotion" rel="noopener noreferrer">
              <LogoTwitter color="#111"/> Twitter
            </ButtonBase>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://www.instagram.com/analogicemotion/" rel="noopener noreferrer">
              <LogoInstagram color="#111"/> Instagram
            </ButtonBase>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://www.facebook.com/analogicemotion" rel="noopener noreferrer">
              <LogoFacebook color="#111"/> Facebook
            </ButtonBase>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://www.linkedin.com/company/aemotion" rel="noopener noreferrer">
              <LogoLinkedIn color="#111"/> LinkedIn
            </ButtonBase>
          </Container>
        </section>
        <section className={classes.grid}>
          <Container fixed>
            <Fab
              size="medium"
              color={_.isEmpty(search_values)?'default':'secondary'}
              aria-label="Search"
              className={classes.fab}
              onClick={(e) => this.toggleSearch(e)}
            >
              <SearchIcon />
            </Fab>
            <div className={classes.list}>
              {content}
            </div>
          </Container>
        </section>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
