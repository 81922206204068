// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

// Components
import Container from '../Global/Container';
import Logo from '../Global/Svg/Logo';
import LogoGitLab from '../Global/Svg/LogoGitLab';
import LogoDigitalOcean from '../Global/Svg/LogoDigitalOcean';


const styles = {
  root: {
    height: '75px'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: '100%'
  },
  company: {
    '& svg': {
      width: '40px'
    },
    display: 'flex',
    alignItems: 'center'
  },
  copy: {
    fontSize: '9px',
    lineHeight: '9px',
    margin: '5px 0 0 7px'
  },
  btn: {
    height: '45px',
    borderRadius: '5px',
    padding: '0 8px',
    overflow: 'hidden',
    maxWidth: '140px',
    '& svg': {
      height: '100%',
      maxHeight: '30px',
      zIndex: 10
    }
  }
};


const Footer = (props) => {
  const { classes, bgColor, logoColor } = props;

  const bgStyle = {
    backgroundColor: bgColor,
    color: logoColor
  };

  // render
  return (
    <Fragment>
      <section className={classes.root} style={bgStyle}>
        <Container fixed className={classes.flex}>
          <div className={classes.company}>
            <Logo color={logoColor} />
            <p className={classes.copy}>
              &copy; {(new Date()).getFullYear()}<br/>analogicemotion.com<br/>multimedia publishers
            </p>
          </div>
          <div className={classes.flex}>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://www.digitalocean.com" rel="noopener noreferrer">
              <LogoDigitalOcean color={logoColor}  className={classes.logo}/>
            </ButtonBase>
            <ButtonBase className={classes.btn} component="a" target="_blank" href="https://gitlab.com/analogicemotion" rel="noopener noreferrer">
              <LogoGitLab color={logoColor}  className={classes.logo}/>
            </ButtonBase>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

Footer.defaultProps = {
  bgColor: '#BDBDBD',
  logoColor: '#111',
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.string.isRequired,
  logoColor: PropTypes.string.isRequired,
};

export default withStyles(styles)(Footer);
