// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import LaunchIcon from '@material-ui/icons/Launch';
import DownloadIcon from '@material-ui/icons/GetApp';
import Chip from '@material-ui/core/Chip';


const styles = theme => ({
  box: {
    position: 'relative'
  },
  buttons: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    width: '100px',
  },
  link: {
    marginRight: '10px',
    background: "#fff"
  },
  chip: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    height: '24px',
    background: '#fff',
    textTransform: 'lowercase'
  },
  title: {
    margin: '20px 0 10px'
  },
  list: {
    margin: '20px 0 10px'
  },
  preview: {
    position: 'relative',
    paddingTop: '100%',
    backgroundColor: '#eee',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  },
  wrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left:0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& img': {
      flexGrow: 0,
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '& svg': {
      width: '100%',
      height: '100%'
    }
  }
});


const ImageItemComponent = (props) => {
  const { classes, item, preview, download } = props;
  const extension = item.extension?item.extension:false;

  // render
  return (
    <Grid item lg={3} md={3} sm={4} xs={6}>
      <div className={classes.box}>
        <div className={classes.preview}>
          <div className={classes.wrap}>
            <img src={item.src} alt={item.src} />
          </div>
        </div>
        {extension && (
          <Chip
            className={classes.chip}
            label={extension}
          />
        )}
        <div className={classes.buttons}>
          <Fab size="small" className={classes.link} onClick={preview.bind(this, item)}>
            <LaunchIcon />
          </Fab>
          <Fab size="small" className={classes.link} onClick={download.bind(this, item)}>
            <DownloadIcon />
          </Fab>
        </div>
      </div>
    </Grid>
  )
}
const ImageItem = withStyles(styles)(ImageItemComponent);


const SvgItemComponent = (props) => {
  const { classes, item, downloadSvg } = props;

  // render
  return (
    <Grid item lg={3} md={3} sm={4} xs={6}>
      <div className={classes.box}>
        <div className={classes.preview}>
          <div className={classes.wrap} dangerouslySetInnerHTML={{__html: item.html }}></div>
        </div>
        <Chip
          className={classes.chip}
          label=".svg"
        />
        <div className={classes.buttons}>
          {/*
          <Fab size="small" className={classes.link} onClick={preview.bind(this, item)}>
            <LaunchIcon />
          </Fab>
          */}
          <Fab size="small" className={classes.link} onClick={downloadSvg.bind(this, item)}>
            <DownloadIcon />
          </Fab>
        </div>
      </div>
    </Grid>
  )
}
const SvgItem = withStyles(styles)(SvgItemComponent);


const List = (props) => {
  const { classes, actives, entities, preview, download, downloadSvg } = props;

  let content = [];

  if (actives.indexOf('image')!==-1 && entities.image) {
    const itemImages = _.map(entities.image, (item, key) => {

      return (
        <ImageItem
          key={`img_${key}`}
          item={item}
          preview={preview}
          download={download}
        />
      );
    });
    content.push(
      <Fragment key="image">
        <Typography className={classes.title}>
          <strong>Image</strong>
        </Typography>
        <Grid container spacing={2}>{itemImages}</Grid>
      </Fragment>
    );
  }

  if (actives.indexOf('bg_image')!==-1 && entities.bg_image) {
    const itemBgImages = _.map(entities.bg_image, (item, key) => {

      return (
        <ImageItem
          key={`bg_img_${key}`}
          item={item}
          preview={preview}
          download={download}
        />
      );
    });
    content.push(
      <Fragment key="bg_image">
        <Typography className={classes.title}>
          <strong>BG Image</strong>
        </Typography>
        <Grid container spacing={2}>{itemBgImages}</Grid>
      </Fragment>
    );
  }

  if (actives.indexOf('svg')!==-1 && entities.svg) {
    const itemSvgs = _.map(entities.svg, (item, key) => {

      return (
        <SvgItem
          key={`img_${key}`}
          item={item}
          preview={preview}
          downloadSvg={downloadSvg}
        />
      );
    });
    content.push(
      <Fragment key="svg">
        <Typography className={classes.title}>
          <strong>SVG</strong>
        </Typography>
        <Grid container spacing={2}>{itemSvgs}</Grid>
      </Fragment>
    );
  }

  // render
  return (
    <div className={classes.list}>
      {content}
    </div>
  );
};

// props validation
List.defaultProps = {
  entities: false
};

List.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(List);
