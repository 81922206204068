// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Map from './Map';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';

const styles = theme => ({
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      ip_location: false,
      browser_location: false
    };
    // Bind functions
    this.ipLocation = this.ipLocation.bind(this);
    this.browserLocation = this.browserLocation.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  addressInfo(lat, lon, cb) {
    // Informació segons posició
    axios.get(
      '/api/get_address',
      {
        params: {
          lat: lat,
          lon: lon,
        },
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        const address = res.data;
        return cb(address);
      });
  }

  ipLocation() {
    axios.get(
      '/api/ip_location',
      {
        //ip: 88.9.13.254,
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        const lat = res.data.ip_location.latitude;
        const lon = res.data.ip_location.longitude;
        this.addressInfo(lat, lon, (address) => {
          this.setState({
            ip_location: {
              lat: lat,
              lon: lon,
              address: address
            }
          });
        });
      });
  }

  browserLocation(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    this.addressInfo(lat, lon, (address) => {
      this.setState({
        browser_location: {
          lat: lat,
          lon: lon,
          address: address
        }
      });
    });
  }

  // lifecycle methods

  componentDidMount() {
    this.ipLocation();

    navigator.geolocation.getCurrentPosition((position) => {
      this.browserLocation(position);
    });
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    //const { classes } = this.props;
    const { ip_location, browser_location } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Localització d'usuaris
            </Typography>
            <Typography variant="h3" component="h3">
              Diferències entre la localització del navegador i segons IP
            </Typography>
            <div className="map">
              <FullScreen>
                <Map
                  ipLocation ={ip_location}
                  browserLocation ={browser_location}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography container="div">
              Obtenim les dades de geolocalització del navegador i de la API de ipStack que ens dona informació segons la IP utilitzada. Cal haver donat els permisos corresponents per poder accedir a la informació de geolocalització del navegador. Tant la informació dels punts com la de la ruta per arribar d'un punt a l'altre s'obtenen a través de la API de Openroute Service.
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
            <Typography container="div">
              Visualitzem els resultats que ens donen els dos sistemes de geolocalització més populars, la distància que els separa i una ruta que els uneix, situant-los sobre el mapa amb informació de cadascún dels elements.
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state}/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
