// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AOS from 'aos'

// Components
import ScrollToTop from './Global/ScrollToTop';
import NavBar from './Global/NavBar';
import Header from './Global/Header';
import Footer from './Global/Footer';

// Import CSS
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

// MuiTheme
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  }
});
theme.shadows = []; // no shadows

const styles = {
  root: {
    width: '100%',
    minHeight: '100vh'
  }
};

// Init AOS
AOS.init({
  once: true
});

class Layout extends Component {

  // init scroll
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // render
  render() {
    const { classes, children } = this.props;

    return (
      <ScrollToTop>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <NavBar/>
            <Header/>
            {children}
            <Footer
              bgColor="#333"
              logoColor="#fff"
            />
          </div>
        </MuiThemeProvider>
      </ScrollToTop>
    );
  }
}

// props validation
Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(Layout);
