// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const LogoDigitalOcean = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 2500 422" xmlSpace="preserve">
      <g>
        <path fill={color} d="M210,421.8v-82c87,0,154.1-85.8,120.5-176.5c-12.4-34.1-39.2-61-73.3-73.3c-67-25.2-141.7,8.7-166.9,75.7
        	c-5.4,14.3-8.2,29.5-8.3,44.8H0C0,73.8,133-34.3,278.3,10.4c63.4,19.9,113.1,69.6,133,133C456,288.8,347.9,421.8,210,421.8z"/>
        <path fill={color} d="M211.2,339.8h-82V259h82 M129.2,403.2H67.1v-63.4h62.1V403.2 M67.1,339.8H15v-52.2h52.1 M749.3,122.3
        	c-23.6-16.2-54.7-25-89.5-25H584v242.4h75.8c34.8,0,64.6-8.7,89.5-26.1c13.6-9.9,24.3-23.2,31.1-38.5c7.5-16.2,11.2-36.1,11.2-57.2
        	s-3.7-41-11.2-55.9C772.9,143.4,762.9,131,749.3,122.3L749.3,122.3z M628.7,138.4h23.6c26.1,0,48.5,5,65.8,16.2
        	c18.6,11.2,27.4,32.4,27.4,63.4s-10,53.4-27.4,65.9c-16.1,10-37.3,16.2-64.6,16.2h-24.8V138.4 M844.9,93.7c-7-0.2-13.7,2.5-18.6,7.5
        	c-5.7,4.4-9,11.4-8.7,18.6c0,14.4,11.7,26.1,26.1,26.1c14.4,0,26.1-11.7,26.1-26.1c0.2-7-2.5-13.7-7.5-18.6
        	C858.6,96.2,852.3,93.7,844.9,93.7 M822.4,168.3h43.5v170.3h-43.3V168.3 M1021.4,183.3c-12.5-11.2-27.4-18.6-43.5-18.6
        	c-22.2-0.5-43.4,8.6-58.4,25c-15.5,16.4-24,38.3-23.6,60.9c0,23.6,7.5,44.7,22.5,60.9c15,16.2,34.8,23.6,58.4,23.6
        	c15.2,0.2,30.1-4.6,42.2-13.7v3.7c0,13.7-3.7,25-11.2,32.4c-7.5,7.5-17.5,11.2-31.1,11.2c-20,0-32.3-7.5-47.2-28.6l-29.9,28.6
        	l1.2,1.2c6.2,8.7,16.1,17.5,28.6,26.1c12.4,8.6,28.6,12.5,47.2,12.5c26.1,0,46-7.5,60.9-23.6c14.9-16.1,23.6-36.1,23.6-62.2V168.3
        	h-42.2v15H1021.4 M1010.2,283.9c-7.5,8.7-17.5,12.5-29.9,12.5c-12.5,0-22.5-3.7-29.9-12.5c-7.5-8.8-11.2-20-11.2-33.6
        	s4-24.9,11.5-33.6c7.5-8.7,17.5-12.5,29.9-12.5s22.5,3.7,29.9,12.5s11.2,20,11.2,33.6c0,13.6-3.7,25-11.2,33.6 M1101.2,168.3h43.5
        	v170.3h-43.8V168.3 M1122,93.7c-14.1-0.3-25.7,10.8-26.1,24.8c0,0.4,0,0.8,0,1.3c0,14.4,11.7,26.1,26.1,26.1
        	c14.4,0,26.1-11.7,26.1-26.1c0.3-14.1-10.8-25.7-24.8-26.1C1122.9,93.7,1122.4,93.7,1122,93.7 M1237.5,122.3h-42.2v46h-25v38.5h25
        	v70.9c0,22.5,5,38.5,13.7,47.2c8.7,8.7,25,13.7,46,13.7c7.1,0.2,14.1-0.2,21.1-1.2h2.5v-38.5l-15,1.2c-10,0-17.5-1.2-20-5
        	c-3.7-3.7-5-11.2-5-22.5v-64.5h39.8v-38.5h-39.6L1237.5,122.3 M1478.6,97.3h43.5v242.4h-43.4V97.5 M1955.8,278.9
        	c-6.4,7.3-13.4,14-21.1,20c-6.8,4.1-14.5,6.3-22.5,6.2c-11.8-0.1-23-5.1-30.9-13.8c-8.7-10-12.5-21.1-12.5-36.1
        	c0-14.9,3.7-26.1,12.5-36.1c7.7-9.2,19.1-14.2,31.1-13.7c13.7,0,28.6,8.7,41,23.6l28.6-27.4c-18.6-23.6-42.2-34.8-70.8-34.8
        	c-23.6,0-43.5,8.7-60.9,26.1c-16.3,17.1-25.2,39.8-25,63.4c0,25,8.7,47.2,25,63.4c15.6,17,37.8,26.5,60.9,26.1
        	c31.1,0,55.9-13.7,73.3-38.5l-28.6-28.6 M2131,193c-6.5-8.6-15.1-15.5-24.9-20c-11.3-5.2-23.6-7.8-36-7.5
        	c-23.6,0-43.5,8.7-58.4,26.1c-14.1,18.1-21.6,40.5-21.1,63.4c0,26.1,7.5,47.2,23.6,63.4c16.1,16.2,36,25,62.1,25
        	c28.6,0,52.2-11.2,70.8-34.8l1.2-1.2l-28.6-27.4c-2.5,3.7-6.2,6.2-10,10c-3.5,3.7-7.8,6.7-12.5,8.7c-6.5,3.5-13.8,5.3-21.1,5
        	c-10.8,0.2-21.4-3.4-29.9-10c-7.5-6.2-11.2-15-12.5-26.1H2148v-16.2c0.3-11-1.4-22-5-32.4c-1.8-9.6-6.1-18.6-12.5-26.1
        	 M2037.8,232.9c1.9-7.5,5.8-14.4,11.2-20c5.9-5.9,14.1-9.1,22.5-8.7c10,0,18.6,2.5,23.6,8.7c5,5,7.5,12.5,8.7,20H2037.8
        	 M2296.2,181.9c-12.5-11.2-31.1-16.2-53.4-16.2c-13.7,0.4-27.1,3.4-39.7,8.7c-11.2,6.2-22.5,15-28.6,27.4L2202,228
        	c11.2-18.6,23.6-25,41-25c8.1-0.2,16.1,2.4,22.5,7.5c5.7,4.4,9,11.4,8.7,18.6v8.7c-10-3.4-20.5-5.1-31.1-5c-21.1,0-38.5,5-52.2,15
        	c-13.7,10-20,25-20,42.3c0,16.2,5,28.6,16.1,38.5c11.2,9.9,24.9,13.7,41,13.7s31.1-6.2,46-17.5v13.7h42.2V229.2
        	C2316.3,209.2,2310.1,193.1,2296.2,181.9 M2220.5,273.9c5-3.7,11.2-5,20-5c11,0.4,21.9,2.5,32.3,6.2v16.2
        	c-10.3,9-23.6,13.9-37.3,13.7c-7.5,0-13.7-1.2-17.5-5c-3.8-3.7-6.2-7.5-6.2-12.5C2212.7,282,2215.9,277,2220.5,273.9 M2481.4,184.4
        	c-12.5-13.7-28.6-20-49.7-20c-17.4,0-31.1,5-41,15v-10h-42.3v170.3h43.5v-94.4c0-12.5,2.5-23.6,8.7-31.1
        	c6.2-7.4,15.4-11.5,24.9-11.2c8.6-0.4,17,3.3,22.5,10c5.8,7.9,8.9,17.6,8.7,27.4v99.4h43.3v-99.6
        	C2498.8,216.7,2492.5,198.1,2481.4,184.4 M1425.2,181.9c-12.5-11.2-31.1-16.2-53.4-16.2c-13.7,0.4-27.2,3.3-39.8,8.7
        	c-11.2,6.2-22.5,15-28.6,27.4l27.4,26.1c11.2-18.6,23.6-25,41-25c8.1-0.2,16.1,2.4,22.5,7.5c5.7,4.4,9,11.4,8.7,18.6v8.7
        	c-10-3.4-20.5-5.1-31.1-5c-21.1,0-38.5,5-52.2,15c-13.7,10-20,25-20,42.3c0,16.2,5,28.6,16.1,38.5c11.2,9.9,25,13.7,41,13.7
        	c16,0,31.1-6.2,46-17.5v13.7h42.2V229.2C1445.2,209.2,1437.7,193.1,1425.2,181.9 M1348.2,273.9c5-3.7,11.2-5,20-5
        	c11,0.4,21.9,2.5,32.3,6.2v16.2c-10.3,9-23.6,13.9-37.3,13.7c-7.5,0-13.7-1.2-17.5-5c-3.8-3.7-6.2-7.5-6.2-12.5
        	C1341.4,282.4,1344.3,277.7,1348.2,273.9 M1677.5,343.5c-68.6,0-124.3-55.6-124.4-124.3s55.6-124.3,124.3-124.4
        	c68.6,0,124.3,55.6,124.4,124.3c0,0,0,0,0,0C1801.6,287.7,1746.1,343.2,1677.5,343.5 M1677.5,137.1c-44.6-0.1-80.8,36-80.9,80.7
        	s36,80.8,80.7,80.9s80.8-36,80.9-80.7c0,0,0,0,0-0.1c0.1-44.5-35.9-80.7-80.4-80.8C1677.7,137.2,1677.6,137.2,1677.5,137.1"/>
      </g>
    </svg>
  )
}

LogoDigitalOcean.defaultProps = {
  color: '#111',
};

LogoDigitalOcean.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LogoDigitalOcean;
