// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import LaunchIcon from '@material-ui/icons/Launch';
import DownloadIcon from '@material-ui/icons/GetApp';


const styles = theme => ({
  box: {
    position: 'relative'
  },
  buttons: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    width: '100px',
  },
  link: {
    marginRight: '10px'
  }
});


const ListItemComponent = (props) => {
  const { classes, item, preview, download } = props;

  const previewStyle = {
    paddingTop: '100%',
    backgroundColor: '#eee',
    backgroundImage: `url(${item.thumb})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  };

  // render
  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <div className={classes.box} data-aos="fade-up">
        <div className={classes.preview} style={previewStyle}></div>
        <div className={classes.buttons}>
          <Fab size="small" className={classes.link} onClick={preview.bind(this, item)}>
            <LaunchIcon />
          </Fab>
          <Fab size="small" className={classes.link} onClick={download.bind(this, item)}>
            <DownloadIcon />
          </Fab>
        </div>
      </div>
    </Grid>
  )
}
const ListItem = withStyles(styles)(ListItemComponent);


const List = (props) => {
  const { entities, preview, download } = props;

  let content = `No s'ha obtingut cap resultat...`;

  if (entities && entities.length > 0) {
    const items = _.map(entities, (item, key) => {
      return (
        <ListItem
          key={`item_${key}`}
          item={item}
          preview={preview}
          download={download}
        />
      );
    });
    content = <Grid container spacing={2}>{items}</Grid>;
  }

  // render
  return (
    <Fragment>
      {content}
    </Fragment>
  );
};

// props validation
List.defaultProps = {
  entities: false
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
