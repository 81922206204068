// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 430.1 411" xmlSpace="preserve">
      <g>
      	<path fill={color} d="M430.1,252v159h-92.2V262.6c0-37.3-13.3-62.7-46.7-62.7c-25.5,0-40.6,17.1-47.3,33.7
      		c-2.4,5.9-3.1,14.2-3.1,22.5V411h-92.2c0,0,1.2-251.3,0-277.3h92.2V173c-0.2,0.3-0.4,0.6-0.6,0.9h0.6V173
      		c12.3-18.9,34.1-45.8,83.1-45.8C384.6,127.2,430.1,166.8,430.1,252z M52.2,0C20.6,0,0,20.7,0,47.9c0,26.6,20,47.9,51,47.9h0.6
      		c32.2,0,52.2-21.3,52.2-47.9C103.1,20.7,83.7,0,52.2,0z M5.5,411h92.2V133.7H5.5V411z"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
