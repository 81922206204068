// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


// Components
import Container from '../../Global/Container';
import Map from './Map';
import Form from './Form';
import FormAPI from './FormAPI';
//import Tags from './Tags';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';

const styles = theme => ({
  map: {
    height: '350px',
    margin: '30px 0 15px 0'
  }
});

const center = {
  lat: 41.981651,
  lng: 2.823610
};

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      position: center,
      address: {},
      elevation: '',
      api: 'mapquest'
    };
    // Bind functions
    this.toAddress = this.toAddress.bind(this);
    this.toCoords = this.toCoords.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.getElevation = this.getElevation.bind(this);
    this.changeApi = this.changeApi.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  // custom functions

  updatePosition(position) {
    // cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on update map.');
    // change state
    this.setState({
      position: position,
      address: {},
      elevation: ''
    });
    // get elevation
    this.getElevation();
  }

  updateAddress(address) {
    // cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on update form.');
    // change state
    this.setState({
      position: {
        lat: '',
        lng: ''
      },
      address: {
        format_address: address,
      },
      elevation: ''
    });
  }

  toAddress() {
    const { position } = this.state;
    // adreça segons posició
    if (position) {
      axios.get(
        '/api/get_address',
        {
          params: {
            lat: position.lat,
            lon: position.lng,
            api: this.state.api
          },
          cancelToken: new CancelToken( (c) => { cancel = c; })
        }
      )
        .then(res => {
          const address = res.data;
          if (address) {
            this.setState({
              address: address
            });
          }
        });
    }
  }

  toCoords() {
    const { address, api } = this.state;

    if (address.format_address) {
      // text search
      const text = `${address.format_address.address} ${address.format_address.locality} ${address.format_address.postalcode}`;
      // Informació segons posició
      if (text) {
        axios.get(
          '/api/get_coords',
          {
            params: {
              text: text.replace(/<[^>]+>/g, ' '),
              api: api
            },
            cancelToken: new CancelToken( (c) => { cancel = c; })
          }
        )
          .then(res => {
            const coords = res.data;
            if (coords) {
              this.setState({
                position: {
                  lat: coords.lat,
                  lng: coords.lng
                }
              }, () => {
                //
                this.getElevation();
              });
            }
          });
      }
    }
  }

  getElevation() {
    const { position, api } = this.state;
    // adreça segons posició
    if (position) {
      axios.get(
        '/api/get_elevation',
        {
          params: {
            lat: position.lat,
            lng: position.lng,
            api: api
          },
          cancelToken: new CancelToken( (c) => { cancel = c; })
        }
      )
        .then(res => {
          const elevation = res.data.elevation;
          if (elevation) {
            this.setState({
              elevation: elevation
            });
          }
        });
    }
  }

  clearForm() {
    // cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user clearing form.');
    // change state
    this.setState({
      address: {},
      /*
      position: {
        lat: '',
        lng: ''
      },
      elevation: ''
      */
    });
  }

  changeApi(value) {
    // cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user clearing form.');
    // change state
    this.setState({
      api: value,
      address: {},
      elevation: ''
    }, () => {
      //
      this.getElevation();
      this.toAddress();
    });
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    const { classes } = this.props;
    const { position, address, elevation, api } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Get Lat Lon
            </Typography>
            <Typography variant="h3" component="h3">
              Geolocalització i geolocalització inversa
            </Typography>
            <div className={classes.map}>
              <FullScreen>
                <Map
                  position={position}
                  updatePosition={this.updatePosition}
                />
              </FullScreen>
            </div>
            <Form
              position={position}
              address={address}
              elevation={elevation}
              toAddress={this.toAddress}
              toCoords={this.toCoords}
              getElevation={this.getElevation}
              clearForm={this.clearForm}
              updatePosition={this.updatePosition}
              updateAddress={this.updateAddress}
            />
            <FormAPI
              api={api}
              changeApi={this.changeApi}
            />
            <Typography variant="h5" component="h5">
              <strong>Dades</strong>
            </Typography>
            <Typography container="div">
              Utilització de les APIS dels serveis de localització geogràfica <strong>OpenRouteService</strong> i <strong>MapQuest</strong> per a realitzar diferents tasques: geolocalització, geolocalització inversa i buscar l'elevació.
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
