// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Svg = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 66.935 80.338" xmlSpace="preserve">
      <g>
        <path fill={color} d="M42.169,0H25.146c-4.258,0-7.71,3.451-7.71,7.71v17.021c0,4.258,3.452,7.708,7.71,7.708h17.023
        c4.256,0,7.708-3.45,7.708-7.708V7.71C49.877,3.451,46.426,0,42.169,0z M27.171,26.472h-3.347V16.57h3.347V26.472z M25.885,13.287
        c-1.907,0-3.45-1.544-3.45-3.452c0-1.908,1.543-3.452,3.45-3.452c1.906,0,3.452,1.544,3.452,3.452
        C29.337,11.742,27.794,13.287,25.885,13.287z M32.611,26.472h-3.347V16.57h3.347V26.472z M38.051,26.472h-3.348V16.57h3.348V26.472
        z M43.49,26.472h-3.348V16.57h3.348V26.472z M41.427,13.287c-1.906,0-3.449-1.544-3.449-3.452c0-1.908,1.543-3.452,3.449-3.452
        c1.906,0,3.452,1.544,3.452,3.452C44.879,11.742,43.333,13.287,41.427,13.287z"/>
        <path fill={color} d="M66.916,68.969c0.02-0.111-0.026-0.209-0.026-0.321c0-0.104,0.026-0.209,0.026-0.313c0-1.67-1.557-3.06-3.465-3.047h-0.553
        v-4.799h0.088L63,59.125c0.052-5.014,0.137-12.588-5.034-17.815c-3.394-3.431-8.451-5.168-15.027-5.168v0.034
        c-0.255-0.037-0.507-0.107-0.769-0.107H25.146c-0.191,0-0.373,0.06-0.559,0.077c-6.755,0.015-11.927,1.782-15.372,5.266
        c-3.836,3.877-4.776,9.009-4.983,13.426l-0.121,0.091v10.356H3.625c-1.965,0-3.545,1.367-3.545,3.043c0,0.013,0,0.026,0,0.052
        C0.041,68.57,0,68.756,0,68.949v3.691c0,1.35,1.278,2.441,2.855,2.441c1.559,0,2.859-1.092,2.859-2.441v-1.285l4.644-0.013v1.298
        c0,1.337,1.271,2.426,2.831,2.426c1.578,0,2.855-1.089,2.855-2.426l-0.02-3.704c0.02-0.111-0.023-0.209-0.023-0.32
        c0-0.104,0.023-0.209,0.023-0.313c0-1.654-1.581-3.034-3.529-3.018h-0.569V55.082c0.157-3.092,0.734-6.205,2.759-8.257
        c0.738-0.746,1.737-1.301,2.862-1.772c-0.057,0.503-0.115,1.014-0.115,1.537v23.229c0.003,5.812,3.456,10.52,7.713,10.52h17.023
        c4.256,0,7.708-4.707,7.708-10.52V46.589c0-0.54-0.059-1.053-0.114-1.569c1.069,0.448,2.023,0.987,2.729,1.703
        c1.981,2.004,2.579,5.047,2.746,8.104l-0.026,0.02v10.441h-0.477c-1.929,0-3.485,1.376-3.485,3.07c0,0.013,0,0.029,0,0.049
        c-0.033,0.196-0.075,0.382-0.075,0.575v3.727c0,1.36,1.259,2.462,2.805,2.462c1.533,0,2.808-1.102,2.808-2.462V71.41l4.56-0.016
        v1.313c0,1.344,1.249,2.445,2.782,2.445c1.546,0,2.805-1.102,2.805-2.445L66.916,68.969z M44.873,67.062l-2.367,2.367l-8.851-8.856
        l-8.851,8.856l-2.367-2.367l8.851-8.855l-8.851-8.859l2.367-2.367l8.851,8.859l8.851-8.859l2.367,2.367l-8.849,8.859L44.873,67.062
        z"/>
      </g>
    </svg>
  )
}

Svg.defaultProps = {
  color: '#111',
};

Svg.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Svg;
