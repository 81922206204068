import * as types from '../actions/actionTypes';

const initialState = {
  process: false,
  send: false,
  mail: false,
  error: false
};

const newsletterReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.SEND_NEWSLETTER_BEGIN:
      return Object.assign({}, state, {
        process: true
      });

    case types.SEND_NEWSLETTER_SUCCESS:
      return Object.assign({}, state, {
        process: false,
        send: true
      });

    case types.SEND_NEWSLETTER_ERROR:
      return Object.assign({}, state, {
        process: false,
        send: true
      });

    default:
      return state;
  }
}

export default newsletterReducer;
