// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  checkbox: {
    height: '32px',
  }
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods
  componentDidMount(){
    this.nameInput.focus();
  }

  // render
  render() {
    const { classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            name: '',
            index: '{ "field": 1 }',
            unique: false,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.nameInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="index"
                    label="Index"
                    placeholder="Index"
                    value={values.index}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.indexInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="unique"
                        value="unique"
                        className={classes.checkbox}
                        disableRipple={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.unique}
                      />
                    }
                    label="Unique"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!dirty}
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}


// props validation
FormComponent.defaultProps = {
  accept: "image/*"
};

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormComponent);
