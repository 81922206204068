// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Map from './Map';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';

const styles = theme => ({
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      geoJson: {}
    };
    // Bind functions
    this.updateData = this.updateData.bind(this);
  }

  // custom functions
  updateData(data) {
    this.setState({
      geoJson: data
    });
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  // render
  render() {
    //const { classes } = this.props;
    const { geoJson } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Leaflet Draw
            </Typography>
            <Typography variant="h3" component="h3">
              Generar l'estructura d'un arxiu GEOJson sobre un mapa
            </Typography>
            <div className="map">
              <FullScreen>
                <Map
                  updateData={this.updateData}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
            <Typography component="div">
              Una de les diferents extensions disponibles per a la llibreria <strong>Leaflet</strong> és <a href="https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html" target="_blank" rel="noopener noreferrer"><strong>draw</strong></a>, que ens dona eines per crear i editar objectes GEOJson sobre un mapa web. Podem veure com es genera l'estructura a l'interactuar amb les eines del mapa.
            </Typography>
          </Container>
        </section>
        <DataSet data={geoJson} title="GEOJson"/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
