import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';

// Import reducers to combine
import authReducer from './reducers/auth';
import projectsReducer from './reducers/projects';
import newsletterReducer from './reducers/newsletter';
import weatherReducer from './reducers/weather';


// Combine reducers
const reducer = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  newsletter: newsletterReducer,
  weather: weatherReducer
});

// Create history
export const history = createHistory();

// Config store
export default function configureStore(initialState) {

  const reactRouterMiddleware = routerMiddleware(history);

  const store = createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(reactRouterMiddleware, thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );

  return store;
}
