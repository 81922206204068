/*
Zoom
https://bl.ocks.org/johnnygizmo/3b16c4aa235ea9c0588d1bb26afad79a
https://bl.ocks.org/mbostock/db6b4335bf1662b413e7968910104f0f
http://bl.ocks.org/nicolashery/9627333
*/

// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as d3 from 'd3';
import _ from 'lodash';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.defs = React.createRef();
    this.tooltip = React.createRef();
    this.tip = React.createRef();
    this.x = React.createRef();
    this.y = React.createRef();
    this.g = React.createRef();
    this.xAxis = React.createRef();
    this.xAxisCall = React.createRef();
    this.yAxis = React.createRef();
    this.yAxisCall = React.createRef();
  }

  // custom functions

  updateGraph() {
    const { width, height, margin, data  } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // transitions
    const t = d3.transition().duration(750);

    // if valid data
    if (!_.isEmpty(data)) {
      // format data
      const parseDate = d3.timeParse('%Y-%m-%d');
      _.map(data, (d) => {
        d.date = parseDate(d._id);
      });

      // Update our scales
      const scale_x = this.x.domain(d3.extent(data, function(d) { return d.date; }));
      const scale_y = this.y.domain([-5, 40]);
      const tip = this.tip;

      // Update our axis
      this.xAxis.call(this.xAxisCall);
      this.yAxis.call(this.yAxisCall);

      this.yAxis.selectAll('.tick text').attr('dx', 16).attr('dy', -4);
      this.yAxis.call(g => g.select(".domain").remove())

      // create data based gradients
      const clips = this.defs.selectAll('.clip')
        .data(data);
      // EXIT old elements not present in new data
      clips.exit().remove();
      // ENTER new elements present in new data
      const enter_clips = clips.enter().append('clipPath');
      enter_clips
        .attr('id', (d,i) => { return `clip-${i}`; })
        .attr('class', 'clip')
        .append('rect')
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('x', (d) => { return scale_x(d.date) - 5; })
        .attr('width', 10)
        .attr('y', (d) => { return scale_y(d.max); })
        .attr('height', (d) => {
          const val = scale_y(d.min) - scale_y(d.max);
          return val<10?10:val;
        });

      // General Update Pattern
      // JOIN new data with old elements
      const bars = this.g.selectAll('bar')
        .data(data);
      // EXIT old elements not present in new data
      bars.exit().transition(t)
        .attr('fill-opacity', 0)
        .attr('cy', scale_y(0))
        .remove();
      // UPDATE old elements present in new data
      bars.transition(t)
        .attr('cx', (d) => { return scale_x(d.date); })
        .attr('cy', (d) => { return scale_y(d.min); });
      // ENTER new elements present in new data
      const enter_bars = bars.enter().append("rect");
      // atributes
      enter_bars
        .attr('x', (d) => { return scale_x(d.date) - 5; })
        .attr('width', 10)
        .attr('y', 0)
        .attr('height', height_data)
        .attr('clip-path', (d,i) => { return `url(#clip-${i})`; })
        .attr('fill', 'url(#gradient)')
        .attr('fill-opacity', 0);
      // transition
      enter_bars
        .transition(t)
        .attr('fill-opacity', 1);
      // mouse events
      enter_bars
        .on('mouseover', function() {
          tip.transition()
            .duration(200)
            .style("opacity", 1);
        })
        .on('mouseout', function() {
          tip.transition()
            .duration(500)
            .style("opacity", 0);
        })
        .on('mousemove', function(d) {
          tip.style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        })
        .on('mouseenter', function(d) {
          const f = d3.format('.2f');
          const date = moment(d.date).format('DD/MM/YYYY');
          tip.html(`Max: ${f(d.max)}<br/>Min: ${f(d.min)}<br/>${date}`)
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        });
    }

    // zoom + pan
    const zoomed = () => {
      // Modifiquem axis
      this.xAxis.call(this.xAxisCall.scale(d3.event.transform.rescaleX(this.x)));
      // Modifiquem posició x graph
      const loc = getDatetimeLocation();
      console.log(d3.event.transform.x, loc);
      this.g.attr("transform", `translate(${d3.event.transform.x},0)`);
    }

    // graph width
    const graph_width = this.g.node().getBoundingClientRect().width;
    console.log(width,width_data,graph_width);
    // set zoom
    let zoom = d3.zoom()
      .translateExtent([[-20,0],[width+20,0]])
      .scaleExtent([1, 10])
      .on("zoom",zoomed);

    // call zoom
    this.zoom_wrap = d3.select('.graph_wrapper');
    this.zoom_wrap.call(zoom).on("wheel.zoom", null);

    // Get datetime value left edge is currently "located" at
    const getDatetimeLocation = () => {
      const dx = d3.zoomTransform(this.zoom_wrap.node()).x;
      const stringifyDatetime = d3.timeFormat('%Y-%m-%dT%H:%M:%S');
      let datetime = this.x.invert(-dx);
      return stringifyDatetime(datetime);
    }
  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // defs
    this.defs = this.canvas.append('defs');
    // define bars linear gradient
    const linearGradient = this.defs.append('linearGradient')
      .attr('id', 'gradient')
      .attr('class', 'gradient')
      .attr('x1', '0%').attr('y1', '0%')
      .attr('x2', '0%').attr('y2', '100%');
    linearGradient.append('stop')
      .attr('offset', '10%')
      .attr('stop-color', '#C0392B');
    linearGradient.append('stop')
      .attr('offset', '30%')
      .attr('stop-color', '#e67e22');
    linearGradient.append('stop')
      .attr('offset', '50%')
      .attr('stop-color', '#f1c40f');
    linearGradient.append('stop')
      .attr('offset', '70%')
      .attr('stop-color', '#1abc9c');
    linearGradient.append('stop')
      .attr('offset',  '90%')
      .attr('stop-color', '#3498db');

    // init main group
    this.g = this.canvas.select(".graph");

    // Caldria que ens assegurèssim que el número de dades és el correcte.

    // init scales
    this.x = d3.scaleTime().range([0, width_data]);
    this.y = d3.scaleLinear().range([height_data, 0]);

    // init axes and labels
    this.xAxisCall = d3.axisBottom(this.x).tickFormat(d3.timeFormat('%Y-%m-%d')).tickSizeOuter(0).ticks(5);
    this.xAxis = this.canvas.select(".x_axis");

    this.yAxisCall = d3.axisLeft(this.y).tickSize(width_data).tickSizeOuter(0).ticks(4);
    this.yAxis = this.canvas.select(".y_axis");

    // tooltip
    this.tip = d3.select(this.tooltip);
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height, margin } = this.props;
    const width_data = width - (margin[0]+margin[2]);
    const height_data = height - (margin[1]+margin[3]);

    return (
      <Fragment>
        <div
          ref={el => this.tooltip = el}
          className="tooltip"
        />
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="#fff"
          />
          <g
            transform={`translate(${margin[0]}, ${margin[1]})`}
          >
            <clipPath id="clip">
              <rect  className="clip" width={width_data} height={height_data} />
            </clipPath>
            <g className="graph_wrapper" clipPath="url(#clip)" width={width_data} height={height_data}>
              <g className="graph" transform={`translate(0, 0)`} />
            </g>
            <g className="axis x_axis" transform={`translate(0, ${height_data})`}></g>
            <g className="grid-axis y_axis" strokeDasharray="2,2" transform={`translate(${width_data}, 0)`}>
              <text className="axis-title" transform={`translate(0, -4)`}>ºC</text>
            </g>
          </g>
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:900,
  height:300,
  margin:[40,50,40,50],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;

// https://bl.ocks.org/johnnygizmo/3b16c4aa235ea9c0588d1bb26afad79a
