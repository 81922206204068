// Dependencies
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Auth
import auth from '../lib/auth';

// Components
import AppLayout from './Layout';
import AppError from './Error/Index';
import Home from './Home/Index';
import Labs from './Labs/Index';
import Wall from './Wall/Index';

import LoginLayout from './Login/Layout';
import Login from './Login/Login/Index';
import Logout from './Login/Logout/Index';
import RecoverPassword from './Login/RecoverPassword/Index';

import AdminLayout from './Admin/Layout';
import AdminError from './Admin/Error/Index';
import Dev from './Admin/Dev/Index';
import Dashboard from './Admin/Dashboard/Index';
import Logs from './Admin/Logs/Index';
import Strings from './Admin/Strings/Index';
import Backups from './Admin/Backups/Index';
import Uploads from './Admin/Uploads/Index';
import Users from './Admin/Users/Index';
import Database from './Admin/Database/Index';

import LabsModel from './Admin/DataModels/Labs/Index';
import WorksModel from './Admin/DataModels/Works/Index';
import SocialPostsModel from './Admin/DataModels/SocialPosts/Index';
import WeathersModel from './Admin/DataModels/Weathers/Index';
import ApartmentsModel from './Admin/DataModels/Apartments/Index';
import ApartmentHostsModel from './Admin/DataModels/ApartmentHosts/Index';

import LabsLayout from './LabsView/Layout';
import LabsAirbnb from './LabsView/Airbnb/Index';
import LabsWeather from './LabsView/Weather/Index';
import LabsGironaOpenData from './LabsView/GironaOpenData/Index';
import LabsWallStats from './LabsView/WallStats/Index';
import LabsBrowserLocation from './LabsView/BrowserLocation/Index';
import LabsGetLatLon from './LabsView/GetLatLon/Index';
import LabsLeafletDrawer from './LabsView/LeafletDrawer/Index';
import LabsSvgBrowser from './LabsView/SvgBrowser/Index';
import LabsTwittDownload from './LabsView/TwittDownload/Index';
import LabsD3RandomPoints from './LabsView/D3RandomPoints/Index';
import LabsD3RealTime from './LabsView/D3RealTime/Index';
import LabsD3Responsive from './LabsView/D3Responsive/Index';
import LabsCropImage from './LabsView/CropImage/Index';
import LabsPixelImage from './LabsView/PixelImage/Index';
import LabsScrapGraphic from './LabsView/ScrapGraphic/Index';
import LabsScrapTables from './LabsView/ScrapTables/Index';
import LabsCurrencyConverter from './LabsView/CurrencyConverter/Index';
import LabsSnake from './LabsView/SnakeGame/Index';
import LabsPlatform from './LabsView/PlatformGame/Index';


// Auth
const isAuth = () => {
  return auth.loggedIn();
}

/*
const PrivateRoute = ({ component: Component, ...rest}) => (
  <Route {...rest} render={props => (
    isAuth() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)
*/


const AppRoutes = ({ location }) => {
  //const locationKey = props.location.pathname

  return (
    <AppLayout
      logged={isAuth()}
    >
      <Switch location={location}>
        <Route exact path="/" component={Home} />
        <Route exact path="/project/:id" component={Home} />
        <Route exact path="/labs" component={Labs} />
        <Route exact path="/wall" component={Wall} />
        <Route exact path="/logout" component={Logout} />
        {/* Si no coindiceix amb cap url ens mostrarà la pàgina d'error */}
        <Route component={AppError} />
      </Switch>
    </AppLayout>
  )
}


const LoginRoutes = ({ location }) => {
  //const locationKey = props.location.pathname

  return (
    <LoginLayout
      logged={isAuth()}
    >
      <Switch location={location}>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/recover_password" component={RecoverPassword} />
        {/* Redirigim totes les altres pàgines a l'inici */}
        <Redirect to="/" />
      </Switch>
    </LoginLayout>
  )
}


const LabsRoutes = ({ match, location }) => {
  //const locationKey = props.location.pathname

  return (
    <LabsLayout
      logged={isAuth()}
    >
      <Switch location={location}>
        <Route exact path={`${match.path}/airbnb`} component={LabsAirbnb} />
        <Route exact path={`${match.path}/openweather`} component={LabsWeather} />
        <Route exact path={`${match.path}/girona_open_data`} component={LabsGironaOpenData} />
        <Route exact path={`${match.path}/wall_stats`} component={LabsWallStats} />
        <Route exact path={`${match.path}/browser_location`} component={LabsBrowserLocation} />
        <Route exact path={`${match.path}/get_lat_lon`} component={LabsGetLatLon} />
        <Route exact path={`${match.path}/leaflet_drawer`} component={LabsLeafletDrawer} />
        <Route exact path={`${match.path}/svg_browser`} component={LabsSvgBrowser} />
        <Route exact path={`${match.path}/twitt_download`} component={LabsTwittDownload} />
        <Route exact path={`${match.path}/d3_random_points`} component={LabsD3RandomPoints} />
        <Route exact path={`${match.path}/d3_real_time`} component={LabsD3RealTime} />
        <Route exact path={`${match.path}/d3_responsive`} component={LabsD3Responsive} />
        <Route exact path={`${match.path}/crop_image`} component={LabsCropImage} />
        <Route exact path={`${match.path}/pixel_image`} component={LabsPixelImage} />
        <Route exact path={`${match.path}/scrap_graphic`} component={LabsScrapGraphic} />
        <Route exact path={`${match.path}/scrap_tables`} component={LabsScrapTables} />
        <Route exact path={`${match.path}/currencies`} component={LabsCurrencyConverter} />
        <Route exact path={`${match.path}/snake`} component={LabsSnake} />
        <Route exact path={`${match.path}/platform`} component={LabsPlatform} />
        {/* Redirigim totes les altres pàgines a l'inici */}
        <Redirect to="/labs" />
      </Switch>
    </LabsLayout>
  )
}


const AdminRoutes = ({ match, location }) => {
  return (
    <AdminLayout
      logged={isAuth()}
    >
      <Switch location={location}>
        <Route exact path={`${match.path}`} component={Dashboard} />
        <Route exact path={`${match.path}/dev`} component={Dev} />
        <Route exact path={`${match.path}/logs`} component={Logs} />
        <Route exact path={`${match.path}/strings`} component={Strings} />
        <Route exact path={`${match.path}/backups`} component={Backups} />
        <Route exact path={`${match.path}/uploads`} component={Uploads} />
        <Route exact path={`${match.path}/users`} component={Users} />
        <Route exact path={`${match.path}/database`} component={Database} />

        <Route exact path={`${match.path}/labs`} component={LabsModel} />
        <Route exact path={`${match.path}/works`} component={WorksModel} />
        <Route exact path={`${match.path}/social_posts`} component={SocialPostsModel} />
        <Route exact path={`${match.path}/weather`} component={WeathersModel} />
        <Route exact path={`${match.path}/apartments`} component={ApartmentsModel} />
        <Route exact path={`${match.path}/apartment_hosts`} component={ApartmentHostsModel} />
        {/*
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/backups" component={Backups} />
        <PrivateRoute exact path="/users" component={Users} />
        */}
        {/* Si no coindiceix amb cap url ens mostrarà la pàgina d'error */}
        <Route component={AdminError} />
      </Switch>
    </AdminLayout>
  )
}


const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/(login|logout|recover_password)" component={LoginRoutes} />
      <Route path="/admin" component={AdminRoutes} />
      <Route path="/lab" component={LabsRoutes} />
      <Route path="/" component={AppRoutes} />
    </Switch>
  </BrowserRouter>
);

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(Router);
