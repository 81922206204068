// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import DateUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';


const styles = theme => ({
  button: {
    margin: '20px 20px 0 0'
  },
  form: {
    padding: '20px 0 0'
  }
});

//
const initvalues = {
  keyword: '',
  type: {
    twitter: true,
    instagram: true,
    facebook: true,
    linkedin: true
  },
  date_begin: null,
  date_end: null //moment().valueOf()
};

// Form
class FormComponent extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Refs
    this.urlInput = React.createRef();
    this.formik = React.createRef();
  }

  // custom methods

  // lifecycle methods

  componentDidMount() {
    this.urlInput.focus();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.searchValues !== nextProps.searchValues) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { searchValues} = this.props;

    if (this.props.searchValues !== prevProps.searchValues) {
      if (this.formik.current) {
        const values = Object.assign({}, initvalues, searchValues);
        this.formik.current.resetForm(values);
      }
    }
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes, formSubmit, formReset, searchValues } = this.props;
    const types = ['twitter','instagram','facebook','linkedin'];

    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <Formik
            ref={this.formik}
            initialValues={{
              keyword: _.get(searchValues, 'keyword', ''),
              type: {
                twitter: _.get(searchValues, 'type.twitter', null),
                instagram: _.get(searchValues, 'type.instagram', null),
                facebook: _.get(searchValues, 'type.facebook', null),
                linkedin: _.get(searchValues, 'type.linkedin', null)
              },
              date_begin: _.get(searchValues, 'date_begin', null),
              date_end: _.get(searchValues, 'date_end', null)
            }}
            onSubmit={(values, { setSubmitting, resetForm, errors }) => {
              formSubmit(values);
              setSubmitting(false);
            }}
            onReset={(values, { setValues, resetForm }) => {
              formReset();
              this.urlInput.focus();
            }}
          >
            {({
              values,
              dirty,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleReset,
              setFieldValue,
              setFieldTouched,
              resetForm
            }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="keyword"
                      label="Keyword"
                      value={values.keyword || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputRef={el => this.urlInput = el}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      clearable
                      fullWidth
                      value={values.date_begin}
                      onChange={(val) => { setFieldValue('date_begin',(val?val.valueOf():null)); }}
                      label="Date begin"
                      format="DD/MM/YYYY"
                      animateYearScrolling={false}
                      minDate={new Date('2010')}
                      maxDate={values.date_end?values.date_end:Date.now()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      clearable
                      fullWidth
                      value={values.date_end}
                      onChange={(val) => { setFieldValue('date_end',(val?val.valueOf():null)); }}
                      label="Date end"
                      format="DD/MM/YYYY"
                      animateYearScrolling={false}
                      minDate={values.date_begin?values.date_begin:new Date('2010')}
                      maxDate={Date.now()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      {types.map((type, i) =>
                        <FormControlLabel
                          key={`type_${i}`}
                          control={
                            <Checkbox
                              id={`type[${type}]`}
                              checked={values.type[type]}
                              value={`type[${type}]`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          }
                          label={type}
                        />
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty || isSubmitting || !_.isEmpty(errors)}
                      className={classes.button}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={_.isEmpty(searchValues)} // ! empty values
                      onClick={handleReset}
                      className={classes.button}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FormComponent);
