// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 300 244.2" xmlSpace="preserve">
      <g transform="translate(-539.17946,-568.85777)">
        <path fill={color} d="M633.9,812c112.5,0,174-93.2,174-174c0-2.6-0.1-5.3-0.2-7.9c11.9-8.6,22.3-19.4,30.5-31.7
          c-11,4.9-22.7,8.1-35.1,9.6c12.6-7.6,22.3-19.5,26.9-33.8c-11.8,7-24.9,12.1-38.8,14.8c-11.2-11.9-27-19.3-44.6-19.3
          c-33.8,0-61.1,27.4-61.1,61.1c0,4.8,0.5,9.5,1.6,13.9c-50.8-2.6-95.9-26.9-126-63.9c-5.3,9-8.3,19.5-8.3,30.7
          c0,21.2,10.8,39.9,27.2,50.9c-10-0.3-19.5-3.1-27.7-7.6c0,0.3,0,0.5,0,0.8c0,29.6,21.1,54.3,49.1,59.9c-5.1,1.4-10.5,2.2-16.1,2.2
          c-3.9,0-7.8-0.4-11.5-1.1c7.8,24.3,30.4,42,57.1,42.5c-20.9,16.4-47.3,26.2-75.9,26.2c-4.9,0-9.8-0.3-14.6-0.8
          C567.2,801.9,599.4,812,633.9,812"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
