// Dependencies
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../Global/Container';
import Info from './Info';
import Newsletter from './Newsletter';
import Portfolio from './Portfolio';
import Social from './Social';
import Contact from './Contact';

const styles = {
};


const Index = (props) => {
  const { classes } = props;

  document.title = 'Home';

  // render
  return (
    <Fragment>
      <section className="">
        <Container fixed>
          <Info/>
        </Container>
      </section>
      <section className="">
        <Container fixed>
          <Newsletter/>
        </Container>
      </section>
      <section className="">
        <Container fixed>
          <Typography variant="h6" color="inherit">
            <FormattedMessage id="intro.title" />
          </Typography>
          <p>Fugiat blanditiis rerum orci nam molestie nihil venenatis. Expedita vivamus senectus, sem architecto, donec, massa provident mi odit, fugiat possimus, gravida potenti fuga leo, cursus, rhoncus, reprehenderit esse molestie nunc? Bibendum corrupti architecto natoque eleifend semper! Gravida condimentum eum, aute lorem cillum, dolore pulvinar euismod fugiat quos wisi voluptas mattis felis lacus bibendum ultrices saepe exercitation, porttitor augue arcu fames.</p>
          <p>Elit exercitationem? Magni doloremque orci cursus nec elit sequi earum, nulla voluptas. Consectetur molestias nonummy! Commodi pretium id. Aliquid porttitor cupidatat animi debitis sint! Cupiditate commodi aptent tempor! Tempore proin, pede, ipsa turpis odio dictumst voluptate! Diamlorem adipisci veritatis, voluptatum! Ullamcorper anim minus quasi hac, voluptatum consequuntur sociosqu? Tincidunt incidunt, nostrud integer unde beatae! Molestie sapien, incididunt quod, rem class.</p>
        </Container>
      </section>
      <section className={classes.portfolio}>
        <Container fixed>
          <Portfolio {...props}/>
        </Container>
      </section>
      <section className={classes.social}>
        <Container fixed>
          <Social/>
        </Container>
      </section>
      <section className={classes.contact}>
        <Container fixed>
          <Contact/>
        </Container>
      </section>
    </Fragment>
  );
}

export default withStyles(styles)(Index);
