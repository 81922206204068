// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { withStyles } from '@material-ui/core/styles';

// styles
import 'leaflet/dist/leaflet.css';

// L.Icon.Default brings a wrong image url
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [12, 41], // the same for the shadow
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  tooltipAnchor: [10, -21], // point from which the tooltip should open relative to the iconAnchor
});
L.Marker.prototype.options.icon = DefaultIcon;


const styles = {
  map: {
    width: '100%',
    height: '100%'
  }
};


class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  updateMarker() {
    const { position } = this.props;
    console.log('updateMarker',position);
    var lat = (position.lat);
    var lng = (position.lng);
    var newLatLng = new L.LatLng(lat, lng);
    this.marker.setLatLng(newLatLng);
    this.map.panTo(newLatLng, {animate: true, duration: 0.3});
  }

  initMap() {
    const { position, updatePosition } = this.props;

    // Tile layers -- https://leaflet-extras.github.io/leaflet-providers/preview/
    const layerMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '' });
    const layerImaginary = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: '' });

    // Create map
    this.map = L.map('map', {
      center: [position.lat, position.lng],
      zoom: 14,
      minZoom: 3,
      maxZoom: 18,
      scrollWheelZoom: false,
      layers: [ layerMap ],
      doubleClickZoom: false
    });

    // Add layer controller
    this.layersCtrl = L.control.layers(
      {
        'Map': layerMap,
        'Image': layerImaginary,
      },
      {
      },
      {
        hideSingleBase: true,
        autoZIndex: false,
        position: 'bottomleft'
      }
    ).addTo(this.map);

    // add marker center
    this.marker = L.marker(position, {draggable:'true'}).addTo(this.map);
    this.marker.bindPopup(`Lat: ${this.marker.getLatLng().lat}<br/>Lon: ${this.marker.getLatLng().lng}<br/><a href="http://www.google.com/maps?layer=c&cbll=${this.marker.getLatLng().lat},${this.marker.getLatLng().lng}" target="_blank">Street view</a>`);
    this.marker.on('dragend', function(e) {
      const position = e.target._latlng;
      updatePosition(position);
    });

    // Map actions

    // toggle overlays --> Sectors always up
    this.map.on('overlayadd overlayremove', function (event) {
      this.closePopup();
    });

    // change zoom --> close popups
    this.map.on('zoomstart', function (event) {
      this.closePopup();
    });

    // click map
    this.map.on('click', function (event) {
      this.closePopup();
      //console.log(`Click on: ${e.latlng.lat.toString()}, ${e.latlng.lng.toString()}`);
    });

    this.map.on('dblclick', function (event) {
      const lat = (event.latlng.lat);
      const lng = (event.latlng.lng);
      updatePosition({ lat,lng });
    });
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.position !== prevProps.position) {
      this.updateMarker();
    }
    if (this.props.full !== prevProps.full) {
      console.log('resize');
      this.map.invalidateSize();
    }
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        id="map"
        className={classes.map}
      >
      </div>
    );
  }
}

// props defaults
Map.defaultProps = {
  position: {
    lat: 41.981651,
    lng: 2.823610
  },
  address: {},
};

// props validation
Map.propTypes = {
  position: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired
};

export default withStyles(styles)(Map);
