// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Container from '../Global/Container';
import List from './List';

const styles = {
  grid: {
    padding: '40px 0',
  },
  creative: {
    background: '#f4f4f4',
    padding: '40px 0',
  }
};


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    document.title = 'Labs';
    // Innit state
    this.state = {
      loading: true,
      models: [],
      tags: [],
      filterVisible: false,
      filterSelected: []
    };
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  getModels() {
    axios.get('/api/get_labs', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({
          loading: false,
          models: res.data.result.models,
          tags: res.data.result.tags
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          models: [],
          tags: []
        });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.getModels();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { classes } = this.props;
    const { loading, tags, models } = this.state;

    let content;
    if (loading) {
      content = (
        <CircularProgress
          color="secondary"
          size={40}
          className={classes.progress}
        />
      );
    } else if (models.length > 0) {
        content = (
          <List
            tags={tags}
            models={models}
          />
        );
    } else {
      content = 'No s\'ha obtingut cap resultat...';
    }

    return (
      <Fragment>
        <section className={classes.grid}>
          <Container fixed>
            {content}
          </Container>
        </section>
        <section className={classes.creative}>
          <Container fixed>
            creative code
          </Container>
        </section>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
