// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';

// Components
import ListItemActions from './ListItemActions';


const styles = theme => ({
  item: {
    width: '150px',
    display: 'inline',
    padding: '0',
    margin: '0 20px 20px 0',
    position: 'relative',
  },
  iconWrap: {
    height: '150px',
    borderRadius: '10px',
    background: '#f4f4f4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    margin: '0 0 10px 0',
  },
  icon: {
    fontSize: '48px'
  },
  actions: {
    position: 'absolute',
  },
});


class ListItemDir extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Bind functions
    this.handleOpenFolder = this.handleOpenFolder.bind(this);
    this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  // custom functions

  handleRename(e) {
    const { item, rename } = this.props;
    rename(item.name);
  }

  handleDeleteFolder(e) {
    const { item, deleteFolder } = this.props;
    deleteFolder(item.route);
  }

  handleOpenFolder(e) {
    const { item, openFolder } = this.props;
    openFolder(item.route);
  }

  // drag & drop

  onDragStart(e) {
    const { item } = this.props;
    e.dataTransfer.effectAllowed = 'move';  //
    e.dataTransfer.setData('text/plain', item.name); // dragged item
    e.dataTransfer.setDragImage(e.target, 20, 20); // drag effect
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    const { item, move } = this.props
    const name = e.dataTransfer.getData('text');
    e.preventDefault();
    move(name, item.route);
  }

  // lifecycle methods


  // render
  render() {
    const { classes, item } = this.props;

    const actions = [{
      label: 'Rename',
      action: this.handleRename,
      icon: 'edit'
    },{
      label: 'Delete',
      action: this.handleDeleteFolder,
      icon: 'delete'
    },{
      label: 'Open',
      action: this.handleOpenFolder,
      icon: 'folder'
    }];

    // render
    return (
      <div
        className={classes.item}
        draggable
        onClick={(e) => this.handleOpenFolder(e)}
        onDragStart={(e) => this.onDragStart(e)}
        onDragOver={(e) => this.onDragOver(e)}
        onDrop={(e) => this.onDrop(e)}
      >
        <ListItemActions
          className={classes.actions}
          actions={actions}
        />
        <div className={classes.iconWrap}>
          <FolderIcon  className={classes.icon} />
        </div>
        <Typography component="p" variant="body2">
          {item.name}
        </Typography>
        <Typography component="p" variant="caption">
          {item.dateText}
        </Typography>
      </div>
    )
  }
}

// props validation
ListItemDir.defaultProps = {
};

ListItemDir.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  openFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListItemDir);
