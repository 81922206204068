// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import HostsPieGraph from './HostsPieGraph';


const styles = theme => ({
  hosts: {
    display: 'flex'
  },
  host: {
    width: '20%',
    padding: '0 15px',
    textAlign: 'center'
  },
  avatar: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    backgroundColor: '#eee',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  pieChart: {
    width: '300px',
    height: '300px'
  },
});


class Hosts extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes, hosts } = this.props;

    // top hosts
    const top_hosts = _.take(_.orderBy(hosts, ['total_rooms'], ['desc']), 5);
    const listHosts = _.map(top_hosts, (item, key) => {
      return (
        <div key={'slide-'+key} className={classes.host}>
          <div className={classes.avatar} style={{ backgroundImage: `url("${item.image}")` }}></div>
          <span><strong dangerouslySetInnerHTML={{ __html: item.name }}/></span><br/>
          <span>{parseInt(item.total_rooms)} Rooms</span><br/>
          <span>{parseInt(item.total_price)} €</span>
        </div>
      );
    });
    const total_hosts = hosts.length;
    const total_rooms = _.sumBy(hosts, 'total_rooms');
    const total_price = _.sumBy(hosts, 'total_price');
    const total_rooms_top = _.sumBy(top_hosts, 'total_rooms');
    const total_price_top = _.sumBy(top_hosts, 'total_price');

    // render
    return (
      <Fragment>
        <Typography variant="h6" color="inherit">
          Totals
        </Typography>
        {total_hosts} hosts<br/>
        {total_rooms} rooms<br/>
        {total_price} €<br/><br/>
        Top 5<br/>
        {total_rooms_top} rooms | {parseFloat(100*5/total_hosts).toFixed(0)}% --> {parseFloat(100*total_rooms_top/total_rooms).toFixed(0)}%<br/>
        {total_price_top} € | {parseFloat(100*5/total_hosts).toFixed(0)}% --> {parseFloat(100*total_price_top/total_price).toFixed(0)}%
        <Typography variant="h6" color="inherit">
          Hosts segons apartaments gestionats
        </Typography>
        <div className={classes.pieChart}>
          <HostsPieGraph
            data={hosts}
          />
        </div>
        <Typography variant="h6" color="inherit">
          Top 5 more rooms
        </Typography>
        <div className={classes.hosts}>
          {hosts.length > 0 ? listHosts : ''}
        </div>
        <Typography variant="h6" color="inherit">
          Totals
        </Typography>
      </Fragment>
    )
  }
}

// props validation
Hosts.defaultProps = {
  total_hosts: 0
};

Hosts.propTypes = {
  classes: PropTypes.object.isRequired,
  total_hosts: PropTypes.number.isRequired
};

export default withStyles(styles)(Hosts);
