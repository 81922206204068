// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import axios, { CancelToken } from 'axios';
import { decode } from '@mapbox/polyline';

import { withStyles } from '@material-ui/core/styles';

// styles
import 'leaflet/dist/leaflet.css';

// L.Icon.Default brings a wrong image url
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [12, 41], // the same for the shadow
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  tooltipAnchor: [10, -21], // point from which the tooltip should open relative to the iconAnchor
});
L.Marker.prototype.options.icon = DefaultIcon;

//set defaults for popups
const markerOptions = {
  autoClose: false,
  closeOnClick: false
};


const styles = {
  map: {
    width: '100%',
    height: '100%'
  }
};

const circleStyles = {
  color: '#3498db',
  opacity: 0,
  fillColor: '#3498db',
  fillOpacity: 0.4
};


class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  initMap() {
    // Tile layers -- https://leaflet-extras.github.io/leaflet-providers/preview/
    const layerMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '' });
    const layerImaginary = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: '' });

    // Create map
    this.map = new L.map('map', {
      center: [41.981651, 2.823610],
      zoom: 12,
      minZoom: 2,
      maxZoom: 15,
      scrollWheelZoom: false,
      layers: [ layerMap ]
    });

    // Add layer controller
    this.layersCtrl = L.control.layers(
      {
        'Map': layerMap,
        'Image': layerImaginary,
      },
      {
      },
      {
        hideSingleBase: true,
        autoZIndex: false,
        position: 'bottomleft'
      }
    ).addTo(this.map);

    // define bounds
    this.bounds = L.latLngBounds();

    // Map actions

    // toggle overlays --> Sectors always up
    this.map.on('overlayadd overlayremove', function (event) {
      //this.closePopup();
    });

    // change zoom --> close popups
    this.map.on('zoomstart', function (event) {
      //this.closePopup();
    });

    // click map
    this.map.on('click', function (event) {
      //this.closePopup();
      //console.log(`Click on: ${e.latlng.lat.toString()}, ${e.latlng.lng.toString()}`);
    });
  }

  fitBounds() {
    this.map.fitBounds(this.bounds, {padding: [40,40]});
  }

  addIpLocation() {
    const { ipLocation } = this.props;
    // add marker center
    const lat = ipLocation.lat;
    const lon = ipLocation.lon;
    const latLng = L.latLng(lat, lon);
    const address = ipLocation.address.text_address;
    // marker
    const markerIp = L.marker(latLng).addTo(this.map);
    markerIp.bindPopup(`<h1 style="margin:2px 0 5px;">IP</h1>${address}<br/>Lat: ${lat}<br/>Lon: ${lon}<br/><a href="http://www.google.com/maps?layer=c&cbll=${lat},${lon}" target="_blank">Street view</a>`, markerOptions);
    markerIp.openPopup();
    this.bounds.extend(latLng);
    this.fitBounds();
    // Draw circle
    L.circle(latLng, 1000, circleStyles).addTo(this.map);
  }

  addBrowserLocation() {
    const { browserLocation } = this.props;
    // add marker center
    const lat = browserLocation.lat;
    const lon = browserLocation.lon;
    const latLng = L.latLng(lat, lon);
    const address = browserLocation.address.text_address;
    // marker
    const markerBrowser = L.marker(latLng).addTo(this.map);
    markerBrowser.bindPopup(`<h1 style="margin:2px 0 5px;">Navegador</h1>${address}<br/>Lat: ${lat}<br/>Lon: ${lon}<br/><a href="http://www.google.com/maps?layer=c&cbll=${lat},${lon}" target="_blank">Street view</a>`, markerOptions);
    markerBrowser.openPopup();
    this.bounds.extend(latLng);
    this.fitBounds();
    // Draw circle
    L.circle(latLng, 1000, circleStyles).addTo(this.map);
  }

  addInfoMap() {
    const { ipLocation, browserLocation } = this.props;
    // values
    const origin = [ipLocation.lat,ipLocation.lon];
    const dest = [browserLocation.lat,browserLocation.lon];
    const latLngs = [origin,dest];
    // calc
    const meters = L.latLng(origin).distanceTo(L.latLng(dest));
    const distance = parseFloat(meters/1000).toFixed(2);
    // draw line
    const lineOptions = { color: '#3498db' };
    const line = L.polyline(latLngs, lineOptions).addTo(this.map);
    line.bindPopup(`<h1 style="margin:2px 0 5px;">${distance} Km</h1>`, markerOptions);
    line.openPopup();
    // draw route
    axios.get(`/api/get_route?from=${ipLocation.lon},${ipLocation.lat}&to=${browserLocation.lon},${browserLocation.lat}`, {cancelToken: this.cancelToken.token })
      .then(res => {
        const { geometry, distance } = res.data;
        // decode polyline
        const routePoints = decode(geometry);
        // draw route
        const routeOptions = { color: '#3498db' };
        const routeLine = L.polyline(routePoints, routeOptions).addTo(this.map);
        routeLine.bindPopup(`<h1 style="margin:2px 0 5px;">${distance} Km</h1>`, markerOptions);
      });
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.ipLocation !== prevProps.ipLocation) {
      this.addIpLocation();
    }
    if (this.props.browserLocation !== prevProps.browserLocation) {
      this.addBrowserLocation();
    }
    if (this.props.ipLocation && this.props.browserLocation && (this.props.ipLocation !== prevProps.ipLocation || this.props.browserLocation !== prevProps.browserLocation)) {
      this.addInfoMap();
    }
    if (this.props.full !== prevProps.full) {
      this.map.invalidateSize();
    }
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        id="map"
        className={classes.map}
      >
      </div>
    );
  }
}

// props defaults
Map.defaultProps = {
  ipLocation: false,
  browserLocation: false
};

// props validation
Map.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Map);
