// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';
import ReactJson from 'react-json-view';
import { saveAs } from 'file-saver';

// Components
import List from './List';
import ListTop from '../Global/ListTop';
import FormName from './FormName';
import FormCreateCollection from './FormCreateCollection';
import FormCreateIndex from './FormCreateIndex';
import SnackBar from '../Global/SnackBar';
import Dialog from '../Global/Dialog';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  title: {
    lineHeight: '56px',
    marginBottom: theme.spacing(2)
  },
  tabContainer: {
    paddingTop: theme.spacing(5)
  }
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'DB Utils';
    // Innit state
    this.state = {
      tabActive: 0,
      loading: true,
      collections: [],
      buildInfo: {},
      hostInfo: {},
      stats: {},
    };
    // Bind functions
    this.tabChange = this.tabChange.bind(this);
    this.getData = this.getData.bind(this);
    this.dropCollection = this.dropCollection.bind(this);
    this.renameCollection = this.renameCollection.bind(this);
    this.renameCollectionSend = this.renameCollectionSend.bind(this);
    this.createCollection = this.createCollection.bind(this);
    this.createCollectionSend = this.createCollectionSend.bind(this);
    this.download = this.download.bind(this);
    this.reIndex = this.reIndex.bind(this);
    this.createIndex = this.createIndex.bind(this);
    this.createIndexSend = this.createIndexSend.bind(this);
    this.dropIndex = this.dropIndex.bind(this);
    this.dropDatabase = this.dropDatabase.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  tabChange(e, num) {
    this.setState({ tabActive: num });
  }

  getData() {
    axios.all([
      axios.get('/api/db/collections', { cancelToken: this.cancelToken.token }),
      axios.get('/api/db/build_info', { cancelToken: this.cancelToken.token }),
      axios.get('/api/db/stats', { cancelToken: this.cancelToken.token })
      //axios.get('/api/db/host_info', { cancelToken: this.cancelToken.token }),
    ])
      .then(axios.spread((collections, build_info, stats)=> { //host_info
        this.setState({
          tabActive: 0,
          loading: false,
          collections: collections.data,
          buildInfo: build_info.data,
          stats: stats.data,
          //hostInfo: host_info.data,
        });
      }));
  }

  dropDatabase() {
    axios.post('/api/db/drop_database', null, { cancelToken: this.cancelToken.token })
      .then(res => {
        this.getData();
        this.snackbar.openSnackBar('success', 'Database dropped ok!');
      })
      .catch(err => {
        this.snackbar.openSnackBar('error', err.message);
      });
  }

  dropCollection(col) {
    if (col) {
      axios.post('/api/db/drop_collection', { name: col }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getData();
          this.snackbar.openSnackBar('success', 'Collection dropped ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  createCollectionSend(values) {
    const name = values.name || false;
    const file = values.file || false;
    this.dialog.closeDialog();
    if (name) {
      // config ajax
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: this.cancelToken.token,
        onUploadProgress: (e) => console.log(e.loaded, e.total)
      };
      // formdata
      const fd = new FormData();
      fd.append('name', name);
      fd.append('file', file);
      // ajax call
      axios.post('/api/db/create_collection', fd, config)
        .then(res => {
          this.getData();
          this.snackbar.openSnackBar('success', 'Collection created ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  createCollection() {
    const title = 'Create Collection';
    const content = <FormCreateCollection onSubmit={this.createCollectionSend} />;
    this.dialog.openDialog(title, content);
  }

  renameCollectionSend(col, values) {
    const from = col || false;
    const to = values.name || false;
    if (from && to) {
      axios.post('/api/db/rename_collection', { from, to }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getData();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Collection renamed ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  renameCollection(col) {
    if (col) {
      const title = 'Rename Collection';
      const content = <FormName onSubmit={this.renameCollectionSend.bind(this, col)} name={col} />;
      this.dialog.openDialog(title, content);
    }
  }

  download(col) {
    if (col) {
      axios.post('/api/db/download_collection', { col }, { responseType: 'arraybuffer', cancelToken: this.cancelToken.token })
        .then(res => {
          saveAs(new Blob([res.data]), `${col}__${new Date().getTime()}.zip`);
          this.snackbar.openSnackBar('success', 'Collection downloaded ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  createIndexSend(col, values) {
    const name = values.name || false;
    const index = values.index || false;
    const unique = values.unique || false;
    if (col && name && index) {
      axios.post('/api/db/create_index', { col, name, index, unique }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getData();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Index created ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  createIndex(col) {
    if (col) {
      const title = 'Create Index';
      const content = <FormCreateIndex onSubmit={this.createIndexSend.bind(this, col)} />;
      this.dialog.openDialog(title, content);
    }
  }

  dropIndexSend(col, values) {
    const name = values.name || false;
    if (col && name) {
      axios.post('/api/db/drop_index', { col, name }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getData();
          this.dialog.closeDialog();
          this.snackbar.openSnackBar('success', 'Collection renamed ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  dropIndex(col) {
    if (col) {
      const title = 'Drop Index';
      const content = <FormName onSubmit={this.dropIndexSend.bind(this, col)} />;
      this.dialog.openDialog(title, content);
    }
  }

  reIndex(col) {
    if (col) {
      axios.post('/api/db/reindex', { col }, { cancelToken: this.cancelToken.token })
        .then(res => {
          this.getData();
          this.snackbar.openSnackBar('success', 'Collection reindex ok!');
        })
        .catch(err => {
          this.snackbar.openSnackBar('error', err.message);
        });
    }
  }

  // lifecycle methods

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }


  // render
  render() {
    const { classes } = this.props;
    const { tabActive, loading, collections, hostInfo, buildInfo, stats } = this.state;

    const actionsTop = [
      {
        label: 'Create Collection',
        action: this.createCollection,
      },
      {
        label: 'Drop Database',
        action: this.dropDatabase,
      },
    ];

    // render
    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} fullscreen={false} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <ListTop
          title='DB Utils'
          actions={actionsTop}
        />
        <div>
          <Tabs value={tabActive} onChange={this.tabChange}>
            <Tab label={`Collections (${collections.length})`} />
            <Tab label="Build Info" />
            <Tab label="Resume" />
            {/*<Tab label="Host Info" />*/}
          </Tabs>
        </div>
        {tabActive === 0 &&
          <div className={classes.tabContainer}>
            {loading ? (
              <CircularProgress
                color="secondary"
                size={40}
              />
            ) : (
              <List
                collections={collections}
                dropCollection={this.dropCollection}
                renameCollection={this.renameCollection}
                download={this.download}
                reIndex={this.reIndex}
                dropIndex={this.dropIndex}
                createIndex={this.createIndex}
              />
            )}
          </div>
        }
        {tabActive === 1 &&
          <div className={classes.tabContainer}>
            <ReactJson src={buildInfo} enableClipboard={false} />
          </div>
        }
        {tabActive === 2 &&
          <div className={classes.tabContainer}>
            <ReactJson src={stats} enableClipboard={false} />
          </div>
        }
        {tabActive === 3 &&
          <div className={classes.tabContainer}>
            <ReactJson src={hostInfo} enableClipboard={false} />
          </div>
        }
      </Fragment>
    );
  }
}

export default withStyles(styles)(Index);
