//CommentBox.js
import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Strings';
    // Innit state
    this.state = {};
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    return (
      <Fragment>
        <Typography variant="h2" component="h2" gutterBottom>
          Strings
        </Typography>
      </Fragment>
    )
  }
}

export default Index;
