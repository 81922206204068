export default {
  'en': {
    buttons: {
      enter: 'Enter',
      wall: 'Wall'
    },
    placeholders: {
      username: 'User',
      password: 'Password',
      email: 'Email'
    },
    login: {
      facebook: 'Login with Facebook',
      twitter: 'Login with Twitter',
      text: 'Introdueix el teu nom d usari o correu electrònic i la teva contassenya per accedir a l aplicació.',
      link_recover: 'Si ets un usuari registrat i has oblidat la teva contrassenya segueix aquest enllaç.'
    },
    recover_password: {
      text: 'Introdueix el teu email i t\'enviarem una nova contrassenya.',
      link_login: 'Accedir al compte d\'usuari.',
      error_404: 'No existeix cap usuari amb aquest email.'
    },
    intro: {
      title: 'Intro'
    },
    admin: {
      title: 'Admin'
    }
  },
  'es': {
    buttons: {
      enter: 'Entrar',
      wall: 'Wall'
    },
    placeholders: {
      username: 'Usuario',
      password: 'Contraseña',
      email: 'Email'
    },
    login: {
      facebook: 'Entrar con Facebook',
      twitter: 'Entrar con Twitter',
      text: 'Introdueix el teu nom d usari o correu electrònic i la teva contassenya per accedir a l aplicació.',
      link_recover: 'Si ets un usuari registrat i has oblidat la teva contrassenya segueix aquest enllaç.'
    },
    recover_password: {
      text: 'Introdueix el teu email i t\'enviarem una nova contrassenya.',
      link_login: 'Accedir al compte d\'usuari.',
      error_404: 'No existeix cap usuari amb aquest email.'
    },
    intro: {
      title: 'Intro'
    },
    admin: {
      title: 'Admin'
    }
  },
  'ca': {
    buttons: {
      enter: 'Entrar',
      wall: 'Wall'
    },
    placeholders: {
      username: 'Usuari',
      password: 'Contrassenya',
      email: 'Email'
    },
    login: {
      facebook: 'Entrar amb Facebook',
      twitter: 'Entrar amb Twitter',
      text: 'Introdueix el teu nom d usari o correu electrònic i la teva contassenya per accedir a l aplicació.',
      link_recover: 'Ets un usuari registrat i has oblidat la teva contrassenya?'
    },
    recover_password: {
      text: 'Introdueix el teu email i t\'enviarem una nova contrassenya.',
      link_login: 'Accedir al compte d\'usuari.',
      error_404: 'No existeix cap usuari amb aquest email.'
    },
    intro: {
      title: 'Introducció'
    },
    admin: {
      title: 'Admin'
    }
  }
}
