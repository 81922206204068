// Dependencies
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import BoxTwitter from './BoxTwitter';
import BoxInstagram from './BoxInstagram';
import BoxFacebook from './BoxFacebook';
import BoxLinkedIn from './BoxLinkedIn';


const styles = theme => ({
  box: {
    position: 'relative',
    background: '#fff',
    minHeight: '100px',
    padding: '15px',
    borderRadius: '6px',
    border: '1px solid #f4f4f4'
  },
  link: {
    display: 'flex',
    position: 'relative',
    border: '2px solid #111',
    width: '100%',
    height: '100%',
    padding: '15px',
  },
});


class List extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
  }

  // custom functions

  // lifecycle methods

  componentDidMount() {
  }

  // render
  render() {
    const { classes, models } = this.props;

    // content
    let content = `No s'ha obtingut cap resultat...`;
    if (models && models.length > 0) {

      const items = _.map(models, (item, key) => {
        //
        let boxContent;
        const type = item.type;
        switch (type) {
          case 'twitter':
            boxContent = <BoxTwitter item={item}/>;
            break;
          case 'instagram':
            boxContent = <BoxInstagram item={item}/>;
            break;
          case 'facebook':
            boxContent = <BoxFacebook item={item}/>;
            break;
          case 'linkedin':
            boxContent = <BoxLinkedIn item={item}/>;
            break;
        }
        //
        return (
          <Grid item lg={6} md={6} sm={6} xs={12} key={`item_${key}`}>
            <div className={classes.box} data-aos="fade-up">
              {boxContent}
            </div>
          </Grid>
        );
      });
      content = <Grid container spacing={2}>{items}</Grid>;
    }

    // render
    return (
      <Fragment>
        {content}
      </Fragment>
    );
  }
}

// props validation
List.defaultProps = {
  models: false
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
