// Dependencies
import React, { Component, å } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import Equal from '@material-ui/icons/DragHandle';


const styles = theme => ({
  th: {
    cursor: 'pointer',
  },
  iconSort: {
    margin: '0 3px',
    fontSize: '10px',
    verticalAlign: 'middle'
  },
  icon: {
    marginLeft: '2px',
    verticalAlign: 'text-bottom'
  },
  green: {
    color: 'green',
    verticalAlign: 'bottom'
  },
  red: {
    color: 'red',
  }
});


class TableValues extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      sort_key: false,
      sort_dir: false,
    };
    // bind functions
    this.handleSort = this.handleSort.bind(this);
  }

  // custom functions

  handleSort(key) {
    const { sort_key } = this.state;
    if (key != sort_key) {
      this.setState({
        sort_key: key,
        sort_dir: 'asc'
      });
    } else {
      this.setState((prevState) => ({
        sort_dir: (prevState.sort_dir !='asc'? 'asc' : 'desc')
      }));
    }
  }

  // render
  render() {
    const { classes, data } = this.props;
    const { sort_key, sort_dir } = this.state;
    const arrowUp = <ArrowUp className={classes.icon}/>;
    const arrowDown = <ArrowDown className={classes.icon}/>;
    const equal = <Equal className={classes.icon}/>;
    // remove reference currency

    // order data
    let currencies = data;
    if (sort_key) {
      currencies = _.orderBy(currencies, [sort_key],[sort_dir]);
    }

    // sort icon
    const iconSort = (key) => {
      let icon = false;
      if (key === sort_key) {
        if (sort_dir === 'desc') {
          icon = <span className={classes.iconSort}>&#9660;</span>;
        } else {
          icon = <span className={classes.iconSort}>&#9650;</span>;
        }
      }
      return icon
    };

    // create table cells
    const values = _.map(currencies, (item, key) => {

      const day_class = ( Math.abs(item.day_ago) === 0 ? '' : ( item.day_ago<0 ? classes.red : classes.green ));
      const day_icon = ( Math.abs(item.day_ago) === 0 ? equal : ( item.day_ago<0 ? arrowDown : arrowUp ));
      const week_class = ( Math.abs(item.week_ago) === 0 ? '' : ( item.week_ago<0 ? classes.red : classes.green ));
      const week_icon = ( Math.abs(item.week_ago) === 0 ? equal : ( item.week_ago<0 ? arrowDown : arrowUp ));
      const month_class = ( Math.abs(item.month_ago) === 0 ? '' : ( item.month_ago<0 ? classes.red : classes.green ));
      const month_icon = ( Math.abs(item.month_ago) === 0 ? equal : ( item.month_ago<0 ? arrowDown : arrowUp ));

      return (
        <TableRow key={item.currency}>
          <TableCell>
            <Typography variant="h6" component="span">
              € / {item.currency}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="h6" component="span">
              {item.value.toFixed(4)}
            </Typography>
          </TableCell>
          <TableCell align="right" className={day_class}>
            <Typography variant="h6" component="span">
              {item.day_ago}%
            </Typography>
            {day_icon}
          </TableCell>
          <TableCell align="right" className={week_class}>
            <Typography variant="h6" component="span">
              {item.week_ago}%
            </Typography>
            {week_icon}
          </TableCell>
          <TableCell align="right" className={month_class}>
            <Typography variant="h6" component="span">
              {item.month_ago}%
            </Typography>
            {month_icon}
          </TableCell>
        </TableRow>
      );
    });

    // render
    return (
      <div className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell onClick={(e) => this.handleSort('currency')} className={classes.th}>
                Conversion
                {iconSort('currency')}
              </TableCell>
              <TableCell align="right" onClick={(e) => this.handleSort('value')} className={classes.th}>
                {iconSort('value')}
                Value
              </TableCell>
              <TableCell align="right" onClick={(e) => this.handleSort('day_ago')} className={classes.th}>
                {iconSort('day_ago')}
                Last Day
              </TableCell>
              <TableCell align="right" onClick={(e) => this.handleSort('week_ago')} className={classes.th}>
                {iconSort('week_ago')}
                Last Week
              </TableCell>
              <TableCell align="right" onClick={(e) => this.handleSort('month_ago')} className={classes.th}>
                {iconSort('month_ago')}
                Last Month
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values}
          </TableBody>
        </Table>
      </div>
    )
  }
}

// props validation
TableValues.defaultProps = {
  data: [],
};

TableValues.propTypes = {
  data: PropTypes.array.isRequired
};

export default withStyles(styles)(TableValues);
