// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import CreateFolderIcon from '@material-ui/icons/CreateNewFolder';
import UploadIcon from '@material-ui/icons/CloudUpload';


const Actions = (props) => {
  const { refresh, createFolder, uploadFiles } = props;

  // render
  return (
    <Fragment>
      <IconButton
        aria-label="Refresh"
        onClick={refresh}
      >
        <CachedIcon />
      </IconButton>
      <IconButton aria-label="Create Folder" onClick={createFolder}>
        <CreateFolderIcon />
      </IconButton>
      <IconButton aria-label="Upload File" onClick={uploadFiles}>
        <UploadIcon />
      </IconButton>
    </Fragment>
  )
}

Actions.propTypes = {
  refresh: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export default Actions;
