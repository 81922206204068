// Dependencies
import axios from 'axios';


export default {

  /**
   * Login
   */

  login: (username, password, cb) => {

    axios.post('/auth/login', { username: username, password: password })
      .then(res => {
        if (res.data.authenticated) {
          localStorage.token = res.data.token;
          if (cb) return cb(true);
        } else {
          if (cb) return cb(false, res.data);
        }
      })
      .catch(err => {
        return cb(false, {
          status: err.response.status,
          message: err.response.data.message
        });
      });
  },


  /**
   * Logout
   */

  logout: (cb) => {

    axios.get('/auth/logout')
      .then(res => {
        localStorage.removeItem('token')
        if (cb) return cb(res.data.result);
      })
      .catch(err => {
        if (cb) return cb(false);
        console.log(err);
      });
  },


  /**
   * Logged in
   */

  loggedIn: () => {

    if (localStorage.token) {
      return !!localStorage.token;
    }
  },


  /**
   * Get Token
   */

  getToken: () => {
    return localStorage.token;
  },


  /**
   * Logged in
   */

  ensureLogged: (cb) => {

    axios.get('/auth/is_logged')
      .then(res => {
        if (res.data.authenticated) {
          localStorage.token = res.data.token;
          if (cb) return cb(true);
        } else {
          if (cb) return cb(false);
        }
      })
      .catch(err => {
        return cb(false);
      });
  },


  /**
   * Recover Password
   */

  recoverPassword: (email, cb) => {

    axios.post('/auth/recover_password', { email: email })
      .then(res => {
        if (res.data.result) {
          if (cb) return cb(true);
        } else {
          if (cb) return cb(false, res.data);
        }
      })
      .catch(err => {
        return cb(false, {
          status: err.response.status,
          message: err.response.data.message
        });
      });
  }
};
