/* Dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { addLocaleData, IntlProvider } from 'react-intl';
//import { whyDidYouUpdate } from 'why-did-you-update';
import { flatten } from './lib/utils';

// Messages
import es from 'react-intl/locale-data/es';
import en from 'react-intl/locale-data/en';
import ca from 'react-intl/locale-data/ca';
import messages from './translations/messages';

// Routes
import Router from './components/Router';

// Store
import configureStore from './redux/store';

// Actions
import { getLoggedUser } from './redux/actions/auth';
import { loadProjects } from './redux/actions/projects';
import { loadWeather } from './redux/actions/weather';

// performance tools, only dev
if (process.env.NODE_ENV !== 'production') {
  //whyDidYouUpdate(React);
}

// Set up our store, can also pass in an initialState
const store = configureStore();
// Dispatch actions against store when app loads
store.dispatch(getLoggedUser());
store.dispatch(loadProjects());
store.dispatch(loadWeather());

// Locale data
addLocaleData([ ...en, ...es, ...ca ]);
// Set locale
const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en-EN';
const locale = language.toLowerCase().split(/[_-]+/)[0]; // Split region code, ca-ES -> ca

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={flatten(messages[locale])}>
      <Router />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
