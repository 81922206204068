// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RotateIcon from '@material-ui/icons/RotateRight';
import ScaleXIcon from '@material-ui/icons/SwapHoriz';
import ScaleYIcon from '@material-ui/icons/SwapVert';
import ResetIcon from '@material-ui/icons/Refresh';
import CropIcon from '@material-ui/icons/CropOriginal';
import PlayIcon from '@material-ui/icons/Crop';
import PauseIcon from '@material-ui/icons/NotInterested';
import PhotoIcon from '@material-ui/icons/Photo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: '10px',
    minWidth: '32px',
    '& + button': {
      marginLeft: '10px',
    }
  },
  label: {
    marginLeft: '10px',
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});


class CropperButtons extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      viewDialog: false
    };
    // refs
    this.inputFile = React.createRef();
    // bind functions
    this.handleChangeImage = this.handleChangeImage.bind(this);
  }

  // custom functions

  handleChangeImage(e) {
    const { changeImage } = this.props;
    const url = URL.createObjectURL(e.target.files[0]); // creem url temporal enllaçada amb el doc, e.target === this.inputFile
    changeImage(url);
  }

  // dialog file

  openDialogFile(e) {
    e.preventDefault();
    this.setState({
      openDialog: true,
      viewDialog: true
    });
  }

  closeDialogFile() {
    this.setState({ openDialog: false });
  }

  confirmDialogFile() {
    this.setState({ openDialog: false }, () => {
      this.inputFile.click();
    });
  }

  // lifecycle methods

  // render
  render() {
    const { classes, active, loading, rotate, scaleX, scaleY, reset, crop, toggleActive } = this.props;
    const { openDialog, viewDialog } = this.state;
    const is_active = (active && !loading)?true:false;

    return (
      <div className={classes.buttons}>
        <div className={classes.cropActions}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            aria-label="rotate"
            className={classes.button}
            onClick={() => rotate()}
            disabled={is_active?false:true}
          >
            <RotateIcon className={classes.iconSmall} />
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            aria-label="scaleX"
            className={classes.button}
            onClick={() => scaleX()}
            disabled={is_active?false:true}
          >
            <ScaleXIcon className={classes.iconSmall} />
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            aria-label="scaleY"
            className={classes.button}
            onClick={() => scaleY()}
            disabled={is_active?false:true}
          >
            <ScaleYIcon className={classes.iconSmall} />
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            aria-label="reset"
            className={classes.button}
            onClick={() => reset()}
            disabled={is_active?false:true}
          >
            <ResetIcon className={classes.iconSmall} />
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            aria-label="crop"
            className={classes.button}
            onClick={() => crop()}
            disabled={is_active?false:true}
          >
            <CropIcon className={classes.iconSmall} />
          </Button>
        </div>
        <div className={classes.cropConfig}>
          <Button
            variant="contained"
            size="small"
            color={active?"secondary":"primary"}
            aria-label="clear"
            className={classes.button}
            onClick={() => toggleActive()}
          >
            {active?<PauseIcon className={classes.iconSmall} />:<PlayIcon className={classes.iconSmall} />}
          </Button>

          <input
            ref={el => this.inputFile = el}
            accept="image/*"
            className={classes.input}
            id="btn-file"
            type="file"
            onChange={this.handleChangeImage}
          />
          <label htmlFor="btn-file" className={classes.label}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              aria-label="change"
              component="span"
              className={classes.button}
              disabled={Boolean(loading)}
              onClick={(e) => { if (!viewDialog) this.openDialogFile(e)}}
            >
              <PhotoIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
              Change Image
            </Button>
          </label>
          <Dialog
            open={openDialog}
            onClose={(e) => this.closeDialogFile(e)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Let Google help apps determine location. This means sending anonymous location data to
                Google, even when no apps are running.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.closeDialogFile(e)} color="primary">
                Disagree
              </Button>
              <Button onClick={(e) => this.confirmDialogFile(e)} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

// props defaults
CropperButtons.defaultProps = {
  active: true,
  loading: false
};

// props validation
CropperButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleActive: PropTypes.func.isRequired,
  changeImage: PropTypes.func.isRequired,
  rotate: PropTypes.func.isRequired,
  scaleX: PropTypes.func.isRequired,
  scaleY: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  crop: PropTypes.func.isRequired,
};

export default withStyles(styles)(CropperButtons);
