// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
});


const roles = [ 'user', 'editor', 'admin', 'super' ];

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit, model } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            userName: _.get(model, 'userName', ''),
            email: _.get(model, 'email', ''),
            password: '',
            role: _.get(model, 'role', ''),
            twitter: { username: _.get(model, 'twitter.username', true)},
            facebook: { username: _.get(model, 'facebook.username', true)},
            active: _.get(model, 'active', true),
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            // Remove password if empty
            if (!payload.password) delete payload.password;

            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="userName"
                    label="Name"
                    placeholder="Name"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.nameInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.emailInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="input-role">Role</InputLabel>
                    <Select
                      inputProps={{
                        name: 'role',
                        id: 'input-role',
                      }}
                      value={values.role}
                      onChange={handleChange}
                      fullWidth
                    >
                      {roles.map((role) =>
                        <MenuItem value={role} key={role}>{role}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    label="Password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.passwordInput = el}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="twitter.username"
                    label="Twitter Username"
                    placeholder="Twitter Username"
                    value={values.twitter.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.twUsernameInput = el}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="facebook.username"
                    label="Facebook Username"
                    placeholder="Facebook Username"
                    value={values.facebook.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.fbUsernameInput = el}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        value="active"
                        className={classes.checkbox}
                        disableRipple={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.active}
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!dirty}
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}

export default withStyles(styles)(FormComponent);
