// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.tooltip = React.createRef();
    this.tip = React.createRef();
    this.x = React.createRef();
    this.g = React.createRef();
    this.xAxis = React.createRef();
    this.xAxisCall = React.createRef();
  }

  // custom functions

  updateGraph() {
    const { width, height, margin, data  } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // transitions
    //const t = d3.transition().duration(750);

    // if valid data
    if (!_.isEmpty(data)) {
      // Update our scales
      const x = this.x.domain([0, d3.max(data, function(d) { return parseInt(d.properties.price); }) + 5]);
      const tip = this.tip;
      //
      const simulation = d3.forceSimulation(data)
        .force('x', d3.forceX((d) => { return x(parseInt(d.properties.price)); }).strength(3))
        .force('y', d3.forceY(height_data/2))
        .force('collide', d3.forceCollide(4))
        .stop();

      for (var i = 0; i < 120; ++i) simulation.tick();

      // Update our axis
      this.xAxis.call(this.xAxisCall);

      // Update data
      const cells = this.g.selectAll('.cell')
        .data(d3.voronoi()
          .extent([[0, 0], [width_data, height_data]])
          .x(function(d) { return d.x; })
          .y(function(d) { return d.y; })
          .polygons(data)
        );
      // EXIT old elements not present in new data
      cells.exit().remove();
      // ENTER new elements present in new data
      const enter_cells = cells.enter().append('g');
      enter_cells.append('circle')
        .attr('r', 3)
        .attr('cx', function(d) { return d?d.data.x:0; })
        .attr('cy', function(d) { return d?d.data.y:0; });
      // mouse events
      enter_cells
        .on('mouseover', () => {
          tip.transition()
            .duration(200)
            .style('opacity', 1);
        })
        .on('mouseout', () => {
          tip.transition()
            .duration(500)
            .style('opacity', 0);
        })
        .on('mousemove', (d) => {
          console.log(d);
          tip.html(`${d.data.properties.name}<br/>${d.data.properties.host}<br/>${d.data.properties.price}`)
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        });

      // update data line!

      // translate lines, cals position
      const min_val = 12;
      this.line_min
        .style('opacity', 1)
        .attr('transform', `translate(${x(min_val)}, 0)`)
        .select('text').text(`${min_val}€`);

      // translate lines, cals position
      const max_val = 290;
      this.line_max
        .style('opacity', 1)
        .attr('transform', `translate(${x(max_val)}, 0)`)
        .select('text').text(`${max_val}€`);

      // translate lines, cals position
      const normal_val = 73;
      this.line_normal
        .style('opacity', 1)
        .attr('transform', `translate(${x(normal_val)},0)`)
        .select('text').text(`${normal_val}€`);
    }
  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // init main group
    this.g = this.canvas.append('g')
      .attr('transform', `translate(${margin[0]}, ${margin[2]})`);

    // init scales
    this.x = d3.scaleLinear().range([0, width_data]);

    // init axes and labels
    this.xAxisCall = d3.axisBottom(this.x).ticks(5);
    this.xAxis = this.g.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0, ${height_data})`);

    // Center line Y
    this.g.append('line')
      .attr('class', 'axis')
      .attr('x1', '0').attr('y1', height_data/2)
      .attr('x2', width_data).attr('y2', height_data/2)
      .attr('stroke-dasharray', '2,2');

    // Min X
    this.line_min = this.g.append('g')
      .attr('transform', `translate(0,0)`)
      .style('opacity', 0);
    this.line_min.append('line')
      .attr('class', 'axis')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', height_data)
      .attr('stroke-dasharray', '2,2');
    this.line_min.append('text')
      .attr('class', 'axis-title')
      .attr('y',16)
      .attr('x',6);

    // Max X
    this.line_max = this.g.append('g')
      .attr('transform', `translate(${width_data},0)`)
      .style('opacity', 0);
    this.line_max.append('line')
      .attr('class', 'axis')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', height_data)
      .attr('stroke-dasharray', '2,2');
    this.line_max.append('text')
      .attr('class', 'axis-title')
      .attr('y',16)
      .attr('x',6);

    // Max X
    this.line_normal = this.g.append("g")
      .attr('transform', `translate(${width_data/2},0)`)
      .style('opacity', 0);
    this.line_normal.append('line')
      .attr('class', 'axis')
      .attr('x1', 0).attr('y1', 0)
      .attr('x2', 0).attr('y2', height_data)
      .attr('stroke-dasharray', '2,2');
    this.line_normal.append('text')
      .attr('class', 'axis-title')
      .attr('y',16)
      .attr('x',6);

    // tooltip
    this.tip = d3.select(this.tooltip);
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height } = this.props;

    return (
      <Fragment>
        <div
          ref={el => this.tooltip = el}
          className="tooltip"
        />
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="none"
          />
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:900,
  height:220,
  margin:[10,10,10,40],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
