/*
Real time
https://bl.ocks.org/boeric/3b57a788a4b96e1af211

Responsive
https://www.youtube.com/watch?v=jF1XPXqom_c
https://bl.ocks.org/mtranggit/7689a322bbc9261f22c3317291ca506f
https://bl.ocks.org/mtranggit/7689a322bbc9261f22c3317291ca506f
http://bl.ocks.org/enactdev/a647e60b209e67602304
*/

// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

// Components
import Container from '../../Global/Container';
import Graph from './Graph';
import DataSet from '../DataSet';

const styles = theme => ({
  graph: {
  },
  wrapper: {
    maxWidth: '900px',
    margin: '0 auto'
  },
  buttons: {
    textAlign: 'center'
  },
  button: {
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      data: []
    };
    // Bind functions
    this.initData = this.initData.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  // custom functions

  initData() {


    console.log('data');
  }

  updateData() {
    /*
    // update state values
    const randomData = [];
    const length = Math.random()*10 + 40;

    for (let i = 0; i < length; i++) {
      randomData.push({
        gpa: Math.random()*80 + 120,
        height: Math.random()*3 + 2,
        intensity: Math.random()*30 + 5,
      });
    }

    this.setState({
      data: _.orderBy(randomData, ['intensity'],['desc']) // primer els més grossos, així evitem superposicions
    });
    */
  }


  // lifecycle methods

  componentDidMount() {
    /*
    this.timer = setInterval(
      () => this.updateData(),
      1000
    );
    */
    this.initData();
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              D3 Real Time
            </Typography>
            <Typography variant="h3" component="h3">
              Actualització de les dades en temps real
            </Typography>
            <div className={classes.graph}>
              <div className={classes.wrapper}>
                <Graph
                  data={data}
                  initData={data}
                  updateData={data}
                />
              </div>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography container="div">
              The chart's time domain is moving with the passage of time, which means that any data placed in the chart eventually will age out and leave the chart.
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={data}/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
