/*
Real time
https://bl.ocks.org/boeric/3b57a788a4b96e1af211

Responsive
https://github.com/Is2PidGuy/responsive/blob/master/app/chart.js
https://bl.ocks.org/mtranggit/7689a322bbc9261f22c3317291ca506f
https://bl.ocks.org/mtranggit/7689a322bbc9261f22c3317291ca506f
http://bl.ocks.org/enactdev/a647e60b209e67602304
*/

// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';

// Components
import Container from '../../Global/Container';
import Graph from './Graph';
import DataSet from '../DataSet';

const styles = theme => ({
  graph: {
  },
  wrapper: {
    maxWidth: '900px',
    height: '450px',
    margin: '0 auto'
  },
  buttons: {
    textAlign: 'center'
  },
  button: {
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      data: [],
      width: 900,
      height: 450
    };
    // Bind functions
    this.resize = this.resize.bind(this);
  }

  // custom functions

  getData() {
    const data = [
      { year: 2012, percent: 50 },
      { year: 2013, percent: 30 },
      { year: 2014, percent: 80 },
      { year: 2015, percent: 20 },
      { year: 2016, percent: 55 },
      { year: 2017, percent: 83 },
    ];
    this.setState({ data });
  }

  resize() {
    const node = this.node;
    const bounds = node.getBoundingClientRect();
    const width = bounds.width;
    const height = bounds.height;
    this.setState({ width, height });
    console.log(this.state);
  }


  // lifecycle methods

  componentDidMount() {
    // add listener
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  // render
  render() {
    const { classes } = this.props;
    const { data, width, height } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              D3 Responsive
            </Typography>
            <Typography variant="h3" component="h3">
              Create responsive bar chart using d3
            </Typography>
            <div className={classes.graph}>
              <div
                ref={node => this.node = node}
                className={classes.wrapper}
              >
                <Graph
                  data={data}
                  width={width}
                  height={height}
                />
              </div>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography container="div">
              To make an accurate responsive HOC, you need to detect the dimension changes of the parent node of the chart. You can check out react dimensions for that which basically can detect change in dimensions of any react element not just the browser window.
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={data}/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
