// Dependencies
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Auth
import auth from '../../../lib/auth';


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Logout';

    // redirect to home
    const { history } = props;

    auth.logout(function(result) {
      if (result) {
        return history.replace({ pathname: '/' }, {loggedIn: false});
      }
    });
  }

  render() {
    return null;
  }
}

export default withRouter(Index);
