// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});


class ListItemActions extends Component {
  // construct
  constructor(props) {
    super(props);
    // Init state
    this.state = {
      anchorEl: null
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
  }

  // custom functions

  handleClick(e) {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget
    });
  }

  handleClose(e) {
    e.stopPropagation();
    this.setState({
      anchorEl: null
    });
  }

  handleClickMenu(action, e) {
    // close menu
    this.handleClose(e)
    // call action
    if (typeof action === 'function') {
      action();
    }
  }

  // lifecycle methods

  render() {
    const { anchorEl } = this.state;
    const { className, actions } = this.props;

    // render
    return (
      <div className={className}>
        <IconButton
          aria-label="Item Actions"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          onChange={this.handleClose}
          TransitionComponent={Fade}
          PaperProps={{
            style: {
              maxHeight: 250,
              width: 150,
            },
          }}
        >
          {actions.map((item, key) => (
            <MenuItem key={`action_${key}`} onClick={(e) => this.handleClickMenu(item.action, e)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

ListItemActions.propTypes = {
  actions: PropTypes.array.isRequired
};

export default withStyles(styles)(ListItemActions);
