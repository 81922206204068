// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';
import _ from 'lodash';

// Components
import List from './List';
import ListTop from '../../Global/ListTop';
import DataSet from '../../Global/DataSet';
import SnackBar from '../../Global/SnackBar';
import Dialog from '../../Global/Dialog';

import CircularProgress from '@material-ui/core/CircularProgress';


export default class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Apartments';
    // State
    this.state = {
      loading: true,
      models: []
    };
    // create token for cancel axios request
    this.cancelToken = CancelToken.source();
    // Bind functions
    this.getModels = this.getModels.bind(this);
    this.removeModel = this.removeModel.bind(this);
    this.clearModels = this.clearModels.bind(this);

    this.viewData = this.viewData.bind(this);
  }

  getModels() {
    axios.get('/api/apartments', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ models: res.data.models, loading: false });
      })
      .catch(err => {
        const error = err.response.data.message || err.message;
        this.snackbar.openSnackBar('error', error);
      });
  }

  removeModel(model) {
    if (model) {
      const id = model._id.toString();

      axios.delete('/api/apartments/'+id, { cancelToken: this.cancelToken.token })
        .then(res => {
          //this.getModels();
          this.setState({ models: _.filter(this.state.models, (o,i) => o._id.toString() !== id ) });
          this.snackbar.openSnackBar('success', 'Apartment deleted ok!');
        })
        .catch(err => {
          const error = err.response.data.message || err.message;
          this.snackbar.openSnackBar('error', error);
        });
    }
  }

  clearModels() {
    axios.delete('/api/apartments/clear', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ models: [] });
        this.snackbar.openSnackBar('success', 'Apartments empty!');
      })
      .catch(err => {
        const error = err.response.data.message || err.message;
        this.snackbar.openSnackBar('error', error);
      });
  }

  /* Modals */

  viewData(model) {
    const title = 'JSON';
    const content = <DataSet data={model} />;
    this.dialog.openDialog(title, content);
  }

  // lifecycle methods

  componentDidMount() {
    // First call
    this.getModels();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  render() {
    const { models, loading } = this.state;

    const actionsTop = [
      {
        label: 'Clear Apartments',
        action: this.clearModels,
      }
    ];

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <ListTop
          title='Apartments'
          actions={actionsTop}
        />
        {loading ? (
          <CircularProgress
            color="secondary"
            size={40}
          />
        ) : (
          <List
            models={models}
            viewData={this.viewData}
            removeModel={this.removeModel}
          />
        )}
      </Fragment>
    );
  }
}
