// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import * as d3 from 'd3';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconZoomIn from '@material-ui/icons/Add';
import IconZoomOut from '@material-ui/icons/Remove';
import IconReset from '@material-ui/icons/ChangeHistory';


const styles = {
  browser: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  buttons: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '6px',
    background: '#fff',
    border: '2px solid rgba(0,0,0,0.2)',
    zIndex: 1000,
  },
  button: {
    position: 'relative',
    margin: 0,
    borderRadius: 0,
    color: '#111',
    padding: '6px',
    borderTop: '1px solid rgba(0,0,0,0.2)',
    '&:first-child': {
      borderTop: 0
    }
  }
};

// axios cancel
let cancel;


class Vis extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.wrap = React.createRef();
    this.canvas = React.createRef();
    this.vis = React.createRef();
    this.zoom = React.createRef();
    this.zoomContainer = React.createRef();
    this.zoomLayer = React.createRef();
    // bind functions
    this.zoomImage = this.zoomImage.bind(this);
    this.reset = this.reset.bind(this);
    this.redraw = this.redraw.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  redraw() {
    //
    if (!this.wrap) return;

    // container div size
    const width = this.wrap.offsetWidth;
    const height = this.wrap.offsetHeight;
    const prevViewBox = this.canvas.getAttribute("viewBox").split(" ");

    // change viewbox
    d3.select(this.canvas)
      .attr("viewBox", `0 0 ${width} ${height}`);
    // change center
    d3.select(this.center)
      .attr("transform", `translate(${width/2},${height/2})`);

    // change zoom
    this.zoom.extent([[0, 0], [width, height]]);

    // calc inc
    const inc_x = width/parseFloat(prevViewBox[2]);
    const inc_y = height/parseFloat(prevViewBox[3]);
    const prevRatio = prevViewBox[2]/prevViewBox[3];
    const newRatio = width/height;
    const inc = (newRatio>1 || (prevRatio>1 && newRatio<1) || (prevRatio<1 && newRatio>1))?inc_y:inc_x;

    //
    if (inc) {
      const transform = d3.zoomTransform(this.canvas);
      //const zoom_level = Math.log2(transform.k);
      // projection
      const bx = (transform.x - parseFloat(prevViewBox[2])/2*(1-transform.k))/transform.k;
      const by = (transform.y - parseFloat(prevViewBox[3])/2*(1-transform.k))/transform.k;
      const ex = transform.k*bx*inc + width/2*(1 - transform.k);
      const ey = transform.k*by*inc + height/2*(1 - transform.k);
      // transform zoom
      const newTransform = d3.zoomIdentity.translate(ex, ey).scale(transform.k);
      this.zoom.transform(d3.select(this.canvas), newTransform);
    }
  }

  zoomImage(f) {
    const factor = (f === 'in') ? 2 : 0.5; // potències de 2
    // scale
    this.zoom.scaleBy(d3.select(this.canvas).transition().duration(250), factor);
  }

  reset() {
    d3.select(this.canvas)
      .transition()
      .duration(250)
      .call(this.zoom.transform, d3.zoomIdentity);
  }

  handleAction(action, values) {
    const { triggerAction } = this.props;
    triggerAction(action,values);
  }

  initVis() {
    const { svgUrl } = this.props;

    // canvas
    const canvas = d3.select(this.canvas);

    // zoom
    const zoomLayer  = d3.select(this.zoomLayer);//.style("transform-origin", "0 0 0");

    this.zoom = d3.zoom();
    this.zoom.scaleExtent([0.125, 32]);
    this.zoom.on("zoom", () => {
      zoomLayer.attr("transform", d3.event.transform);
      //console.log(d3.event.transform);
    });

    // call zoom on canvas
    canvas.call(this.zoom)
      .on("wheel.zoom", null);

    // Handle click svg actions
    const handleClick = (action,values) => {
      this.handleAction(action,values);
    };

    // load svg and append
    axios.get(
      svgUrl,
      {
        responseType: 'document',
        cancelToken: new CancelToken(function executor(c) { cancel = c; })
      }
    ).then((res) => {
      const svg = res.data;
      const importedNode = document.importNode(svg.documentElement, true);
      zoomLayer.node().append(importedNode);
      //
      zoomLayer.selectAll('[data-action]')
        .style('cursor','pointer')
        .on('click', function() {
          const action = d3.select(this).attr("data-action");
          const value = d3.select(this).attr("data-value");
          handleClick(action,value);
        });
    });

    this.redraw();

    // Redraw based on the new size whenever the browser window is resized.
    window.addEventListener("resize", this.redraw);
  }

  // lifecycle methods

  componentDidMount() {
    this.initVis();
  }

  componentDidUpdate(prevProps) {
    if (this.props.file !== prevProps.file) {
      this.updateVis();
    }
    if (this.props.full !== prevProps.full) {
      this.redraw();
    }
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on unmount.');
  }

  // render
  render() {
    const { classes } = this.props;

    return (
      <div
        className={classes.browser}
        ref={el => this.wrap = el}
      >
        <div className={classes.buttons}>
          <IconButton
            onClick={() => this.zoomImage('in')}
            className={classes.button}
            aria-label="Zoom +"
          >
            <IconZoomIn fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => this.zoomImage('out')}
            className={classes.button}
            aria-label="Zoom -"
          >
            <IconZoomOut fontSize="small"/>
          </IconButton>
          <IconButton
            onClick={this.reset}
            className={classes.button}
            aria-label="Reset"
          >
            <IconReset fontSize="small"/>
          </IconButton>
        </div>
        <svg
          ref={el => this.canvas = el}
          width="100%"
          viewBox={'0 0 100 100'}
          preserveAspectRatio="xMidYMid meet"
        >
          <rect
            width="100%"
            height="100%"
            fill="#f4f4f4"
          />
          <g
            ref={el => this.zoomLayer = el}
          />
          <g
            ref={el => this.center = el}
          >
            <line x1="0" y1="-5" x2="0" y2="5" stroke="#3498db" />
            <line x1="-5" y1="0" x2="5" y2="0" stroke="#3498db" />
          </g>
        </svg>
      </div>
    );
  }
}

// props defaults
Vis.defaultProps = {
  svgUrl: '/img/star.svg',
};

// props validation
Vis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vis);
