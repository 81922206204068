// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')] : {
      display: 'flex'
    }
  },
  flex: {
    [theme.breakpoints.up('xs')] : {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('sm')] : {
      flex: 1,
      paddingRight: theme.spacing(2)
    }
  },
  button: {
    [theme.breakpoints.up('xs')] : {
      width: '100%',
    },
    [theme.breakpoints.up('sm')] : {
      height: '48px',
      width: '120px',
    }
  }
});


class FormComponent extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Refs
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
  }

  // custom methods

  // lifecycle methods

  componentDidMount() {
  }

  // render
  render() {
    const { classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            name: '',
            email: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form className={classes.root}>
              <div className={classes.flex}>
                <TextField
                  id="name"
                  label="Name"
                  placeholder="Your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={el => this.nameInput = el}
                  fullWidth
                  required
                />
              </div>
              <div className={classes.flex}>
                <TextField
                  id="email"
                  label="Email"
                  placeholder="your@email.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={el => this.emailInput = el}
                  fullWidth
                  required
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                  className={classes.button}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}

export default withStyles(styles)(FormComponent);
