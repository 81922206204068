// Dependencies
import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

// Components
import NewsletterForm from './NewsletterForm';


class Newsletter extends Component {
  // construct
  constructor(props, context) {
    super(props, context);
    // Innit state
    this.state = {
      process: false,
      error: false,
      success: false
    };
    // Bind functions
    this.sendForm = this.sendForm.bind(this);
  }

  // custom functions
  sendForm(values) {
    console.log(values);
    this.setState({
      error: false
    });
  }

  // lifecycle methods

  // render
  render() {
    const { send } = this.state;

    return (
      <Fragment>
        <Typography variant="h6" color="inherit">
          Newsletter
        </Typography>
        {!send ? (
          <NewsletterForm
            onSubmit={this.sendForm}
          />
        ):(
          <span>SEND!</span>
        )}
      </Fragment>
    );
  }
}

export default Newsletter;
