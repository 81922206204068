// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FileIcon from '@material-ui/icons/InsertPhoto';

// Components
import ListItemActions from './ListItemActions';


const styles = theme => ({
  item: {
    width: '150px',
    display: 'inline',
    padding: '0',
    margin: '0 20px 20px 0',
    position: 'relative',
  },
  iconWrap: {
    height: '150px',
    borderRadius: '10px',
    background: '#f4f4f4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    margin: '0 0 10px 0',
  },
  icon: {
    fontSize: '48px'
  },
  actions: {
    position: 'absolute',
  },
  selected: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: '#00e676',
    border: '2px solid #fff'
  }
});


class ListItemFile extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      selected: false
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
    this.handlePreviewFile = this.handlePreviewFile.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
  }

  // custom functions

  handleClick(e) {
    const { item, select } = this.props;
    e.stopPropagation();
    select(item.route);
  }

  handleRename(e) {
    const { item, rename } = this.props;
    rename(item.name);
  }

  handleDeleteFile(e) {
    const { item, deleteFile } = this.props;
    deleteFile(item.route);
  }

  handlePreviewFile(e) {
    const { item, previewFile } = this.props;
    previewFile(item.route);
  }

  handleDownloadFile(e) {
    const { item, downloadFile } = this.props;
    downloadFile(item);
  }

  // drag & drop

  onDragStart(e) {
    const { item } = this.props;
    e.dataTransfer.effectAllowed = 'move';  //
    e.dataTransfer.setData('text/plain', item.name); // dragged item
    e.dataTransfer.setDragImage(e.target, 20, 20); // drag effect
  }

  onDragOver(e) {
    e.preventDefault();
  }

  // lifecycle methods


  // render
  render() {
    const { classes, item } = this.props;

    const actions = [{
      label: 'Rename',
      action: this.handleRename,
      icon: 'edit'
    },{
      label: 'Delete',
      action: this.handleDeleteFile,
      icon: 'delete'
    },{
      label: 'Preview',
      action: this.handlePreviewFile,
      icon: 'folder'
    },{
      label: 'Download',
      action: this.handleDownloadFile,
      icon: 'folder'
    }];

    // render
    return (
      <div
        className={classes.item}
        draggable
        onClick={(e) => this.handleClick(e)}
        onDragStart={(e) => this.onDragStart(e)}
        onDragOver={(e) => this.onDragOver(e)}
      >
        <ListItemActions
          className={classes.actions}
          actions={actions}
        />
        {item.selected &&
          <div className={classes.selected} />
        }
        <div className={classes.iconWrap}>
          <FileIcon className={classes.icon} />
        </div>
        <Typography component="p" variant="body2">
          {item.name}
        </Typography>
        <Typography component="p" variant="caption">
          {item.dateText}<br/>{item.size}
        </Typography>
      </div>
    )
  }
}

// props validation
ListItemFile.defaultProps = {
  actions: [],
};

ListItemFile.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  previewFile: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListItemFile);
