// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';
import Map from './Map';
import Hosts from './Hosts';
import Price from './Price';
import History from './History';

const styles = theme => ({
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      totals: {},
      hosts: [],
      rooms: []
    };
    // Bind functions
    this.loadInfo = this.loadInfo.bind(this);
  }

  // custom functions

  loadInfo() {
    axios.get(
      '/api/apartments/lab_data',
      {
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        const data = res.data.result;
        this.setState({
          hosts: data.hosts,
          rooms: data.rooms
        });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadInfo();
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    //const { classes } = this.props;
    const { hosts, rooms, totals } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Airbnb Girona
            </Typography>
            <Typography variant="h3" component="h3">
              Collaborative Economy Is Changing Everything?
            </Typography>
            <div className="map">
              <FullScreen>
                <Map
                  points={rooms}
                />
              </FullScreen>
            </div>
            <Hosts
              total_hosts={totals.hosts}
              hosts={hosts}
            />
            <Price
              total_price={totals.price}
              data={rooms}
            />
            <History
              total_price={totals.price}
              result={hosts}
            />
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
