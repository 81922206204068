// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import axios, { CancelToken } from 'axios';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import CropperButtons from './CropperButtons';

import 'cropperjs/dist/cropper.css';


const styles = theme => ({
  layer: {
    height: '100%',
    width: '100%',
    background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC) left top repeat',
    '& svg': {
      position: 'relative'
    }
  },
  flex: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  full: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  opacity: {
    background: '#111',
    opacity: '0.6'
  },
  hidden: {
    display: 'none',
  },
  image: {
    visibility: 'hidden'
  },
  wrap: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative'
  }
});

// axios cancel
let cancel;


class CropperComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      img: null,
      loading: false,
      active: true,
      openDialog: false,
      viewDialog: false
    };
    // refs
    this.cropper = React.createRef();
    this.container = React.createRef();
    this.wrap = React.createRef();
    this.image = React.createRef();
    // bind functions
    this.rotate = this.rotate.bind(this);
    this.scaleX = this.scaleX.bind(this);
    this.scaleY = this.scaleY.bind(this);
    this.reset = this.reset.bind(this);
    this.crop = this.crop.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  // custom functions
  putImage(url, cb) {
    // init
    this.image.setAttribute('src', '');
    // loading
    this.setState({ loading: true });
    // load
    axios.get(
      url,
      {
        responseType: 'arraybuffer',
        cancelToken: new CancelToken(function executor(c) { cancel = c; })
      }
    )
      .then(res => {
        // parts base64
        const prefix = "data:" + res.headers["content-type"] + ";base64,";
        const base64 = Buffer.from(res.data, 'binary').toString('base64');
        // construim imatge
        let img = new Image();
        img.src = prefix + base64;
        img.onload = () => {
          // imatge
          this.image.setAttribute('width', img.width);
          this.image.setAttribute('height', img.height);
          this.image.setAttribute('src', img.src);
          this.setState({ loading: false, img: img });
        };

        // modifiquem mides, busquem ratio
        const ratio_x = 1/(img.width/this.container.offsetWidth);
        const ratio_y = 1/(img.height/this.container.offsetHeight);
        const ratio = (ratio_x<1 || ratio_y<1)?Math.min(ratio_x,ratio_y):1;
        console.log(ratio);
        //this.cropper.setCanvasData({ left: 20, top:20, width:20, height:20});
        //this.wrap.setAttribute('width', im.width*ratio);
        //this.wrap.setAttribute('height', im.height*ratio);
        //this.container;
        //this.cropper.setCanvasData({left: 0, top: 0, width: 20});
        //console.log(this.cropper,this.container.offsetWidth);
        this.cropper.replace(img.src);
        // callback/return
        if (typeof(cb) === "function") return cb();
        return true;
      });
  }

  initCropper() {
    const { image } = this.props;

    this.cropper = new Cropper(this.image, {
      viewMode: 2,
      dragMode: 'none',
      responsive: true,
      checkOrientation: true,
      modal: true,
      background: false,
      autoCropArea: 0.8,
      movable: false,
      rotatable: true,
      scalable: true,
      resizable: true,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      minContainerWidth: 20,
      minContainerHeight: 20,
      minCanvasWidth: 20,
      minCanvasHeight: 20,
      minCropBoxWidth: 20,
      minCropBoxHeight: 20,
      crop(event) {
      },
    });

    this.putImage(image, () => {
    });
  }

  redraw() {
    console.log('calcular noves posicions pel contenidor...');
  }

  rotate() {
    this.cropper.rotate(90);
    // size cont
    // size canvas
    this.cropper.setCanvasData({left: 0, top: 0, height: 450});
  }

  scaleX() {
    const imageData = this.cropper.getImageData();
    const scaleX = imageData.scaleX;
    const scaleY = imageData.scaleY;
    this.cropper.scale(-scaleX, scaleY);
    this.cropper.setAspectRatio(0.2);
  }

  scaleY() {
    const imageData = this.cropper.getImageData();
    const scaleX = imageData.scaleX;
    const scaleY = imageData.scaleY;
    this.cropper.scale(scaleX, -scaleY);
    //this.cropper.setAspectRatio(1.5);
  }

  reset() {
    this.cropper.reset();
  }

  crop() {
    const { updateCropper } = this.props;
    const imageData = this.cropper.getImageData();
    console.log('imageData',imageData);
    updateCropper(imageData);
  }

  changeImage(url) {
    const { updateImage } = this.props;
    if (url) {
      // cancel ajax
      if (typeof cancel === 'function') cancel('Operation canceled by the user on change image.');
      // update image
      updateImage(url);
    }
  }

  toggleActive() {
    if (this.cropper.cropped) {
      this.cropper.clear();
      this.setState({ active: false });
    } else {
      this.cropper.crop();
      this.setState({ active: true });
    }
  }

  resize() {

  }


  // lifecycle methods

  componentDidMount() {
    this.initCropper();
  }

  componentDidUpdate(prevProps) {
    if (this.props.image !== prevProps.image) {
      const image = this.props.image;
      this.putImage(image);
    }
    if (this.props.full !== prevProps.full) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  // render
  render() {
    const { classes, image } = this.props;
    const { active, loading } = this.state;

    return (
      <Fragment>
        <div
          className={classNames(classes.layer,classes.flex)}
          ref={el => this.container = el}
        >
          <div className={classNames(classes.opacity,classes.full)}/>
          <div
            ref={el => this.wrap = el}
            className={classes.wrap}
          >
            <img
              id="image"
              src={image}
              ref={el => this.image = el}
              className={classes.image}
              alt={image}
            />
          </div>
          {loading &&
            <div className={classNames(classes.full,classes.flex)}>
              <CircularProgress
                color="secondary"
                size={120}
                className={classes.progress}
              />
            </div>
          }
        </div>
        <CropperButtons
          active={active}
          loading={loading}
          rotate={this.rotate}
          scaleX={this.scaleX}
          scaleY={this.scaleY}
          reset={this.reset}
          crop={this.crop}
          changeImage={this.changeImage}
          toggleActive={this.toggleActive}
        />
      </Fragment>
    );
  }
}

// props defaults
CropperComponent.defaultProps = {
  image: '/img/DYmseNWXcAURwtk.jpg',
};

// props validation
CropperComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CropperComponent);
