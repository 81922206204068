// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            name: '',
            file: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            submitForm,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.nameInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept=".json"
                    className={classes.input}
                    id="file"
                    name="file"
                    type="file"
                    onChange={(e) => {
                      setFieldValue("file", e.currentTarget.files[0]);
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!dirty}
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}


// props validation
FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormComponent);
