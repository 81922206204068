// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.tooltip = React.createRef();
    this.tip = React.createRef();
    this.x = React.createRef();
    this.y = React.createRef();
    this.g = React.createRef();
    // Bind functions
    this.reduceIntervals = this.reduceIntervals.bind(this);
  }

  // custom functions
  reduceIntervals(data) {

    const intervals = ['1','2','3-4','5-9','+10'];

    const count = _.reduce(data, function(result, value, key) {
      const val = value.total_rooms;
      // count intervals
      if (val === 1) {
        result[0] = (result[0]?result[0]+1:1);
      } else if (val === 2 ) {
        result[1] = (result[1]?result[1]+1:1);
      } else if (val < 5) {
        result[2] = (result[2]?result[2]+1:1);
      } else if (val < 10) {
        result[3] = (result[3]?result[3]+1:1);
      } else {
        result[4] = (result[4]?result[4]+1:1);
      }
      return result;
    }, {});

    const values = _.map(count, function(x,i) {
      return {
        title: intervals[i],
        count: x
      };
    });

    return values;
  }

  updateGraph() {
    const { data } = this.props;

    // transitions
    //const t = d3.transition().duration(750);

    // if valid data
    if (!_.isEmpty(data)) {

      // parse data
      const pie_data = this.reduceIntervals(data);
      const arc = this.arc;
      const tip = this.tip;

      const total = d3.sum(data, function(d){ return d.count; });
      const color = d3.scaleOrdinal().range(["red", "green", "blue", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"]);

      const Pie = d3.pie()
        .value(total)
        .sort(null);

      // General Update Pattern
      // JOIN new data with old elements
      const parts = this.g.selectAll('.parts')
        .data(Pie(pie_data));
      // EXIT old elements not present in new data
      parts.exit()
        .remove();
      // UPDATE old elements present in new data
      // ENTER new elements present in new data
      const enter_parts = parts.enter().append("g")
        .attr('class', 'parts');
      enter_parts.append('path')
        .attr('d', arc)
        .attr('fill', function(d,i) {
          return color(i);
        });
      // mouse events
      enter_parts
        .on('mouseover', () => {
          tip.transition()
            .duration(200)
            .style('opacity', 1);
        })
        .on('mouseout', () => {
          tip.transition()
            .duration(500)
            .style('opacity', 0);
        })
        .on('mousemove', (d) => {
          console.log(d);
          tip.html(`${d.data.title}<br/>${d.data.count}`)
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        });
      /*
      var label = arc.append("g")
        .attr("transform", function(d) { return "translate(" + Arc.centroid(d) + ")"; });

      label.append("text")
        .attr("text-anchor", "middle")
        .text(function(d) { return d.data.title; });

      label.append("text")
        .attr("dy", "20")
        .attr("text-anchor", "middle")
        .text(function(d) { return d3.format(".2%")(d.data.count / total); });
        */
    }
  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // init main group
    this.g = this.canvas.append("g")
      .attr('transform', `translate(${width_data/2 + margin[0]}, ${width_data/2 + margin[2]})`);

    // init scales
    this.x = d3.scaleLinear().range([0, width_data]);
    this.y = d3.scaleLinear().range([height_data, 0]);

    // init pie constants
    const radius = Math.min(width_data, height_data) / 2;
    this.arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(radius - 85);

    // tooltip
    this.tip = d3.select(this.tooltip);

    // Put data
    this.updateGraph();
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height } = this.props;

    return (
      <Fragment>
        <div
          ref={el => this.tooltip = el}
          className="tooltip"
        />
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="none"
          />
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:500,
  height:500,
  margin:[20,20,20,20],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
