// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 2500 887" xmlSpace="preserve">
      <g id="word" transform="translate(498 107)">
      	<path fill={color} d="M1413.4,179.3h-42.6l0.3,317h172.1V457h-129.8V179.3z"/>
      	<path fill={color} d="M1707.3,440.4c-10.7,11.2-28.6,22.4-52.8,22.4c-32.5,0-45.5-15.9-45.5-36.7c0-31.5,21.8-46.5,68.1-46.5
      		c8.6,0,22.6,1,29.9,2.3v58.5H1707.3z M1663.3,248.5c-34.3,0-65.8,12.2-90.5,32.5l15.1,26c17.4-10.1,38.7-20.3,69.2-20.3
      		c34.8,0,50.4,17.9,50.4,47.8v15.6c-6.8-1.6-20.8-2.3-29.4-2.3c-74.6,0-112.3,26-112.3,80.9c0,48.9,29.9,73.6,75.4,73.6
      		c30.7,0,60.1-14,70.2-36.7l7.8,30.9h29.6V334C1748.9,282.8,1726.5,248.5,1663.3,248.5z"/>
      	<path fill={color} d="M1886.2,464.3c-15.9,0-29.9-1.8-40.6-6.8V310.6c14.6-12.2,32.5-20.8,55.1-20.8c41.1,0,57.2,29.1,57.2,75.9
      		C1957.9,432.8,1932.2,464.3,1886.2,464.3 M1904.1,248.5c-38,0-58.5,26-58.5,26v-41.1l-0.3-54.1h-41.6l0.3,309.2
      		c20.8,8.6,49.4,13.5,80.4,13.5c79.3,0,117.5-50.7,117.5-138.3C2001.9,294.2,1966.5,248.5,1904.1,248.5"/>
      	<path fill={color} d="M950.5,213.6c37.7,0,61.9,12.5,78,25.2l18.2-31.7c-25-21.8-58.5-33.8-94.1-33.8
      		c-90.5,0-153.9,55.1-153.9,166.4c0,116.5,68.4,162,146.7,162c39.3,0,72.6-9.1,94.4-18.5l-0.8-124.6v-39.3H922.7v39.3h74.1l0.8,94.7
      		c-9.6,4.9-26.5,8.6-49.4,8.6c-62.9,0-105.1-39.5-105.1-123C843.1,254.7,886.8,213.6,950.5,213.6"/>
      	<path fill={color} d="M1250.1,179.3h-41.6l0.3,53.3v183.6c0,51.2,22.4,85.6,85.6,85.6c8.8,0,17.4-0.8,25.7-2.3V462
      		c-6,1-12.5,1.6-19.2,1.6c-34.8,0-50.2-17.9-50.2-47.8V289h69.2v-34.8h-69.7V179.3z"/>
      	<path fill={color} d="M1102.9,496h41.6V254.2h-41.6L1102.9,496L1102.9,496z"/>
      	<path fill={color} d="M1102.9,220.9h41.6v-41.6h-41.6L1102.9,220.9L1102.9,220.9z"/>
      </g>
      <g>
      	<path  fill={color} d="M483.3,884L20.4,547.3c-5.7-3.8-11.5-11.5-13.4-19.1s-1.9-15.3,0-23l53.6-162.6L483.3,884z M202.1,15.6l105.2,327.1H60.6
      		L165.8,15.6c1.9-7.7,9.6-11.5,17.2-11.5C194.5,2.2,200.2,7.9,202.1,15.6z M307.3,342.7h352L483.3,884L307.3,342.7z M961.5,507.2
      		c1.9,7.7,1.9,15.3,0,23c-1.9,7.7-7.7,13.4-13.4,19.1L483.3,884l422.7-541.3L961.5,507.2z M906.1,342.7H661.2L766.4,15.6
      		c1.9-7.7,9.6-11.5,17.2-11.5c9.6,0,15.3,3.8,17.2,11.5L906.1,342.7z"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
