// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';

const styles = theme => ({
  game: {
    position: 'relative',
    margin: '50px auto',
    width: '400px',
    height: '400px',
    border: '2px solid #000',
  }
});


class Snake extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
  }

  // custom functions


  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <Fragment>
      </Fragment>
    );
  }
}

// props validation
Snake.defaultProps = {
};

Snake.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Snake);
