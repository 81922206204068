// Dependencies
import React, { Component, Fragment } from 'react';


// Components
import FileManager from '../FileManager/Index';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Uploads';
    // Innit state
    this.state = {
    };
    // Bind functions
    this.getSelected = this.getSelected.bind(this);
  }

  // custom functions

  getSelected(selected) {
    console.log(selected);
  }

  // lifecycle methods

  // render
  render() {
    //const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h2" component="h2" gutterBottom>
          Uploads
        </Typography>
        <FileManager
          getSelected={this.getSelected}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Index);
