// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import LockIcon from '@material-ui/icons/LockOutlined';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

// Auth
import auth from '../../../lib/auth';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});


class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      result: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault()

    const email = this.email.value;

    this.setState({ error: false });

    // check is user
    auth.recoverPassword(email, (result, error) => {
      if (result) {
        this.setState({
          result: true
        });
      } else {
        this.setState({
          error: `error_${error.status}`
        });
      }
    });
  }

  render() {
    const { classes, intl } = this.props;

    return (
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Typography variant="h5">Recover Password</Typography>
        {/*
        {this.state.result ? (
          <div className="result">
            Mail send with new password...
          </div>
        ) : (
        )}
        */}
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="raised"
            color="primary"
            className={classes.submit}
          >
            {intl.formatMessage({ id: 'buttons.enter' })}
          </Button>
          {/*
          <input type="email" ref={(c) => { this.email = c; }} placeholder={intl.formatMessage({ id: 'placeholders.email' })} />
          <button type="submit" onClick={this.handleSubmit}>{intl.formatMessage({ id: 'buttons.enter' })}</button>
          {this.state.error && (
            <p className="form-error">{intl.formatMessage({ id: `recover_password.${this.state.error}` })}</p>
          )}
          */}
          <Typography variant="caption" align="center">
            {intl.formatMessage({ id: 'recover_password.text' })}
            <Link to="/login">{intl.formatMessage({ id: 'recover_password.link_login' })}</Link>
          </Typography>
        </form>
      </Paper>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(Index));
