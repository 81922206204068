// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import OpenIcon from '@material-ui/icons/Fullscreen';
import CloseIcon from '@material-ui/icons/FullscreenExit';

const styles = {
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  full: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1100,
    background: '#fff'
  },
  button: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1000,
  }
};

class FullScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      full: false
    };
    // Bind functions
    this.toggleFull = this.toggleFull.bind(this);
  }

  // custom functions
  toggleFull() {
    this.setState( (prevState) => {
      document.body.classList.toggle('noscroll', !prevState.full);
      return  { full: !prevState.full };
    });
  }

  // lifecycle methods
  componentDidMount() {
    document.body.classList.toggle('noscroll', this.state.full);
  }

  componentWillUnmount() {
    document.body.classList.remove('noscroll');
  }

  // render
  render() {
    const { classes, children } = this.props;
    const { full } = this.state;
    const icon = !full?<OpenIcon />:<CloseIcon />;

    return (
      <Fragment>
        <div className={!full?classes.root:classes.full}>
          <Fab
            size="medium"
            color="primary"
            className={classes.button}
            onClick={this.toggleFull}
            aria-label="fullScreen"
          >
            {icon}
          </Fab>
          {React.cloneElement(children, { full: full })}
        </div>
      </Fragment>
    );
  }
}

FullScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(FullScreen);
