// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Components
import ScrollToTop from './Global/ScrollToTop';

// Styles
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';

// MuiTheme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#fff'
    },
  },
  iconButtonSmall: {
    fontSize: 20,
  },
  typography: {
    useNextVariants: true,
  }
});

const styles = {
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    minHeight: '100vh'
  },
  content: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }
};


class Layout extends Component {
  // construct
  constructor(props) {
    super(props);
    //
    document.title = 'Login';
    // Innit state
    this.state = {};
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes, children } = this.props;

    return (
      <ScrollToTop>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <main className={classes.content}>
              {children}
            </main>
          </div>
        </MuiThemeProvider>
      </ScrollToTop>
    );
  }
}

// props validation
Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(Layout);
