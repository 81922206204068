// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as weatherActions from '../../redux/actions/weather';

//import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/*
const styles = {
  intro: {
    height: '500px',
    backgroundColor: 'rgb(231, 76, 60)'
  }
};
*/

const Weather = (props) => {
  //const { classes } = props;
  const { weather } = props;

  return (
    <Fragment>
      <Typography>{weather.temp} {weather.main}</Typography>
    </Fragment>
  )
}

/*
Weather.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Weather);
*/

// props validation
Weather.propTypes = {
  actions: PropTypes.object.isRequired,
  weather: PropTypes.object.isRequired
};

// connect Redux & related methods
const mapStateToProps = (state, ownProps) => {
  return {
    weather: state.weather
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(weatherActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Weather);
