// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';
import InputMask from 'react-input-mask';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  field: {
    paddingBottom: theme.spacing(2)
  }
});


class FormComponent extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
  }

  // custom methods

  // lifecycle methods

  componentDidMount() {
  }

  // render
  render() {
    const { classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '(+56)',
            message: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form>
              <div className={classes.field}>
                <TextField
                  id="name"
                  label="Name"
                  placeholder="Your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={el => this.nameInput = el}
                  fullWidth
                  required
                />
              </div>
              <div className={classes.field}>
                <TextField
                  id="email"
                  label="Email"
                  placeholder="your@email.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={el => this.mailInput = el}
                  fullWidth
                  required
                />
              </div>
              <div>
                <InputMask
                  mask="(+99)9999999999"
                  maskChar=" "
                  value={values.phone}
                  onChange={handleChange}
                >
                  {() => <TextField
                    id="phone"
                    label="Phone"
                    type="text"
                    fullWidth
                    />}
                </InputMask>
              </div>
              <div className={classes.field}>
                <TextField
                  id="message"
                  label="Message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputRef={el => this.messageInput = el}
                  fullWidth
                  required
                  multiline
                  rows={8}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!dirty}
                  onClick={handleReset}
                  className={classes.button}
                >
                  Clear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Fragment>
    );
  }
}

export default withStyles(styles)(FormComponent);
