// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods
  componentDidMount(){
    this.nameInput.focus();
  }

  // render
  render() {
    const { classes, onSubmit, error } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    placeholder="your@email.com"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={el => this.nameInput = el}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    className={classes.button}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.text}>
                  {error}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}

export default withStyles(styles)(FormComponent);
