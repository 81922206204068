// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import LinkIcon from '@material-ui/icons/Link';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';
import Form from './Form';
import Options from './Options';
import Table from './Table';


const styles = theme => ({
  stepper: {
    background: 'transparent',
    margin: '30px 0',
    padding: 0
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  progress: {
    margin: '20px 0'
  },
  link: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  }
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      activeStep: 0,
      loading: false,
      url: false,
      selected: false,
      tables: []
    };
    // Bind functions
    this.scrapHtml = this.scrapHtml.bind(this);
    this.sendUrl = this.sendUrl.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.showTable = this.showTable.bind(this);
    this.reset = this.reset.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  // custom functions
  scrapHtml(url) {
    if (url) {
      axios.get(
        '/api/scrap/page_tables',
        {
          params: { url: url },
          cancelToken: new CancelToken(function executor(c) { cancel = c; })
        }
      )
        .then(res => {
          //
          const tables = res.data.tables;
          //
          this.setState({
            loading: false,
            tables: tables,
            selected: false
          });
        })
        .catch(err => {
          this.setState({
            loading: false
          });
        });
    }
  }

  sendUrl(values) {
    // get parts
    if (values.url) {
      // init values
      this.setState({
        loading: true,
        url: values.url,
        selected: false,
        tables: [],
      });
      this.scrapHtml(values.url);
    }
    this.handleNext();
  }

  reset() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on reset.');
    // Reset media state values
    this.setState({
      loading: false,
      url: false,
      selected: false,
      tables: [],
    });
  }

  updateSelected(value) {
    this.setState({ selected: value });
  }

  showTable(type) {
    this.handleNext();
  }

  downloadCsv() {
    const { selected, tables } = this.state;
    const table = tables[selected];
    const csv = table.csv;
    const filename = `scrap_table_${Date.now()}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const dataURI = `data:text/csv;charset=utf-8,${csv}`;

      const URL = window.URL || window.webkitURL;
      const downloadURI = typeof URL.createObjectURL === "undefined" ? dataURI : URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.setAttribute("href", downloadURI);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // stepper

  handleNext() {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  }

  handleBack() {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  }

  handleReset() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on reset.');
    // Reset state values
    this.setState({
      activeStep: 0,
    });
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on unmount.');
  }

  // render
  render() {
    const { classes } = this.props;
    const { url, loading, selected, tables, activeStep } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Scrap Tables
            </Typography>
            <Typography variant="h3" component="h3">
              Descarregar elements d'un lloc web
            </Typography>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography>
              Amb tècniques de scrapping obtenim les taules d'una pàgina web.
            </Typography>
            <div>
              <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                <Step key="url">
                  <StepLabel>URL</StepLabel>
                  <StepContent>
                    <Typography>
                      Cillum quibusdam penatibus quae quisque dapibus porro deserunt accusamus officia pulvinar voluptatibus varius officiis, pariatur qui, tristique voluptates cubilia reiciendis aperiam aliquip deserunt
                    </Typography>
                    <Form
                      onSubmit={this.sendUrl}
                      onReset={this.reset}
                    />
                  </StepContent>
                </Step>
                <Step key="type">
                  <StepLabel>Type</StepLabel>
                  <StepContent>
                    <Typography>
                      Cillum quibusdam penatibus quae quisque dapibus porro deserunt accusamus officia pulvinar voluptatibus varius officiis, pariatur qui, tristique voluptates cubilia reiciendis aperiam aliquip deserunt
                    </Typography>
                    <Typography>
                      <strong className={classes.link}>{url}</strong> <a href={url} className={classes.link} target="_blank" rel="noopener noreferrer"><LinkIcon/></a>
                    </Typography>
                    {loading ? (
                      <CircularProgress
                        color="secondary"
                        size={40}
                        className={classes.progress}
                      />
                    ) : (
                      <Options
                        values={tables}
                        selected={selected}
                        updateSelected={this.updateSelected}
                      />
                    )}
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!selected}
                          onClick={(e) => this.showTable(e)}
                          className={classes.button}
                        >
                          Next
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => this.handleReset(e)}
                          className={classes.button}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
                <Step key="results">
                  <StepLabel>Results</StepLabel>
                  <StepContent>
                    <Typography>
                      Cillum quibusdam penatibus quae quisque dapibus porro deserunt accusamus officia pulvinar voluptatibus varius officiis, pariatur qui, tristique voluptates cubilia reiciendis aperiam aliquip deserunt
                    </Typography>
                    <Typography>
                      <strong className={classes.link}>{url}</strong> <a href={url} className={classes.link} target="_blank" rel="noopener noreferrer"><LinkIcon/></a>
                    </Typography>
                    {loading ? (
                      <CircularProgress
                        color="secondary"
                        size={40}
                        className={classes.progress}
                      />
                    ) : (
                      <Table
                        table={tables[parseInt(selected)]}
                        downloadCsv={this.downloadCsv}
                      />
                    )}
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          variant="contained"
                          className={classes.button}
                          onClick={(e) => this.downloadCsv(e)}
                        >
                          <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                          Descarregar CSV
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) => this.handleBack(e)}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => this.handleReset(e)}
                          className={classes.button}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              </Stepper>
            </div>
          </Container>
        </section>
        <DataSet data={this.state.tables} title="State" />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
