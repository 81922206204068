// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 503.8 503.8" xmlSpace="preserve">
      <path fill={color} d="M251.9,45.4c67.3,0,75.2,0.3,101.8,1.5c24.6,1.1,37.9,5.2,46.8,8.7c10.9,4,20.8,10.5,29,18.8c8.4,8.1,14.8,18,18.8,29
      	c3.5,8.9,7.6,22.2,8.7,46.8c1.2,26.6,1.5,34.5,1.5,101.8s-0.3,75.2-1.5,101.8c-1.1,24.6-5.2,37.9-8.7,46.8
      	c-8.5,22-25.8,39.3-47.8,47.8c-8.9,3.5-22.2,7.6-46.8,8.7c-26.6,1.2-34.5,1.5-101.8,1.5s-75.2-0.3-101.8-1.5
      	c-24.6-1.1-37.9-5.2-46.8-8.7c-10.9-4-20.8-10.5-29-18.8c-8.4-8.1-14.8-18-18.8-29c-3.5-8.9-7.6-22.2-8.7-46.8
      	c-1.2-26.6-1.5-34.5-1.5-101.8s0.3-75.2,1.5-101.8c1.1-24.6,5.2-37.9,8.7-46.8c4-10.9,10.5-20.8,18.8-29c8.1-8.4,18-14.8,29-18.8
      	c8.9-3.5,22.2-7.6,46.8-8.7C176.7,45.6,184.7,45.4,251.9,45.4 M251.9,0c-68.4,0-77,0.3-103.9,1.5C121.2,2.7,102.9,7,86.9,13.2
      	c-16.8,6.3-32,16.2-44.6,29.1C29.5,54.9,19.5,70.1,13.2,86.9c-6.2,16-10.5,34.3-11.7,61.2C0.3,174.9,0,183.5,0,251.9
      	s0.3,77,1.5,103.9c1.2,26.8,5.5,45.1,11.7,61.2c6.3,16.8,16.2,32,29.1,44.6c12.6,12.8,27.8,22.7,44.6,29.1
      	c16,6.2,34.3,10.5,61.2,11.7c26.9,1.2,35.4,1.5,103.9,1.5s77-0.3,103.9-1.5c26.8-1.2,45.1-5.5,61.2-11.7
      	c33.8-13.1,60.6-39.8,73.7-73.7c6.2-16,10.5-34.3,11.7-61.2c1.2-26.9,1.5-35.4,1.5-103.9s-0.3-77-1.5-103.9
      	c-1.2-26.8-5.5-45.1-11.7-61.2c-6.3-16.8-16.2-32-29.1-44.6c-12.6-12.8-27.8-22.7-44.6-29.1c-16-6.2-34.3-10.5-61.2-11.7
      	C328.9,0.3,320.3,0,251.9,0L251.9,0L251.9,0z"/>
      <path fill={color} d="M251.9,122.6c-71.4,0-129.4,57.9-129.4,129.4s57.9,129.4,129.4,129.4s129.4-57.9,129.4-129.4l0,0
      	C381.3,180.5,323.4,122.6,251.9,122.6z M251.9,335.9c-46.4,0-84-37.6-84-84s37.6-84,84-84c46.4,0,84,37.6,84,84
      	C335.9,298.3,298.3,335.9,251.9,335.9z"/>
      <circle fill={color} cx="386.4" cy="117.4" r="30.2"/>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
