// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

// Components
import ListItemFile from './ListItemFile';
import ListItemDir from './ListItemDir';
import ListItemPrevDir from './ListItemPrevDir';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});


const List = (props) => {
  const { classes, route, files, openFolder, deleteFolder, move, rename, deleteFile, previewFile, downloadFile, select  } = props;

  const prevItem = () => {
    // prev dir
    const folders = route.split('/');
    folders.pop();
    const prevDir = folders.join('/');

    // component
    return (<ListItemPrevDir
      route={prevDir}
      openFolder={openFolder}
      move={move}
    />);
  };

  const listItems = _.map(files, (item, key) => {

    if (item.type === 'dir') {

      return (<ListItemDir
        key={item.id}
        item={item}
        openFolder={openFolder}
        deleteFolder={deleteFolder}
        move={move}
        rename={rename}
      />);

    } else {

      return <ListItemFile
        key={item.id}
        item={item}
        deleteFile={deleteFile}
        downloadFile={downloadFile}
        previewFile={previewFile}
        rename={rename}
        select={select}
        selected={item.selected}
      />;
    }
  });

  // render
  return (
    <div className={classes.root}>
      {route ? prevItem() : ''}
      {files.length > 0 ? listItems : ''}
    </div>
  )
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  openFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  previewFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
};

export default withStyles(styles)(List);
