// Dependencies
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  title: {
    lineHeight: '56px',
    marginBottom: theme.spacing(2)
  }
});


const Index = (props) => {
  const { classes } = props;

  document.title = 'Error';

  // render
  return (
    <Fragment>
      <Typography variant="h2" component="h2" className={classes.title}>
        <FormattedMessage id="error.404" />
      </Typography>
    </Fragment>
  );
}

export default withStyles(styles)(Index);
