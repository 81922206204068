// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Components
import LogsList from './LogsList';


const styles = theme => ({
  title: {
    lineHeight: '56px'
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  box: {
    padding: '15px',
  },
  pre: {
    margin: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    padding: '10px',
    borderRadius: '5px'
  }
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Dev';
    // Innit state
    this.state = {
      date: Date.now(),
      env: process.env.NODE_ENV,
      os_info: {},
      node_info: {},
      mongo_info: {},
      logs: []
    };
    // Bind functions
    this.loadOsInfo = this.loadOsInfo.bind(this);
    this.loadNodeInfo = this.loadNodeInfo.bind(this);
    this.loadMongoInfo = this.loadMongoInfo.bind(this);
    this.loadLogs = this.loadLogs.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  loadOsInfo() {
    axios.get('/api/info/server', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ os_info: res.data });
      });
  }

  loadNodeInfo() {
    axios.get('/api/info/node', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ node_info: res.data });
      });
  }

  loadMongoInfo() {
    axios.get('/api/info/mongo', { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ mongo_info: res.data });
      });
  }

  loadLogs() {
    axios.post('/api/logs', {}, { cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ logs: res.data });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadOsInfo();
    this.loadNodeInfo();
    this.loadMongoInfo();

    this.logTimer = setInterval(
      () => this.loadLogs(),
      10000
    );
    this.loadLogs();
  }

  componentWillUnmount() {
    clearInterval(this.logTimer);
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h2" component="h2" className={classes.title}>
          Server Info
        </Typography>
        <Typography variant="h4" component="h4"  className={classes.subtitle}>
          Environment: { this.state.env }
        </Typography>
        <Paper square className={classes.box}>
          <Typography variant="h5" component="h5">
            Server
          </Typography>
          <pre
            className={classes.pre}
            dangerouslySetInnerHTML={{ __html: this.state.os_info }}
          />
        </Paper>
        <Paper square className={classes.box}>
          <Typography variant="h5" component="h5">
            Node.js
          </Typography>
          <pre
            className={classes.pre}
            dangerouslySetInnerHTML={{ __html: this.state.node_info }}
          />
        </Paper>
        <Paper square className={classes.box}>
          <Typography variant="h5" component="h5">
            MongoDB
          </Typography>
          <pre
            className={classes.pre}
            dangerouslySetInnerHTML={{ __html: this.state.mongo_info }}
          />
        </Paper>
        <Paper square className={classes.box}>
          <Typography variant="h5" component="h5">
            Logs
          </Typography>
          <LogsList logs={ this.state.logs }/>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Index);
