// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.tooltip = React.createRef();
    this.tip = React.createRef();
    this.x = React.createRef();
    this.y = React.createRef();
    this.g = React.createRef();
    this.xAxis = React.createRef();
    this.xAxisCall = React.createRef();
    this.yAxis = React.createRef();
    this.yAxisCall = React.createRef();
    this.xGridAxis = React.createRef();
    this.xGridAxisCall = React.createRef();
    this.yGridAxis = React.createRef();
    this.yGridAxisCall = React.createRef();
  }

  // custom functions

  updateGraph() {
    const { data } = this.props;

    /*
    // Join
    var p = d3.selectAll("p").data(data);
    // Enter
    p.enter().append("p");
    // Enter + Update
    p.text(function (d) { return "Number: " + d; });
    // Exit
    p.exit().remove();
    */

    // transitions
    const t = d3.transition().duration(750);
    // colors
    const blue = "#3498db";
    const orange = "#f39c12";
    const red = "#e74c3c";
    const red_strong = "#c0392b";

    // if valid data
    if (!_.isEmpty(data)) {
      // Update our scales
      const scale_x = this.x.domain([d3.min(data, function(d){ return d.gpa; }) / 1.05, d3.max(data, function(d){ return d.gpa; }) * 1.05]);
      const scale_y = this.y.domain([d3.min(data, function(d){ return d.height; }) / 1.05, d3.max(data, function(d){ return d.height; }) * 1.05]);
      const tip = this.tip;

      // Update our axis
      this.xAxis.transition(t).call(this.xAxisCall);
      this.xGridAxis.transition(t).call(this.xGridAxisCall);
      this.yAxis.transition(t).call(this.yAxisCall);
      this.yGridAxis.transition(t).call(this.yGridAxisCall);

      //
      const clearOtherActives = () => {
        this.g.selectAll('circle.selected')
          .classed('selected', false)
          .transition()
          .duration(250)
          .attr('fill', blue);
      }
      // General Update Pattern
      // JOIN new data with old elements
      const circles = this.g.selectAll('circle')
        .data(data);
      // UPDATE old elements present in new data
      circles.transition(t)
        .attr('cx', function(d){ return scale_x(d.gpa); })
        .attr('cy', function(d){ return scale_y(d.height); })
        .attr('r', function(d){ return d.intensity; })
        .attr('fill-opacity', 0.7);
      // ENTER new elements present in new data
      const enter_circles = circles.enter().append('circle');
      // atributes
      enter_circles
        .attr('cx', function(d){ return scale_x(d.gpa); })
        .attr('cy', function(d){ return scale_y(d.height); })
        .attr('r', function(d){ return d.intensity; })
        .attr('fill', blue)
        .attr('fill-opacity', 0);
      // transition
      enter_circles
        .transition(t)
        .attr('fill-opacity', 0.7);
      // mouse events
      enter_circles
        .on('click', function() {
          let selected = true;
          let fillColor = red_strong;
          if (d3.select(this).classed('selected')) {
            selected = false;
            fillColor = orange;
          } else {
            clearOtherActives();
          }
          d3.select(this)
            .classed("selected", selected)
            .transition()
            .duration(250)
            .attr("fill", fillColor)
            .attr('fill-opacity', 0.7);
        })
        .on('mouseover', function() {
          //
          let colorFill = orange;
          if (d3.select(this).classed("selected")) colorFill = red_strong;
          //
          d3.select(this).transition()
            .duration(250)
            .attr("fill", colorFill)
            .attr('fill-opacity', 0.7);
          tip.transition()
            .duration(200)
            .style('opacity', 1);
        })
        .on('mouseout', function() {
          //
          let colorFill = blue;
          if (d3.select(this).classed("selected")) colorFill = red;
          //
          d3.select(this).transition()
            .duration(250)
            .attr("fill", colorFill)
            .attr('fill-opacity', 0.7);
          tip.transition()
            .duration(500)
            .style('opacity', 0);
        })
        .on('mousemove', function(d) {
          tip.style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        })
        .on('mouseenter', function(d) {
          const f = d3.format('.4f');
          tip.html(`H: ${f(d.height)}<br/>G: ${f(d.gpa)}<br/>I: ${f(d.intensity)}`)
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 50}px`);
        });
      // EXIT old elements not present in new data
      circles.exit().transition(t)
        .attr('fill-opacity', 0)
        .attr('cy', scale_y(0))
        .remove();
    }
  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // init main group
    this.g = this.canvas.select(".graph");

    // init scales
    this.x = d3.scaleLinear().range([0, width_data]);
    this.y = d3.scaleLinear().range([height_data, 0]);

    // init axes and labels
    this.xAxisCall = d3.axisBottom(this.x).ticks(5);
    this.xAxis = this.canvas.select(".x_axis");

    this.xGridAxisCall = d3.axisBottom(this.x).tickFormat('').tickSize(height_data).tickSizeOuter(0).ticks(5);
    this.xGridAxis = this.canvas.select(".x_grid_axis");

    this.yAxisCall = d3.axisLeft(this.y).ticks(4);
    this.yAxis = this.canvas.select(".y_axis");

    this.yGridAxisCall = d3.axisLeft(this.y).tickFormat('').tickSize(width_data).tickSizeOuter(0).ticks(4);
    this.yGridAxis = this.canvas.select(".y_grid_axis");

    // init tooltip
    this.tip = d3.select(this.tooltip);
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height, margin } = this.props;
    const width_data = width - (margin[0]+margin[2]);
    const height_data = height - (margin[1]+margin[3]);

    return (
      <Fragment>
        <div
          ref={el => this.tooltip = el}
          className="tooltip"
        />
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="none"
          />
          <g
            transform={`translate(${margin[0]}, ${margin[1]})`}
          >
            <g className="graph" transform={`translate(0, 0)`} />
            <g className="axis x_axis" transform={`translate(0, ${height_data})`}>
              <text className="axis-title" transform={`translate(${width_data-6}, -6)`}>Grade Point Average</text>
            </g>
            <g className="axis y_axis" transform={`translate(0, 0)`}>
              <text className="axis-title" transform={`rotate(-90) translate(-6, 16)`}>Height / Centimeters</text>
            </g>
            <g className="grid-axis x_grid_axis" strokeDasharray="2,2" transform={`translate(0, 0)`} />
            <g className="grid-axis y_grid_axis" strokeDasharray="2,2" transform={`translate(${width_data}, 0)`} />
          </g>
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:700,
  height:500,
  margin:[50,30,40,50],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
