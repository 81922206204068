// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  container: {
    width: '100%',
    margin: '0 auto',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  fixed: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '720px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '940px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1140px'
    }
  }
});


const Container = (props) => {
  const { classes, children, fixed, className } = props;
  const containerClass = classNames(
    classes.container,
    {
      [classes.fixed]: fixed,
    },
    className
  );

  // render
  return (
    <div className={containerClass}>
      {children}
    </div>
  )
}

Container.defaultProps = {
  fixed: false
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(Container);
