// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios, { CancelToken } from 'axios';
import * as d3 from 'd3';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slider from '@material-ui/lab/Slider';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/NotInterested';
import PhotoIcon from '@material-ui/icons/Photo';


const styles = theme => ({
  layer: {
    height: '100%',
    width: '100%',
    background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC) left top repeat',
    '& svg': {
      position: 'relative'
    }
  },
  flex: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  full: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  opacity: {
    background: '#111',
    opacity: '0.6'
  },
  hidden: {
    display: 'none',
  },
  wrap: {
    display: 'flex',
    flex:1,
    minHeight: 0
  },
  slider: {
    padding: '30px 0px',
    width: '50%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: '10px',
    minWidth: '32px',
    '& + button': {
      marginLeft: '10px'
    }
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  }
});

// axios cancel
let cancel;


class Pixel extends Component {

  // construct
  constructor(props) {
    super(props);

    this.state = {
      img: null,
      loading: false,
      size: 15,
      anchorEl: null,
      openDialog: false,
      viewDialog: false
    };
    // Refs
    this.layer = React.createRef();
    this.canvas = React.createRef();
    this.image = React.createRef();
    this.svg = React.createRef();
    this.context = React.createRef();
    // bind functions
    this.changeImage = this.changeImage.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.sliderDragEnd = this.sliderDragEnd.bind(this);
  }

  // custom functions

  init() {
    // squares group
    const svg = d3.select(this.svg);
    svg.append('g')
      .attr('class', 'square-g');
    // put image
    this.putImage();
  }

  putImage(cb) {
    const { image } = this.props;

    // clear
    this.image.setAttribute('src', '');
    // loading
    this.setState({ loading: true });
    // load
    axios.get(
      image,
      {
        responseType: 'arraybuffer',
        cancelToken: new CancelToken(function executor(c) { cancel = c; })
      }
    )
      .then(res => {
        // parts base64
        const prefix = "data:" + res.headers["content-type"] + ";base64,";
        const base64 = Buffer.from(res.data, 'binary').toString('base64');
        // construim imatge
        let img = new Image();
        img.src = prefix + base64;
        img.onload = () => {
          // imatge
          this.image.setAttribute('width', img.width);
          this.image.setAttribute('height', img.height);
          this.image.setAttribute('src', img.src);
          this.setState({ img: img });
          // Render the image on the canvas
          this.canvas.setAttribute('width', img.width);
          this.canvas.setAttribute('height', img.height);
          this.canvas.getContext('2d').drawImage(img, 0, 0);
          // ratios layer/img
          const r_x = this.layer.offsetWidth/img.width;
          const r_y = this.layer.offsetHeight/img.height;
          const ratio = (r_x<1 && r_y<1)?Math.min(r_x, r_y):1;
          // update svg
          d3.select(this.svg)
            .attr('width', img.width*ratio)
            .attr('height', img.height*ratio)
            .attr('viewBox', `0 0 ${img.width} ${img.height}`);
          // draw color squares
          const data = this.pixelateData();
          this.drawSvg(data);
          this.setState({ loading: false });
          // callback/return
          if (typeof(cb) === "function") return cb();
          return true;
        };
      });
  }


  drawSvg(data) {
    const { size } = this.state;

    const squares = d3.select(this.svg).select('.square-g').selectAll('.square').data(data);
    squares.exit().remove();
    squares.enter().append('rect')
      .attr('class', 'square')
      .merge(squares)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('width', size)
      .attr('height', size)
      .attr('fill', d => d.rgb)
      .style('opacity', 1)
      .on('mouseover', function (d, i) {
        d3.select(this).transition()
          .duration(250)
          .style('opacity', 0);
      });
  }


  pixelateData () {
    const { img, size } = this.state;

    const context = this.canvas.getContext('2d');
    const xSquares = img.width / size;
    const ySquares = img.height / size;
    let data = [];

    for (let x = 0; x < xSquares; x++) {
      for (let y = 0; y < ySquares; y++) {
        const rgba = context.getImageData(x * size, y * size, size, size).data;
        const len = rgba.length;
        const num = len / 4;
        let r = 0;
        let g = 0;
        let b = 0;
        // Sum the color values.
        for (let i = 0; i < len; i += 4) {
          r += rgba[i];
          g += rgba[i + 1];
          b += rgba[i + 2];
        }
        // Save the position and average color value.
        data.push({
          x: x * size,
          y: y * size,
          rgb: 'rgb(' + [
            Math.round(r / num),
            Math.round(g / num),
            Math.round(b / num)
          ].join(',') + ')'
        });
      }
    }
    return data;
  }

  changeImage(e) {
    const { updateImage } = this.props;
    const url = URL.createObjectURL(e.target.files[0]);
    if (url) {
      // cancel ajax
      if (typeof cancel === 'function') cancel('Operation canceled by the user on change image.');
      // update image
      updateImage(url);
    }
  }

  // slider

  sliderChange(e,value) {
    this.setState({
      size: value
    });
  }

  sliderDragEnd(e) {
    const data = this.pixelateData();
    this.drawSvg(data);
  }

  // menu download

  openMenuDownload(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  closeMenuDownload() {
    this.setState({ anchorEl: null });
  }

  download() {
    console.log('download');
    this.closeMenuDownload();

    this.setState((prevState) => {
      return {
        loading: !prevState.loading
      };
    });
  }

  // dialog file

  openDialogFile(e) {
    e.preventDefault();
    this.setState({
      openDialog: true,
      viewDialog: true
    });
  }

  closeDialogFile() {
    this.setState({ openDialog: false });
  }

  confirmDialogFile() {
    this.setState({ openDialog: false }, () => {
      this.inputFile.click();
    });
  }

  // lifecycle methods

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.image !== prevProps.image) {
      this.putImage();
    }
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on unmount.');
  }


  // render
  render() {
    const { classes, image } = this.props;
    const { loading, size, anchorEl, openDialog, viewDialog } = this.state;

    return (
      <Fragment>
        <div
          className={classNames(classes.layer,classes.flex)}
          ref={el => this.layer = el}
        >
          <div className={classNames(classes.opacity,classes.full)}/>
          <div className={classes.wrap}>
          <svg
              ref={el => this.svg = el}
              width="450"
              height="450"
            />
          </div>
          <img
            id="image"
            ref={el => this.image = el}
            className={classes.hidden}
            alt={image}
          />
          <canvas
            ref={el => this.canvas = el}
            width={450}
            height={450}
            className={classes.hidden}
          />
          {loading &&
            <div className={classNames(classes.full,classes.flex)}>
              <CircularProgress
                color="secondary"
                size={120}
                className={classes.progress}
              />
            </div>
          }
        </div>
        <div className={classes.buttons}>
          <Typography id="slider-icon">Pixel size</Typography>
          <Slider
            classes={{ container: classes.slider }}
            value={size}
            aria-labelledby="value"
            onChange={(e, val) => this.sliderChange(e, val)}
            onDragEnd={(e) => this.sliderDragEnd(e)}
            min={5}
            max={50}
            step={1}
            disabled={Boolean(loading)}
          />
          <div>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              aria-label="download"
              className={classes.button}
              onClick={(e) => this.openMenuDownload(e)}
              aria-owns={anchorEl ? 'download-menu' : undefined}
              aria-haspopup="true"
            >
              <DownloadIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
              Download
            </Button>
            <Menu
              id="download-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={(e) => this.download(e)}>JPG</MenuItem>
              <MenuItem onClick={(e) => this.download(e)}>PNG</MenuItem>
              <MenuItem onClick={(e) => this.download(e)}>SVG</MenuItem>
            </Menu>

            <input
              ref={el => this.inputFile = el}
              accept="image/*"
              className={classes.input}
              id="btn-file"
              type="file"
              onChange={(e) => this.changeImage(e)}
            />
            <label htmlFor="btn-file" className={classes.label}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                aria-label="change"
                component="span"
                className={classes.button}
                disabled={Boolean(loading)}
                onClick={(e) => { if (!viewDialog) this.openDialogFile(e)}}
              >
                <PhotoIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                Change Image
              </Button>
            </label>
            <Dialog
              open={openDialog}
              onClose={(e) => this.closeDialogFile(e)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Let Google help apps determine location. This means sending anonymous location data to
                  Google, even when no apps are running.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => this.closeDialogFile(e)} color="primary">
                  Disagree
                </Button>
                <Button onClick={(e) => this.confirmDialogFile(e)} color="primary">
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Fragment>
    );
  }
}

// props defaults
Pixel.defaultProps = {
  image: '/img/4.png',
};

// props validation
Pixel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pixel);
