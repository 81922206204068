// Dependencies
import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = {
  slide: {
    background: 'inherit',
    color: '#111',
    minHeight: '100px',
  }
};

class Social extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {};
    // Bind functions
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: true,
      centerPadding: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Fragment>
        <Typography variant="h6" color="inherit">
          Social
        </Typography>
        <Slider {...settings}>
          {_.times(12, i =>
            <div className={classes.slide} key={'slide-'+i}>
              {i}
            </div>
          )}
        </Slider>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Social);
