// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import Button from '@material-ui/core/Button';


const styles = theme => ({
});


const Select = (props) => {
  const { classes, getSelected, clearSelect, numSelect, maxSelect  } = props;

  // render
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="Unselect"
        onClick={clearSelect}
      >
        <ReplayIcon />
      </IconButton>
      {numSelect} / {maxSelect}
      <Button color="primary" className={classes.button} onClick={(e) => getSelected(e)}>
        Select
      </Button>
    </div>
  )
}

Select.propTypes = {
  getSelected: PropTypes.func.isRequired,
  clearSelect: PropTypes.func.isRequired,
  maxSelect: PropTypes.number.isRequired,
  numSelect: PropTypes.number.isRequired,
};

export default withStyles(styles)(Select);
