// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const styles = theme => ({
});


const Route = (props) => {
  const { classes, route, openFolder  } = props;
  
  // array folders
  const folders = route.split('/');
  // breadcrumb buttons
  const listFolders = _.map(folders, (item, key) => {
    if (item) {
      const slice = folders.slice(0, (key+1));
      const path = slice.join('/');
      return (<Fragment key={`folder_${key}`}>
        /
        <Button color="primary" className={classes.button} onClick={() => openFolder(path)}>
          {item}
        </Button>
      </Fragment>);
    }
  });

  // render
  return (
    <div className={classes.root}>
      <Button color="primary" className={classes.button} onClick={() => openFolder('')}>
        Uploads
      </Button>
      {listFolders}
    </div>
  )
}

Route.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  openFolder: PropTypes.func.isRequired
};

export default withStyles(styles)(Route);
