import * as types from '../actions/actionTypes';

const initialState = {};

const weatherReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.LOAD_WEATHER:
      return action.weather;

    default:
      return state;
  }
}

export default weatherReducer;
