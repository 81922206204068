import * as types from '../actions/actionTypes';

const initialState = []; // Array of objects, [{ id:1, name:'project1'}, { id:2, name:'project2'}];     

const projectsReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.CREATE_PROJECT:
      // ES6 spead operator
      return [...state, Object.assign({}, action.project)];

    case types.LOAD_PROJECTS_SUCCESS:
      return action.projects;

    default:
      return state;
  }
}

export default projectsReducer;
