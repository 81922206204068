import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Components
import ListItem from '../Global/ListItem';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import WarnIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';


const styles = theme => ({
  icon: {
    display: 'inline-flex',
    verticalAlign: 'bottom'
  },
});


const List = (props) => {
  const { classes, logs } = props;

  const levelIcon = (level) => {
    switch (level) {
      case 'error':
        return <ErrorIcon color="secondary"/>;
      case 'warn':
        return <WarnIcon color="primary"/>;
      default:
        return <InfoIcon/>;
    }
  }

  const listItems = _.map(logs, (item, index) => {
    return (
      <ListItem
        key={`log_${index}`}
        item={item}
      >
        <Typography component="p" variant="body1">
          <span className={classes.icon}>{levelIcon(item.level)}</span> {item.timestamp}
        </Typography>
        <Typography component="p" variant="body2">
          {item.message}
        </Typography>
      </ListItem>
    );
  });

  // render
  return (
    <Fragment>
      {logs.length > 0 ? listItems : ''}
    </Fragment>
  );
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
};

export default withStyles(styles)(List);
