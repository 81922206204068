// Dependencies
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import HomeIcon from '@material-ui/icons/Home';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HttpsIcon from '@material-ui/icons/Https';

// Auth
//import auth from '../../lib/auth';

// Components
import Container from './Container';
import ButtonScroll from './ButtonScroll';
import Logo from './Svg/Logo';
import Weather from './Weather';
import NavButton from './NavButton';


const styles = theme => ({
  bar: {
    backgroundColor: 'rgba(255,255,255,.95)'
  },
  container: {
    [theme.breakpoints.up('xs')] : {
      height: '50px',
    },
    [theme.breakpoints.up('sm')] : {
      height: '60px',
    }
  },
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    justifyContent: 'flex-end'
  },
  toTop: {
    width: '60px',
    height: '60px',
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    '& svg': {
      width: '40px',
      zIndex: 10
    }
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});


const NavBar = (props) => {

  const { classes } = props;
  const date = moment().format("dd MM/DD");

  return (
    <AppBar position="fixed" className={classes.bar}>
      <Container fixed className={classNames(classes.flex,classes.container)}>
        <div className={classes.flex}>
          <Button component={NavButton} to="/" exact>
            <HomeIcon/>
            Home
          </Button>
          <Button component={NavButton} to="/labs" exact>
            <BookmarkIcon/>
            Labs
          </Button>
          <Button component={NavButton} to="/wall" exact>
            <DashboardIcon/>
            <FormattedMessage id="buttons.wall" />
          </Button>
        </div>
        <ButtonScroll className={classes.toTop}>
          <Logo/>
        </ButtonScroll>
        <div className={classNames(classes.flex, classes.right)}>
          <Weather/>
          &nbsp;&nbsp;
          <Typography>{date}</Typography>
          <Fab size="small" component={NavButton} to="/login" exact>
            <HttpsIcon/>
          </Fab>
        </div>
        {/*
        {logged && (
          <Fab size="small" component={NavButton} to="/dashboard" exact>
            <HttpsIcon/>
          </Fab>
        )}
        */}
      </Container>
    </AppBar>
  )
}

export default withStyles(styles)(NavBar);
