// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 212.7 212.7" xmlSpace="preserve">
      <g>
        <path fill={color}  d="M200.9,0H11.7C5.3,0,0,5.3,0,11.7v189.2c0,6.5,5.3,11.7,11.7,11.7h101.9v-82.4H85.9V98.2h27.7V74.5
        	c0-27.5,16.8-42.4,41.3-42.4c11.7,0,21.8,0.9,24.8,1.3v28.7l-17,0c-13.3,0-15.9,6.3-15.9,15.6v20.5h31.8l-4.1,32.1h-27.6v82.3h54.2
        	c6.5,0,11.7-5.3,11.7-11.7V11.7C212.7,5.3,207.4,0,200.9,0z"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
