// Dependencies
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const styles = theme => ({
  root: {
    margin: '0 0 40px 0',
  }
});

// Form
class Form extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {};
    // Bind functions
    this.handleChange = this.handleChange.bind(this);
  }

  // custom functions

  handleChange(event) {
    const { changeApi } = this.props;
    const value = event.target.value;
    changeApi(value);
  }

  // render
  render() {
    const { classes, api } = this.props;

    return (
      <div className={classes.root}>
        <FormControl component="fieldset">
          <FormLabel component="legend">API</FormLabel>
          <RadioGroup
            name="api"
            value={api}
            onChange={this.handleChange}
            row
          >
            <FormControlLabel
              value="openrouteservice"
              control={<Radio color="secondary" />}
              label="OpenRouteService"
            />
            <FormControlLabel
              value="mapquest"
              control={<Radio color="secondary" />}
              label="MapQuest"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

// props defaults
Form.defaultProps = {
  api: 'mapquest',
};

// props validation
Form.propTypes = {
};

export default withStyles(styles)(Form);
