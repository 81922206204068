export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const AUTH_USER_BEGIN = 'AUTH_USER_BEGIN';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';

export const SEND_NEWSLETTER_BEGIN = 'SEND_NEWSLETTER_BEGIN';
export const SEND_NEWSLETTER_SUCCESS = 'SEND_NEWSLETTER_SUCCESS';
export const SEND_NEWSLETTER_ERROR = 'SEND_NEWSLETTER_ERROR';

export const LOAD_WEATHER = 'LOAD_WEATHER';
