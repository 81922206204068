// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';

// Components
import List from './List';
import ListTop from '../Global/ListTop';
import SnackBar from '../Global/SnackBar';
import Dialog from '../Global/Dialog';
import FormFilter from './FormFilter';

import CircularProgress from '@material-ui/core/CircularProgress';


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Logs';
    // Innit state
    this.state = {
      loading: true,
      logs: [],
      search: {}
    };
    // Bind functions
    this.loadLogs = this.loadLogs.bind(this);
    this.filter = this.filter.bind(this);
    this.clearLogs = this.clearLogs.bind(this);
    // create axios token
    this.cancelToken = CancelToken.source();
  }

  // custom functions

  loadLogs(values) {
    this.dialog.closeDialog();
    axios.post('/api/logs', values, {cancelToken: this.cancelToken.token })
      .then(res => {
        this.setState({ logs: res.data, search: values, loading: false });
      });
  }

  filter() {
    const { search } = this.state;
    const title = 'Filter Logs';
    const content = <FormFilter onSubmit={this.loadLogs} search={search} />;
    this.dialog.openDialog(title, content);
  }

  clearLogs() {
    axios.post('/api/clear_logs', {cancelToken: this.cancelToken.token })
      .then(res => {
        this.snackbar.openSnackBar('success', 'Logs clear!');
        this.loadLogs();
      })
      .catch((err) => {
        const error = err.response.data.message || err.message;
        this.snackbar.openSnackBar('error', error);
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadLogs();
  }

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { logs, loading } = this.state;

    const actionsTop = [
      {
        label: 'Filter',
        action: this.filter,
      },
      {
        label: 'Clear Logs',
        action: this.clearLogs,
      },
    ];

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} fullscreen={false} />
        <SnackBar ref={ref => (this.snackbar = ref)} />
        <ListTop
          title='Logs'
          actions={actionsTop}
        />
        {loading ? (
          <CircularProgress
            color="secondary"
            size={40}
          />
        ) : (
          <List logs={ logs }/>
        )}
      </Fragment>
    );
  }
}

export default Index;
