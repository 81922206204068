// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form, Field } from 'formik';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

import CreatableSelect from 'react-select/creatable';
import ImageInput from '../../Global/ImageInput';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  creatable: {
    margin: '3px 0 0',
    zIndex: 1000
  },
  checkbox: {
    height: '32px',
  }
});


// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit, model } = this.props;
    const tags = ['scrap','maps','graphs','utils'];

    // options
    const optionTags = _.map(tags, (item, key) => {
      return {
        label: item,
        value: item,
      };
    });

    // selected options
    let selectedTags = [];
    if (!_.isEmpty(model)) {
      const selected = model.tags;
      selectedTags = _.filter(optionTags, (item, key) => {
        if (selected.indexOf(item.value)>-1) {
          return item;
        }
        return false;
      });
      //console.log(selected,selectedTags);
    }

    return (
      <Fragment>
        <Formik
          initialValues={{
            title: _.get(model, 'title', ''),
            route: _.get(model, 'route', ''),
            tags: selectedTags,
            image: _.get(model, 'image', ''),
            text: _.get(model, 'text', ''),
            active: _.get(model, 'active', false)
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values,
              tags: values.tags.map(t => t.value)
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue,
            setFieldTouched,
            resetForm
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="route"
                    label="Route"
                    value={values.route}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText component="label">Tags</FormHelperText>
                  <CreatableSelect
                    name="tags"
                    placeholder="Tags"
                    options={optionTags}
                    value={values.tags}
                    onChange={(val) => { setFieldValue('tags',val); }}
                    onBlur={() => { setFieldTouched('tags'); }}
                    isClearable
                    isMulti
                    className={classes.creatable}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="image"
                    label="Image"
                    component={ImageInput}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText component="label">Text</FormHelperText>
                  <CKEditor
                    id="text"
                    editor={ClassicEditor}
                    data={values.text}
                    onChange={(e, editor) => setFieldValue("text", editor.getData())}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="active"
                        value="active"
                        className={classes.checkbox}
                        disableRipple={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.active}
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!dirty || isSubmitting}
                    className={classes.button}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!dirty}
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}

export default withStyles(styles)(FormComponent);
