// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

import { withStyles } from '@material-ui/core/styles';

// Components
import Container from '../Global/Container';

const styles = {
};


const DataSet = (props) => {
  const { data } = props;
  // render
  return (
    <Fragment>
      <section className="">
        <Container fixed>
          <ReactJson src={data} enableClipboard={false} />
        </Container>
      </section>
    </Fragment>
  );
}

DataSet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DataSet);
