// Dependencies
import React, { Fragment, Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Clear from '@material-ui/icons/Clear';

import Dialog from '../Global/Dialog';
import FileManager from '../FileManager/Index';


const styles = theme => ({
});


class ImageInput extends Component {
  // construct
  constructor(props) {
    super(props);
    // State
    this.state = {
      open: false,
    };
    // Bind functions
    this.open = this.open.bind(this);
    this.getSelected = this.getSelected.bind(this);
    this.clear = this.clear.bind(this);
  }

  // custom functions
  open(e) {
    const title = 'File Manager';
    const content = <FileManager maxSelect={1} getSelected={this.getSelected} />;
    this.dialog.openDialog(title, content);
  }

  getSelected(val) {
    const { field, form: { setFieldValue } } = this.props;
    setFieldValue(field.name, val[0]);
    this.dialog.closeDialog();
  }

  clear(e) {
    const { field, form: { setFieldValue } } = this.props;
    e.preventDefault();
    e.stopPropagation();
    setFieldValue(field.name, '');
  }

  // lifecycle methods

  // Render
  render() {
    const { label, field, form: { dirty, touched, errors }, ...other } = this.props;
    const errorText = errors[field.name]
    const hasError = dirty && touched[field.name] && errorText !== undefined

    const endButtons = () => {
      return (
        <InputAdornment position="end">
          {field.value && (
            <IconButton size="small" onClick={(e) => this.clear(e)}>
              <Clear />
            </IconButton>
          )}
          <IconButton size="small" onClick={(e) => this.open(e)}>
            <Visibility />
          </IconButton>
        </InputAdornment>
      )
    }

    return (
      <Fragment>
        <Dialog ref={ref => (this.dialog = ref)} />
        <TextField
          label={label}
          error={hasError}
          helperText={hasError ? errorText : ''}
          {...field}
          {...other}
          onClick={(e) => this.open(e)}
          InputProps={{
            readOnly: true,
            endAdornment: endButtons(),
          }}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ImageInput);
