// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import DesactiveIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import DownloadIcon from '@material-ui/icons/GetApp';
import RestoreIcon from '@material-ui/icons/Cached';
import FolderIcon from '@material-ui/icons/Folder';


const icons = {
  active: ActiveIcon,
  desactive: DesactiveIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  code: CodeIcon,
  download: DownloadIcon,
  restore: RestoreIcon,
  folder: FolderIcon
};


const styles = theme => ({
  button: {
    padding: '0 7px 0 5px',
    minHeight: '24px'
  },
  icon: {
    fontSize: '18px',
    margin: '0 2px 0 0'
  }
});


const ListItemAction = (props) => {
  const { classes, item, action } = props;
  const { label, icon, handleClick, buttonProps, iconProps } = action;
  const IconButton = (icon ? icons[icon] : '');

  // render
  return (
    <Button
      size="small"
      className={classes.button}
      onClick={handleClick.bind(this, item)}
      {...buttonProps}
    >
      {icon &&
        <IconButton
          className={classes.icon}
          {...iconProps}
        />
      }
      {label}
    </Button>
  )
}

ListItemAction.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired
};

export default withStyles(styles)(ListItemAction);
