// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

// Components
import Container from './Container';


const styles = theme => ({
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -10,
    marginRight: 10,
  },
  content: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  }
});

const SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction='up' {...props} ref={ref} />
));

class DialogCustom extends Component {
  // construct
  constructor(props, context) {
    super(props);
    // State
    this.state = {
      open: false,
      title: '',
      content: ''
    }
    // Bind functions
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  // custom functions

  openDialog(title, content) {
    this.setState({
      open: true,
      title: title,
      content: content
    });
  }

  closeDialog() {
    this.setState({ open: false });
  }

  // lifecycle methods

  // Render
  render() {
    const { classes, fullscreen } = this.props;
    const { open, title, content } = this.state;

    return (
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={this.closeDialog}
        TransitionComponent={SlideTransition}
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton className={classes.menuButton} onClick={this.closeDialog} color="inherit" aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container className={classes.content} fixed>
          {content}
        </Container>
      </Dialog>
    );
  }
}

// props validation
DialogCustom.defaultProps = {
  fullscreen: true,
};

DialogCustom.propTypes = {
  classes: PropTypes.object.isRequired,
  fullscreen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DialogCustom);
