// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';


class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    // Refs
    this.canvas = React.createRef();
    this.x = React.createRef();
    this.y = React.createRef();
    this.g = React.createRef();
    this.xAxis = React.createRef();
    this.xAxisCall = React.createRef();
    this.yAxis = React.createRef();
    this.yAxisCall = React.createRef();
  }

  // custom functions

  updateGraph() {
    const { data, width, height, margin } = this.props;

    /*
    const xscale = scaleBand();
    xscale.domain(data.map(d => d.year));
    xscale.padding(0.2);
    xscale.range([0, w]);

    const yscale = scaleLinear();
    yscale.domain([0, 100]);
    yscale.range([0, h]);
    const upd = node.selectAll('rect').data(data);
    upd.enter()
      .append('rect')
      .merge(upd)
      .attr('x', d => xscale(d.year))
      .attr('y', d => h - yscale(d.percent))
      .attr('width', xscale.bandwidth())
      .attr('height', d => yscale(d.percent))
      .attr('fill', 'black');
    */

  }

  initGraph() {
    const { width, height, margin } = this.props;
    const width_data = width-(margin[0]+margin[2]);
    const height_data = height-(margin[1]+margin[3]);

    // canvas
    this.canvas = d3.select(this.canvas);

    // init main group
    this.g = this.canvas.select(".graph");

    // init scales
    this.x = d3.scaleLinear().range([0, width_data]);
    this.y = d3.scaleLinear().range([height_data, 0]);

    // init axes and labels
    this.xAxisCall = d3.axisBottom(this.x).ticks(5);
    this.xAxis = this.canvas.select(".x_axis");

    this.yAxisCall = d3.axisLeft(this.y).ticks(4);
    this.yAxis = this.canvas.select(".y_axis");
  }

  // lifecycle methods

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateGraph();
    }
  }

  // render
  render() {
    const { width, height, margin } = this.props;
    const width_data = width - (margin[0]+margin[2]);
    const height_data = height - (margin[1]+margin[3]);

    return (
      <Fragment>
        <svg
          ref={el => this.canvas = el}
          viewBox={'0 0 ' + width + ' ' + height}
        >
          <rect
            width={width}
            height={height}
            fill="none"
          />
          <g
            id="graph"
            transform={`translate(${margin[0]}, ${margin[1]})`}
          >
            <rect width={width_data} height={height_data} fill="rgb(245,245,245)" />
            <g className="graph" transform={`translate(0, 0)`} />
            <g className="axis x_axis" transform={`translate(0, ${height_data})`}>
            </g>
            <g className="axis y_axis" transform={`translate(0, 0)`}>
              <text className="axis-title" transform={`rotate(-90) translate(-6, 16)`}>Y Scale</text>
            </g>
            <g className="grid-axis x_grid_axis" strokeDasharray="2,2" transform={`translate(0, 0)`} />
            <g className="grid-axis y_grid_axis" strokeDasharray="2,2" transform={`translate(${width_data}, 0)`} />
          </g>
        </svg>
      </Fragment>
    );
  }
}

// props defaults
Graph.defaultProps = {
  width:900,
  height: 450,
  margin:[30,30,30,30],
  data: []
};

// props validation
Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
