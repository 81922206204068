// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PeopleIcon from '@material-ui/icons/People';
import HealingIcon from '@material-ui/icons/Healing';
import AnnouncementIcon from '@material-ui/icons/Announcement';

// Components
import NavButton from '../../Global/NavButton';


const styles = theme => ({
  drawerPaper: {
    position: 'fixed',
    width: 240,
  },
  toolBar: theme.mixins.toolbar,
  listItemText: {
    padding: 0
  }
});


const items = {
  data: [
    {
      label: 'Portfolio',
      link: '/admin/works'
    },
    {
      label: 'Labs',
      link: '/admin/labs'
    },
    {
      label: 'Social Posts',
      link: '/admin/social_posts'
    },
    {
      label: 'Weather',
      link: '/admin/weather'
    },
    {
      label: 'Apartments',
      link: '/admin/apartments'
    },
    {
      label: 'Hosts',
      link: '/admin/apartment_hosts'
    }
  ],
  admin: [
    {
      label: 'Strings',
      link: '/admin/strings',
      icon: <TextFieldsIcon/>
    },
    {
      label: 'Uploads',
      link: '/admin/uploads',
      icon: <PermMediaIcon/>
    },
    {
      label: 'Users',
      link: '/admin/users',
      icon: <PeopleIcon/>
    },
    {
      label: 'Backups',
      link: '/admin/backups',
      icon: <StorageIcon/>
    },
  ],
  tools: [
    {
      label: 'Server Info',
      link: '/admin/dev',
      icon: <SettingsIcon/>
    },
    {
      label: 'DB Utils',
      link: '/admin/database',
      icon: <HealingIcon/>
    },
    {
      label: 'Logs',
      link: '/admin/logs',
      icon: <AnnouncementIcon/>
    },
  ]
};


const MenuItem = ({ link, icon, label, classes }) => {

  return (
    <ListItem button component={NavButton} to={link}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} className={classes.listItemText}/>
    </ListItem>
  )
}


const AppDrawer = (props) => {
  const { classes } = props;
  const { data, admin, tools } = items;

  // render
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolBar}/>
      <Divider />
      <List>
        <MenuItem
          label="Dashboard"
          link="/admin/"
          icon=<DashboardIcon />
          {...props}
        />
      </List>
      <Divider />
      <List>
        {data.map((item, index) =>
          <MenuItem
            key={`data_${index}`}
            label={item.label}
            link={item.link}
            icon={(item.icon ? item.icon : <FolderIcon/>)}
            {...props}
          />
        )}
      </List>
      <Divider/>
      <List component="nav">
        {admin.map((item, index) =>
          <MenuItem
            key={`data_${index}`}
            label={item.label}
            link={item.link}
            icon={(item.icon ? item.icon : <SettingsIcon/>)}
            {...props}
          />
        )}
      </List>
      <Divider/>
      <List component="nav">
        {tools.map((item, index) =>
          <MenuItem
            key={`data_${index}`}
            label={item.label}
            link={item.link}
            icon={(item.icon ? item.icon : <SettingsIcon/>)}
            {...props}
          />
        )}
      </List>
    </Drawer>
  );
}

// props defaults
AppDrawer.defaultProps = {
};

// props validation
AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppDrawer);
