// Dependencies
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import DashboardIcon from '@material-ui/icons/ArrowBackIos';

// Components
import Container from '../Global/Container';
import ButtonScroll from '../Global/ButtonScroll';
import NavButton from '../Global/NavButton';
import Logo from '../Global/Svg/Logo';


const styles = theme => ({
  bar: {
    height: '50px',
    backgroundColor: 'rgba(33,33,33,.95)',
    color: '#fff',
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%'
  },
  toTop: {
    width: '60px',
    height: '50px',
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    '& svg': {
      width: '35px',
      zIndex: 10
    }
  },
  btn: {
    height: '50px',
    padding: '0 15px',
    margin: '0 0 0 -10px'
  },
  icon: {
    fontSize: 20,
  },
  ripple: {
    fontSize: 20,
  }
});


const LabsViewBar = (props) => {
  const { classes } = props;

  // render
  return (
    <AppBar position="fixed" className={classes.bar}>
      <Container fixed className={classes.flex}>
        <ButtonBase className={classes.btn} component={NavButton} to="/labs" exact centerRipple>
          <DashboardIcon className={classes.icon}/>
          Labs
        </ButtonBase>
        <ButtonScroll className={classes.toTop}>
          <Logo color="#fff" />
        </ButtonScroll>
      </Container>
    </AppBar>
  );
}

export default withStyles(styles)(LabsViewBar);
