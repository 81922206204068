// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';
import Snake from './Snake';
import Food from './Food';


const styles = theme => ({
  game: {
    position: 'relative',
    margin: '50px auto',
    width: '400px',
    height: '400px',
    border: '2px solid #000',
  }
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      food: [6,8],
      snakeDots: [
        [0,0],
        [1,0]
      ]
    };
    // Bind functions
    this.loadData = this.loadData.bind(this);
  }

  // custom functions

  loadData() {
  }


  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;
    const { snakeDots, food } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Snake Game
            </Typography>
            <Typography variant="h3" component="h3">
              ...
            </Typography>
            <div className={classes.game}>
              <Snake snakeDots={snakeDots} />
              <Food dot={food} />
            </div>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state}/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
