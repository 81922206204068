import * as types from './actionTypes';
import axios from 'axios';

// Action creators
export function loadWeatherSuccess(weather) {
  return {type: types.LOAD_WEATHER, weather}
}

// Thunks
export function loadWeather() {
  return function(dispatch) {
    return axios.get('/api/weather/last')
      .then( res => {
        dispatch(loadWeatherSuccess(res.data));
      })
      .catch(err => {
        throw(err);
      });
  }
}
