// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// Components

class InfoButton extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      open: false,
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  // custom functions

  handleClick(e) {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  handleClickAway() {
    if (this.state.open) {
      this.setState({
        open: false,
      });
    }
  }

  // lifecycle methods

  // render
  render() {
    const { item } = this.props;

    const btnClass = classNames({
      'hexLink': true,
      'open': this.state.open
    });

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <button
          href="true"
          className={btnClass}
          ref={(c) => { this.node = c; }}
          onClick={this.handleClick}
        >
          <Fragment>
            <img src={item.icon} alt="logo"/>
            <h5>{item.title}</h5>
            <p>{item.slogan}</p>
          </Fragment>
        </button>
      </ClickAwayListener>
    );
  }
}

// props defaults
InfoButton.defaultProps = {
  item: {}
};

// props validation
InfoButton.propTypes = {
  item: PropTypes.object.isRequired
};

export default InfoButton;
