// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonBase from '@material-ui/core/ButtonBase';

// https://github.com/milosjanda/react-scroll-up/blob/master/scrollUp.jsx

// http://www.gizma.com/easing/
// t: current time, b: begin value, c: change value, d: duration
const easing = (t, b, c, d) => {
  return c*((t=t/d-1)*t*t*t*t + 1) + b; // quintic easing out
};

class ButtonScroll extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {};
    // custom vars
    this.data = {
      startTime: null,
      startValue: 0,
      changeValue: 0,
      rafId: null
    };
    // Bind functions
    this.handleClick = this.handleClick.bind(this);
    this.scrollStep = this.scrollStep.bind(this);
    this.stopScroll = this.stopScroll.bind(this);
  }

  // custom functions

  handleClick() {
    this.stopScroll();
    // scroll values
    this.data.startTime = Date.now();
    this.data.startValue = window.pageYOffset;
    this.data.changeValue = this.props.topPosition - window.pageYOffset;
    // animation
    this.data.rafId = window.requestAnimationFrame(this.scrollStep);
  }

  scrollStep() {
    const { startTime, startValue, changeValue } = this.data;
    const { duration } = this.props;

    const elapsed = Date.now() - startTime;

    const position = easing(elapsed, startValue, changeValue, duration);

    if (window.pageYOffset === 0 || elapsed >= duration ) {
      this.stopScroll()
    } else {
      window.scrollTo(0, position);
      this.data.rafId = window.requestAnimationFrame(this.scrollStep);
    }
  }

  stopScroll() {
    //clearInterval(this.scrollInterval);
    window.cancelAnimationFrame(this.data.rafId);
  }

  // lifecycle methods

  componentDidMount() {
    // Add all listeners which can start scroll
    window.addEventListener('wheel', this.stopScroll);
    window.addEventListener('touchstart', this.stopScroll);
  }

  componentWillUnmount() {
    // Remove all listeners
    window.removeEventListener('wheel', this.stopScroll);
    window.removeEventListener('touchstart', this.stopScroll);
  }

  // render
  render() {
    const { className, children } = this.props;

    return (
      <ButtonBase
        className={className}
        onClick={ () => { this.handleClick() }}
        centerRipple
      >
        {children}
      </ButtonBase>
    )
  }
}

// props defaults
ButtonScroll.defaultProps = {
  children: <span>to top</span>,
  className: 'to-top',
  duration: 400,
  topPosition: 0
};

// props validation
ButtonScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  topPosition: PropTypes.number.isRequired,
};

export default ButtonScroll;
