// Dependencies
import React from 'react';
import PropTypes from 'prop-types';


const Logo = (props) => {
  const { color } = props;

  return (
    <svg x="0px" y="0px" viewBox="0 0 732.6 530.5" xmlSpace="preserve">
      <g>
        <path fill={color} d="M196.7,56.4c1.6,6.5,2.6,11.3,3.9,16c0.2,0.9,1.3,2.1,2.1,2.2c0.7,0.1,1.8-1,2.3-1.9c2.5-4.2,4.9-8.4,8.1-14
          c1.3,7.4,2.4,13.4,3.4,19.3c0.4,2.3,0.5,4.6,1.1,6.8c1.6,5.3,4.2,8.9,10.5,8c1.8-0.3,3.7,0,5.9,0c0.3,1.3,0.6,2.6,1.1,4.5
          c1.9-1.7,3.4-3,4.8-4.3c0.1,0.4,0.2,0.7,0.3,1.1c2.5-0.5,5-1,7.5-1.4c1.7-0.2,3.3-0.2,5-0.3c0,1.8,0.2,3.7,0,5.5
          c-0.1,1-0.9,1.9-1.6,3.4c2.2-0.5,3.7-0.9,6.9-1.6c-2.6,4.2-4.6,7.4-6.9,11c2.1,1.7,4.8,3.8,7.4,5.9c2.9,2.3,5.4,0.5,7.5-1.2
          c2.9-2.4,5.9-4,7.9,0.2c1.6,3.3-1.2,5.5-3.9,7.2c-2.6,1.8-5.1,3.7-7,6.6c2.5-0.6,4.9-1.2,6.5-1.6c2.1,2,3.9,3.6,5.6,5.2
          c-1.4,1.9-2.9,3.9-4.5,6c7-1.6,9.4-8,13.9-12.1c-4.8-5.4-5-5.5,0-12.4c6.5-9.1,11.3-10.3,20-5.8c2.3,1.2,3.6,4.2,5.7,5.9
          c1.1,0.9,3.6,1.7,4.5,1.1c7.3-5.2,14.1-2.2,20.7,1c4.6,2.3,8.5,2.5,13.2,0.4c7.7-3.5,15.7-6.3,23.6-9.5c1-0.4,2.4-0.6,3-1.4
          c4.6-6.1,9-12.4,13.6-18.8c-6.2-5.6-12-3.6-18.2,0.9c-0.8-2.7-2.2-4.7-1.9-6.6c0.7-5.3-1.4-8.9-5.3-11.9c-0.1-0.1-0.2-0.2-0.4-0.4
          c-5.4-5.5-5.9-6.2-1-11.6c3.9-4.3,3.4-7.5,0.1-11.4c-1.1-1.3-1.7-3.2-2.7-4.6c-3.4-4.5-3.8-9-0.2-13.4c2.1-2.6,1.7-4-1.3-5.2
          c-3.1-1.3-3.3-3.3-1-5.7c0.6-0.6,1.4-1.3,1.5-1.9c0.1-5,3.9-4,6.1-2.9c3.8,1.9,4.9,0.1,7.3-2.5c6.8-7.3,15.5-12.1,25.7-12.6
          c2.8-0.1,5.9,1.5,8.6,2.9c6.7,3.3,14.1,5.7,18.9,12.1c0.2,0.3,0.5,0.5,0.5,0.7c2.4,8.4,5.9,16.8,6.8,25.4
          c1.2,10.6-4.6,19.2-12.8,25.7c-3.7,3-6.2,5.8-4.7,10.1c3.9,1,7.4,1.9,11.7,2.9c-5.7,9.1,2.1,12.3,5.6,16.9
          c10.2,13.1,21,25.6,31.3,38.6c2.3,2.9,3.7,6.5,5.1,10c3.3,7.8-0.3,14.1-5.1,19.8c-4.6,5.4-10.8,6.9-17.8,5.4
          c-1.8-0.4-4-0.4-5.5,0.4c-3.6,1.9-6.9,1.9-10.8,1.1c-4.7-0.9-9.5-0.6-14.3-0.9c-0.2,0.7-0.4,1.4-0.6,2c1.1,0.7,2.1,1.5,3.2,2.1
          c6.6,3,7.3,4.6,5.5,11.8c-1.5,5.7-2.8,11.5-4.3,17.9c1.9-0.3,2.5-0.2,2.7-0.5c7.6-10.7,19.9-12.6,31.1-16.4
          c8-2.7,16.3-4.9,24.6-6.2c13-2.1,25.4,0.2,36.2,8.3c5.4,4,10.7,8.2,15.6,12.7c2.3,2.1,4.1,5.1,5.6,8c3.8,7.5,9.6,11.1,17.9,11.1
          c3.3,0,6.6,1,9.9,1.5c0,0.5,0,0.9,0,1.4c-3.8,1.3-7.7,2.6-11.5,3.8c11.7,3.6,23.2-2.2,35.5,0.2c-1.6,2.1-2.5,3.2-4.2,5.4
          c3.9-1.4,6.8-2.4,10-3.5c0.3,1.5,0.6,2.9,0.9,4.1c19.1,1.3,30-13.4,44.7-22.5c-0.6,2-0.8,2.9-1.5,5.3c6.4-4.7,11.7-8.6,17.1-12.5
          c0.3,0.3,0.6,0.6,0.9,0.9c-1.7,5.4-3.4,10.8-5.3,17c3.1-1.1,5.1-1.8,6.7-2.4c3.8,6.7-0.9,10.5-3.5,15.5
          c-6.7,12.6-17.6,18.9-30.4,22.8c-3.8,1.2-8.1,0.8-12.8,1.2c1.7,1,2.9,1.8,5.7,3.6c-7.8,1.9-14.2,3.7-20.8,4.9
          c-4.7,0.8-9.5,0.8-14.3,1.1c-0.8,0.1-1.7,0.1-2.5,0c-8.5-0.8-16.2-3.7-23.1-8.8c-0.5-0.4-1.2-0.4-2.2-0.8c-0.2,1.5-0.4,3-0.7,4.7
          c-1.8-0.8-3.3-1.4-5.1-2.2c-0.3,1.2-0.6,2.2-0.7,2.9c-1.9,0.2-3.9,0.4-6.5,0.6c-1-0.7-2.8-1.9-5-3.5c-1.4,14.5-2.5,28.5-0.2,42.3
          c1.1,6.5,3.2,12.9,5.2,19.2c1.2,3.9,4.8,5.7,8.5,5.4c13.2-1,18.6,7.8,21.1,18.2c2.3,9.8,4.3,19.4,10.9,27.9
          c2.3,2.9,4.8,5.1,7.2,7.7c1.3,1.5,1.3,4.3,2,7.2c0.2,0,1.8,0.1,4.7,0.3c-1.3,2.2-2.4,4-3.3,5.6c2.5,0.9,5.6,2.1,9.5,3.6
          c-1.9,1.7-3.1,2.8-4.4,4c4.8,3.8,4.8,3.8-0.9,7.9c2.2,7.3,4.3,14.6,6.6,21.8c1.7,5.1,5.3,7.1,10.2,4.7c8.1-3.9,15.6-8.9,23.7-12.7
          c24.9-11.5,44.5-29.8,62.8-49.5c8.7-9.4,16.8-19.4,25.3-29c1.2-1.4,3.3-2.1,5.1-3.1c2.6,6.8-1.1,11-4.2,15.1
          c-10,13.2-20,26.3-30.4,39.2c-2.7,3.4-6.5,6.1-9.8,9c-2,1.8-4.4,3.3-6.2,5.3c-22.6,25.2-50.8,42.9-79.5,59.9
          c-25.4,15-52.6,25.9-81.2,33.2c-20.2,5.2-40.3,10.6-60.7,14.7c-15.4,3.1-31.1,4.5-46.7,6.2c-13.9,1.6-27.8,3.8-41.7,3.8
          c-16.1-0.1-32.2-2.2-48.2-3.7c-14-1.4-28-3.2-42-5c-13.3-1.7-26.9-0.4-40-4.8c-11.4-3.8-23.3-6-34.9-9.4
          c-18.6-5.4-37.4-10.2-55.5-17c-16.2-6.2-32.1-13.8-47.1-22.6c-16.3-9.5-32.2-20.1-46.9-31.9c-20-16-36.9-35.1-48.7-58.3
          c-2-3.9-4-7.8-5.8-11.8c-1-2.3-1.5-4.8-2.2-7.2c7.9-3.2,7.9-3,11.5,3.7c10.9,20.4,25.2,37.6,45.6,47.9c-8.5-13.8-17.4-28.1-26-42.6
          c-2.9-4.8-5.1-10.3-0.3-16.6c5.7,6.4,11.5,12.5,16.8,18.9c8.7,10.5,16.2,22.1,25.8,31.6s21,17.2,31.9,25.4
          c8.4,6.2,17.2,11.8,25.8,17.8c4.5,3.2,8.8,3.2,13.4-0.1c12.2-8.8,23.3-18.6,31.7-31.4c4-6,9.9-10.7,14.7-16.1
          c5.6-6.4,11.1-13,16.5-19.7c1.2-1.5,2.4-3.9,2.2-5.7c-1.1-9.4,3.6-16.7,7.9-24.3c3.6-6.4,6.5-13.1,9.8-19.7
          c2.1-4.2,1.6-8.3-0.6-12.1c-7.5-13.1-7.4-27.1-4.1-41.1c3.4-14.7-1.6-27.2-8.3-39.8c-5.5-10.5-10-21.5-14.9-32.4
          c-7.1,3.6-14.9,0.3-22.4-1.6c-13.1-3.4-25.8-8.3-34.7-19.1c-3.9-4.7-6.4-10.9-8.2-16.8c-0.9-2.9-1.9-4.6-4.2-5.8
          c-3.6-2-7.3-3.6-11-5.5c-5-2.5-5.5-5.3-2.5-10.1c0.7-1.1,0.4-3.5-0.4-4.5c-3.4-4.6-3.7-9.8-3.3-15c0.4-4.5,5.4-8.7,10.8-9.9
          c11.9-2.7,23.7-5.8,35.5-8.7c3.9-0.9,6.5-3.3,9.5-6.1c3.9-3.5,9.4-5.5,14.6-7.1c8.6-2.6,11.1-3.2,10.7-13.3c-0.2-5,1-10.1,2.1-15
          C192.2,60,194.5,58.7,196.7,56.4z M341.9,330.4c-3.1-1.3-4.5-2.2-6-2.5c-11.3-1.8-22.6-3.2-33.9-5.1c-15.8-2.6-28,2.7-37.5,15.5
          c-5.7,7.6-12.1,14.7-18.1,22.1c-4.4,5.5-8.3,11.4-12.8,16.8c-6.1,7.2-12.7,13.9-18.8,21.1c-5.1,6-9.7,12.4-14.7,18.4
          c-4.8,5.8-9.9,11.4-15.1,17.3c1.1,0.7,1.8,1,2.4,1.5c0.7,0.6,1.2,1.4,1.9,2.1c-4.6,2.4-6,5.5-4.2,8.2c0.9,1.3,1.3,3,2.2,5.3
          c0,0,1,0.5,2.1,1.1c-3.9,4.5-7.5,8.7-11.5,13.3c1.7,0.7,2.7,1.2,3.7,1.5c14,3.7,27.9,7.5,42,10.9c8.5,2.1,17.2,3.8,25.9,5
          c8.5,1.2,17.1,1.4,25.6,2.2c14.6,1.3,29.1,2.6,43.7,3.9c8.2,0.7,16.4,1.3,24.6,2.1c13.7,1.3,27.5,3.6,41.2,3.7
          c15.6,0.1,31.3-1.2,46.9-2.9c14.4-1.6,28.8-4.1,43-6.9c10.5-2,20.8-5.1,31.3-7.6c6.8-1.6,13.8-2.5,20.5-4.4
          c10.6-3.1,21.1-6.6,31.5-10.2c1.5-0.5,3-2,3.7-3.4c1.7-3.9,2.8-8,4.3-11.9c2.6-6.5,5.9-13,4.7-20.3c-0.8-5.3-1.9-10.6-3.3-15.8
          c-4.1-15.8-12.2-29.9-20-44c-1.8-3.3-4.7-5.1-9.4-4.6c-8.7,1-17.6,1.8-26.2,0.9c-11.3-1.1-21.7-5.1-28.2-15.5
          c-5.4-8.7-10.5-17.7-15.9-26.4c-4.7-7.4-9.7-8.8-17.6-5c-3.8,1.8-7.5,3.9-11,6.3c-15.6,10.5-32.4,17.1-51.6,15.8
          c-5.1-0.3-10.2-0.1-15.7-0.1c0,2.5,0.1,4.5,0,6.5c-0.2,5.1-2.7,9.1-7.6,10.1c-4.3,0.9-9.7,1.8-13.3,0c-5.5-2.7-10-0.5-15-0.2
          c-6.1,0.4-12.3,0.9-18.4,0.4c-4.2-0.3-5.2-3.7-2.9-7.3c4-6.2,10.9-8,16.8-11.4C334.3,335.1,337.5,333,341.9,330.4z M291.1,179.3
          c4.2-0.8,8.1-1.6,12.1-2.4c0.2,0.4,0.4,0.8,0.5,1.3c-2.6,2-5.2,4-8,6.1c4.4,1.1,8.5,2.2,12.8,3.3c-0.7,1.1-1.9,2.2-2,3.3
          c-0.2,1.8-0.1,5.3,0.4,5.4c2.4,0.5,5.7,0.9,7.4-0.3c1.8-1.3,2.5-4.5,3-6.9c1.1-5.4,1.6-11,2.7-16.4c1-4.6,4.1-7.4,8.9-7.8
          c4.9-0.4,7.4,2.8,9.4,6.7c2.4,4.7,4.9,9.3,7.7,14.7c5-6.4,9.8-12.2,14.3-18.3c4.2-5.7,4.1-6-2.4-10.3c-0.5-0.4-1-0.9-1.7-1.6
          c1.6-2.8,3.4-5.5,4.8-8.4c1.5-2.9,2.6-6,4.4-10c-7,2.8-12.6,5.1-18.2,7.1c-1.8,0.7-3.9,1.2-5.7,1c-6.6-0.8-13.2-2.1-19.9-2.8
          c-6.1-0.6-11.8-1.3-11.9-9.3c0-0.5-0.4-1-0.6-1.4c-6,1.8-15.6,12-24.5,25.7c3.3,4.1,3.5,10.8,0.2,16.3c2.9-0.6,5.3-1.2,9-2
          C292.9,174.7,292.2,176.5,291.1,179.3z M138.9,149.2c-3.4,16.5,26,35.2,51,28.8c-2.2-7.3-3.9-14.8-9.4-20.6
          c-0.7-0.8-2.1-1.7-2.9-1.5c-6.2,1.5-11.7-1.4-17.5-2.7C153.2,151.6,146.2,150.5,138.9,149.2z M290.4,135.2c0.4-0.2-1.2-1.2-3.2,1.4
          c-3.8,4.9-7.6,9.8-11.4,14.8c0.3,0.3,0.6,0.6,0.9,0.8C281.3,146.5,285.9,140.9,290.4,135.2z M640.3,242.2c-0.2-0.7-0.4-1.5-0.6-2.2
          c-7,1.9-13,6.1-19.5,9.1c0.2,0.6,0.5,1.1,0.7,1.7C627.4,248,633.8,245.1,640.3,242.2z M426.1,143.1c0.2,0.4,0.5,0.9,0.7,1.3
          c3.8,0,7.6,0,11.5,0c0.2-0.5,0.4-0.9,0.6-1.4c-2.3-1.3-4.6-3.6-6.9-3.7C430.1,139.2,428.1,141.8,426.1,143.1z"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#111',
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Logo;
