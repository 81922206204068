// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';
import Form from './Form';
import List from './List';

const styles = theme => ({
  content: {
    minHeight: '60px'
  }
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      result: {
        id_str: false,
        entities: false
      }
    };
    // Bind functions
    this.sendUrl = this.sendUrl.bind(this);
    this.reset = this.reset.bind(this);
    this.preview = this.preview.bind(this);
    this.download = this.download.bind(this);
  }

  // custom functions

  getTweetParts(url) {
    if (url) {
      axios.post(
        '/api/tweet/parts',
        { url },
        { cancelToken: new CancelToken(function executor(c) { cancel = c; }) }
      )
        .then(res => {
          this.setState({
            loading: false,
            result: res.data
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            result: {
              id_str: false,
              entities: []
            }
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  sendUrl(values) {
    // init values
    this.setState({
      loading: true,
      result: {
        id_str: false,
        entities: []
      }
    });
    // get parts
    if (values.url) {
      this.getTweetParts(values.url);
    }
  }

  reset() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on reset.');
    // Reset state values
    this.setState({
      loading: false,
      result: {
        id_str: false,
        entities: false
      }
    });
  }

  preview(item) {
    // open link in new tab in react router programmatically
    if (item.link) {
      const win = window.open(item.link, '_blank');
      win.focus();
    }
  }

  download(item) {
    if (item.link) {
      window.location = '/api/download?link=' + item.link;
    }
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled by the user on unmount.');
  }

  // render
  render() {
    const { classes } = this.props;
    const { loading, result } = this.state;
    let content = 'Introdueix una URL vàlida...';

    if (loading) {
      content = <CircularProgress
        color="secondary"
        size={40}
        className={classes.progress}
      />;
    } else if (result.id_str) {
      content = <List
        entities={result.entities}
        preview={this.preview}
        download={this.download}
      />;
    }

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Twitt Download
            </Typography>
            <Typography variant="h3" component="h3">
              Descarregar mèdia associat a un twitt
            </Typography>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography component="div">
              Utilització de la API de Twitter per obtenir i visualitzar el contingut d'imatge i video associat per a la seva descàrrega.
            </Typography>
            <Form
              onSubmit={this.sendUrl}
              onReset={this.reset}
            />
            <div className={classes.content}>
              {content}
            </div>
          </Container>
        </section>
        <DataSet data={result} title="State" />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
