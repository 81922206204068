// Dependencies
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  tags: {
  },
  btnTag: {
    margin: '0 10px 10px 0'
  },
  activeTag: {
    background: '#f00'
  },
  box: {
    position: 'relative',
    background: '#f4f4f4',
    height: '100px',
  },
  link: {
    display: 'flex',
    position: 'relative',
    border: '2px solid #111',
    width: '100%',
    height: '100%',
    padding: '15px',
  },
});


class List extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      selected_tags: [],
    };
  }

  // custom functions

  modelsTags() {
    const { tags } = this.props;
    return _.map(tags,'tag').sort();
  }

  clearTags() {
    this.setState({
      selected_tags: []
    });
  }

  handleTag(tag) {
    const { selected_tags } = this.state;

    if (selected_tags.indexOf(tag)<0) {
      // add if not exists
      this.setState( prevState => {
        return { selected_tags: [tag, ...prevState.selected_tags] };
      });
    } else {
      // remove if exists
      this.setState( prevState => {
        let array = [...prevState.selected_tags];
        const index = array.indexOf(tag);
        array.splice(index, 1);
        return { selected_tags: array };
      });
    }
  }

  // lifecycle methods

  componentDidMount() {
  }

  // render
  render() {
    const { classes, models, tags } = this.props;
    const { selected_tags } = this.state;

    // tags
    let tags_content = '';
    if (tags && tags.length > 0) {
      // tag btns
      const tag_values = this.modelsTags();
      const tag_items = _.map(tag_values, (item, key) => {
        // button class active
        const is_active = selected_tags.indexOf(item)!==-1?true:false;
        const btnClass = classNames(classes.btnTag, { [classes.activeTag]: is_active });
        //
        return (
          <button key={`tag_${key}`} onClick={() => this.handleTag(item)} className={btnClass}>{item}</button>
        );
      });
      // clear btn
      tag_items.push(<button key={`clear_tags`} onClick={() => this.clearTags()}>clear</button>);
      tags_content = <div className={classes.tags}>{tag_items}</div>;
    }

    // content
    let content = `No s'ha obtingut cap resultat...`;
    if (models && models.length > 0) {
      let models_view = models;
      // filter by tags
      if (selected_tags.length>0) {
        models_view = _.filter(models, (item, key) => {
          // tags included in item tags
          const diff = _.difference(selected_tags,item.tags);
          return diff.length>0?false:true;
        });
      }
      //
      if (models_view.length>0) {
        const items = _.map(models_view, (item, key) => {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12} key={`item_${key}`}>
              <div className={classes.box} data-aos="fade-up">
                {item.route ? (
                  <Link to={item.route} className={classes.link}>
                    <Typography className={classes.title}>
                      {item.title}
                    </Typography>
                    <Typography className={classes.title}>
                      {item.tags?item.tags.join(','):''}
                    </Typography>
                  </Link>
                ):(
                  <div className={classes.link}>{item.title}</div>
                )}
              </div>
            </Grid>
          );
        });
        content = <Grid container spacing={2}>{items}</Grid>;
      }
    }

    // render
    return (
      <Fragment>
        {tags_content}
        {content}
      </Fragment>
    );
  }
}

// props validation
List.defaultProps = {
  models: false
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
