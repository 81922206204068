// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import DataSet from '../DataSet';
import History from './History';

const styles = theme => ({
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      result: []
    };
    // Bind functions
    this.loadInfo = this.loadInfo.bind(this);
  }

  // custom functions

  loadInfo() {
    axios.get(
      '/api/weather/max_min_days',
      {
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        this.setState({ result: res.data.result });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadInfo();
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    //const { classes } = this.props;
    const { result } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Social Wall Stats
            </Typography>
            <Typography variant="h3" component="h3">
              Recollida de dades de les xarxes socials
            </Typography>
            <History
              result={result}
            />
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={result} />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
