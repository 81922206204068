// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';

// Components
import ListItem from '../Global/ListItem';


const List = (props) => {
  const { files, downloadBackup, restoreBackup, removeBackup } = props;

  const listItems = _.map(files, (item, key) => {

    const itemActions = [{
      label: 'Download',
      icon: 'download',
      handleClick: downloadBackup
    },{
      label: 'Restore',
      icon: 'restore',
      handleClick: restoreBackup
    },{
      label: 'Delete',
      handleClick: removeBackup,
      buttonProps: {
        color: "secondary"
      },
      icon: 'delete'
    }];

    return (
      <ListItem
        key={item.id}
        item={item}
        actions={itemActions}
      >
        <Typography component="p" variant="subtitle1">
          {item.name}
        </Typography>
        <Typography component="p" variant="body1">
          {item.dateText} - {item.size}
        </Typography>
      </ListItem>
    );
  });

  // render
  return (
    <Fragment>
      {files.length > 0 ? listItems : ''}
    </Fragment>
  )
}

List.propTypes = {
  files: PropTypes.array.isRequired,
  downloadBackup: PropTypes.func.isRequired,
  restoreBackup: PropTypes.func.isRequired,
  removeBackup: PropTypes.func.isRequired
};

export default List;
