// Dependencies
import React, { Fragment, Component } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import DateUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from '@material-ui/pickers';


const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit, search } = this.props;

    console.log('open form', search);

    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <Formik
            initialValues={{
              date_begin: _.get(search, 'date_begin', null),
              date_end: _.get(search, 'date_end', null),
              order: _.get(search, 'order', 'desc')
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              let payload = {
                ...values
              };
              onSubmit(payload);
              resetForm(values);
              setSubmitting(false);
            }}
            onReset={() => {
            }}
          >
            {({
              values,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleReset,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker
                      clearable
                      fullWidth
                      ampm={false}
                      value={values.date_begin}
                      onChange={(val) => { setFieldValue('date_begin', (val ? val.valueOf() : null )); }}
                      label="Date begin"
                      format="DD/MM/YYYY HH:mm:ss"
                      animateYearScrolling={false}
                      minDate={new Date('2010')}
                      maxDate={values.date_end?values.date_end:Date.now()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker
                      clearable
                      fullWidth
                      ampm={false}
                      value={values.date_end}
                      onChange={(val) => { setFieldValue('date_end',(val?val.valueOf():null)); }}
                      label="Date end"
                      format="DD/MM/YYYY HH:mm:ss"
                      animateYearScrolling={false}
                      minDate={values.date_begin?values.date_begin:new Date('2010')}
                      maxDate={Date.now()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="order"
                        onChange={(e,val) => { setFieldValue('order',val); }}
                        value={values.order}
                      >
                        <FormControlLabel
                          value="asc"
                          control={<Radio color="primary" />}
                          label="Asc."
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="desc"
                          control={<Radio color="primary" />}
                          label="Desc."
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty || isSubmitting}
                      className={classes.button}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!dirty}
                      onClick={handleReset}
                      className={classes.button}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </MuiPickersUtilsProvider>
      </Fragment>
    )
  }
}

export default withStyles(styles)(FormComponent);
