// Dependencies
import React from 'react';
import { NavLink } from 'react-router-dom';


const NavButton = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

export default NavButton;
