// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Dropzone from 'react-dropzone';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  dropzone: {
    border: '1px dashed #ddd',
    borderRadius: '5px',
    padding: '20px 30px',
  }
});

// Form
class FormComponent extends Component {
  // construct

  // custom methods

  // lifecycle methods

  // render
  render() {
    const { classes, onSubmit } = this.props;

    return (
      <Fragment>
        <Formik
          initialValues={{
            files: false,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let payload = {
              ...values
            };
            onSubmit(payload);
            resetForm(values);
            setSubmitting(false);
          }}
          onReset={() => {
          }}
        >
          {({
            values,
            dirty,
            submitForm,
            isSubmitting,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid>
                  <Dropzone
                    onDrop={async (acceptedFiles) => {
                      if (acceptedFiles.length > 0) {
                        await setFieldValue('files', acceptedFiles);
                        submitForm();
                      }
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()} className={classes.dropzone}>
                          <input {...getInputProps()} />
                          <p>Drop some file here, or click to select files</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fragment>
    )
  }
}


// props validation
FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormComponent);
