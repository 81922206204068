// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ReplyAll';


const styles = theme => ({
  item: {
    width: '150px',
    display: 'inline',
    padding: '0',
    margin: '0 20px 20px 0',
    position: 'relative',
  },
  iconWrap: {
    height: '150px',
    borderRadius: '10px',
    background: '#f4f4f4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    margin: '0 0 10px 0',
  },
  icon: {
    fontSize: '48px'
  },
  actions: {
    position: 'absolute',
  },
});


class ListItemDir extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
    };
    // Bind functions
    this.handleOpenFolder = this.handleOpenFolder.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  // custom functions

  handleOpenFolder(e) {
    const { route, openFolder } = this.props;
    e.stopPropagation();
    openFolder(route);
  }

  // drop

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    const { route, move } = this.props;
    const name = e.dataTransfer.getData('text');
    move(name, route);
  }

  // lifecycle methods


  // render
  render() {
    const { classes } = this.props;

    // render
    return (
      <div
        className={classes.item}
        onClick={(e) => this.handleOpenFolder(e)}
        onDragOver={(e) => this.onDragOver(e)}
        onDrop={(e) => this.onDrop(e)}
      >
        <div className={classes.iconWrap}>
          <BackIcon  className={classes.icon} />
        </div>
        <Typography component="p" variant="body2">..</Typography>
      </div>
    )
  }
}

// props validation
ListItemDir.defaultProps = {
};

ListItemDir.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  openFolder: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListItemDir);
