// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import Portal from '@material-ui/core/Portal';


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  content: {
    flexWrap: 'nowrap',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function SnackBarContentCustom(props) {
  const { classes, onClose, variant, message, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes.content,classes[variant])}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackBarContentCustom.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  message: PropTypes.node,
};

const SnackBarContentWrapper = withStyles(styles)(SnackBarContentCustom);

//let openSnackBarFn;

class SnackbarCustom extends Component {
  // construct
  constructor(props) {
    super(props);
    // Vars
    this.container = document.body;
    this.queue = [];
    // State
    this.state = {
      open: false,
      variant: '',
      message: ''
    }
    // Bind functions
    this.openSnackBar = this.openSnackBar.bind(this);
    this.processQueue = this.processQueue.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }

  // custom functions

  openSnackBar(variant, message) {
    // add queue
    this.queue.push({
      id: new Date().getTime(),
      variant: variant,
      message: message,
    });
    // process queue
    if (this.state.open) {
      this.setState({ open: false });
      //code before the pause
      setTimeout(() => {
        //do what you need here
        this.processQueue();
      }, 100);
    } else {
      this.processQueue();
    }
  }

  processQueue() {
    if (this.queue.length > 0) {
      // get nex queued
      const queue = this.queue.shift();
      // update state
      this.setState({
        open: true,
        id: queue.id,
        variant: queue.variant,
        message: queue.message
      });
    }
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  }

  handleExited() {
    this.processQueue();
  }

  // lifecycle methods

  // Render
  render() {
    const { open, id, variant, message } = this.state;

    return (
      <Portal container={this.container}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={2500}
          transitionDuration={100}
          onClose={this.handleClose}
          onExited={this.handleExited}
        >
          <SnackBarContentWrapper
            key={id}
            onClose={this.handleClose}
            variant={variant}
            message={message}
          />
        </Snackbar>
      </Portal>
    );
  }
}

/*
export function openSnackBar(variant, message) {
  openSnackBarFn(variant, message);
}
*/

export default SnackbarCustom;
