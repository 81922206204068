// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Pixel from './Pixel';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';


const styles = theme => ({
  svg: {
    margin: '30px 0',
    height: '450px'
  }
});


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      image: '/img/4.png',
    };
    // Bind functions
    this.updateImage = this.updateImage.bind(this);
  }

  // custom functions

  updateImage(image) {
    this.setState({ image: image });
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;
    const { image } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Pixel Image
            </Typography>
            <Typography variant="h3" component="h3">
              D3 pixel image + export vis
            </Typography>
            <div className={classes.svg}>
              <FullScreen>
                <Pixel
                  image={image}
                  updateImage={this.updateImage}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} title="State"/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
