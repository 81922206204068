// Dependencies
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import HistoryGraph from './HistoryGraph';


const styles = theme => ({
  root: {
  },
  title: {
    flex: 1,
    lineHeight: '56px'
  },
  graph: {
  },
  wrapper: {
    maxWidth: '700px',
    margin: '0 auto'
  },
});


class History extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      anchorEl: null,
    };
    // Bind functions
  }

  // custom functions

  // lifecycle methods

  // render
  render() {
    const { classes, result, total_price } = this.props;

    // render
    return (
      <Fragment>
        <Typography variant="h5" component="h5">
          History
        </Typography>
        <div>Grafica lineal totals i x separat xxss {total_price} --> Stacked bars</div>
        <div className={classes.graph}>
          <div className={classes.wrapper}>
            <HistoryGraph
              data={result.data}
            />
          </div>
        </div>
        <div>Grafica % xxss / type (none, image, video, link) / repost --> 3 Pie charts</div>
      </Fragment>
    )
  }
}

// props validation
History.defaultProps = {
  result: [],
  total_price: 0
};

History.propTypes = {
  classes: PropTypes.object.isRequired,
  result: PropTypes.array.isRequired,
  total_price: PropTypes.number.isRequired
};

export default withStyles(styles)(History);
