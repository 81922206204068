// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet-draw';

import { withStyles } from '@material-ui/core/styles';

// styles
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

// L.Icon.Default brings a wrong image url
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowSize: [41, 41], // size of the shadow
  shadowAnchor: [12, 41], // the same for the shadow
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  tooltipAnchor: [10, -21], // point from which the tooltip should open relative to the iconAnchor
});
L.Marker.prototype.options.icon = DefaultIcon;


const styles = {
  map: {
    width: '100%',
    height: '100%'
  }
};


class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  initMap() {
    const { center, zoom, updateData } = this.props;

    // Tile layers -- https://leaflet-extras.github.io/leaflet-providers/preview/
    const layerMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '' });
    const layerImaginary = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: '' });

    // Create map
    this.map = L.map('map', {
      center: [center.lat, center.lng + 0.015],
      zoom: zoom,
      minZoom: 8,
      maxZoom: 18,
      scrollWheelZoom: false,
      layers: [ layerMap ]
    });

    // Add layer controller
    this.layersCtrl = L.control.layers(
      {
        'Map': layerMap,
        'Image': layerImaginary,
      },
      {
      },
      {
        hideSingleBase: true,
        autoZIndex: false,
        position: 'bottomleft'
      }
    ).addTo(this.map);

    // draw
    var drawnItems = new L.FeatureGroup();
    this.map.addLayer(drawnItems);

    var drawControl = new L.Control.Draw({
      draw: {
        polygon: {
          shapeOptions: {
            color: '#3498db'
          },
        },
        polyline: {
          shapeOptions: {
            color: '#3498db'
          },
        },
        rect: {
          shapeOptions: {
            color: '#3498db'
          },
        },
        circle: {
          shapeOptions: {
            color: '#3498db'
          },
        },
        marker: {
          icon: DefaultIcon
        },
        circlemarker: false
      },
      edit: {
        featureGroup: drawnItems,
        selectedPathOptions: {
          color: '#fe57a1',
          fillColor: '#fe57a1',
        }
      }
    });
    this.map.addControl(drawControl);

    // Map actions
    this.map.on('draw:created', function (e) {
      const layer = e.layer;
      drawnItems.addLayer(layer);
      // Extract GeoJson from featureGroup
      const shapes = drawnItems.toGeoJSON();
      updateData(shapes);
    });

    this.map.on('draw:edited', function (e) {
      // Extract GeoJson from featureGroup
      const shapes = drawnItems.toGeoJSON();
      updateData(shapes);
    });

    this.map.on('draw:deleted', function (e) {
      // Extract GeoJson from featureGroup
      const shapes = drawnItems.toGeoJSON();
      updateData(shapes);
    });
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.full !== prevProps.full) {
      console.log('resize');
      this.map.invalidateSize();
    }
  }

  componentWillUnmount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        id="map"
        className={classes.map}
      >
      </div>
    );
  }
}

// props defaults
Map.defaultProps = {
  center: {
    lat: 41.981651,
    lng: 2.823610
  },
  zoom: 13,
};

// props validation
Map.propTypes = {
  classes: PropTypes.object.isRequired,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default withStyles(styles)(Map);
