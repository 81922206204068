// Dependencies
import React, { Component, Fragment } from 'react';
import axios, { CancelToken } from 'axios';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Components
import ContactForm from './ContactForm';

class Contact extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      process: false,
      error: false,
      success: false
    };
    // create token for cancel axios request
    this.cancelToken = CancelToken.source();
    // Bind functions
    this.sendForm = this.sendForm.bind(this);
  }

  // custom methods

  sendForm(values) {
    // remove error
    this.setState({ error: false });
    // send mail
    if (values) {
      axios.post('/api/send_mail', values, { cancelToken: this.cancelToken.token })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  // lifecycle methods

  componentWillUnmount() {
    // Cancel ajax
    this.cancelToken.cancel();
  }

  // render
  render() {
    const { error, result } = this.state;

    let content;
    if (result) {
      content = result;
    } else {
      content = <ContactForm onSubmit={this.sendForm} />;
      if (error) {
        content = content + <span>error...</span>;
      }
    }

    return (
      <Fragment>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Typography variant="h6" color="inherit">
              Contact
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h6" color="inherit">
              Contact
            </Typography>
            {content}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default Contact;
