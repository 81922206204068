// Dependencies
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import RepeatIcon from '@material-ui/icons/Repeat';
import PublishIcon from '@material-ui/icons/Publish';

import LogoLinkedIn from '../Global/Svg/LogoLinkedIn';


const styles = theme => ({
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 0 10px 0'
  },
  user: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatar: {
    margin: '0 10px 0 0',
    background: '#f4f4f4'
  },
  btn: {
    display: 'flex',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    overflow: 'visible',
    textDecoration: 'none',
    border: 0,
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: '100%',
      maxHeight: '32px',
      maxWidth: '32px',
      zIndex: 10
    }
  },
  bottom: {
    margin: '10px 0 0',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: '14px',
    margin: '-1px 5px 0 0'
  }
});


const BoxLinkedIn = (props) => {
  const { classes, item } = props;

  const date_post = moment(item.date_post).format('DD-MM-YYYY');
  const date_original = item.is_repost?moment(item.date_original).format('DD-MM-YYYY'):null;
  const urlUser = `https://twitter.com/${item.username}`;

  // render
  return (
    <Fragment>
      <div className={classes.top}>
        <ButtonBase component="a" target="_blank" href={urlUser} rel="noopener noreferrer" centerRipple>
          <div className={classes.user}>
            <Avatar alt="Name" src={item.profile_pic} className={classes.avatar}/>
            <Typography variant="body2" className={classes.title}>
              <strong>{item.name}</strong><br/>@{item.username}
            </Typography>
          </div>
        </ButtonBase>
        <ButtonBase className={classes.btn} component="a" target="_blank" href={item.permalink} rel="noopener noreferrer" centerRipple>
          <LogoLinkedIn color="#4875B4"  className={classes.logo}/>
        </ButtonBase>
      </div>
      <Typography variant="body1" className={classes.title} dangerouslySetInnerHTML={{__html: item.text_html}}>
      </Typography>
      <div className={classes.features}>
      </div>
      <div className={classes.bottom}>
        {date_original ? (
          <Fragment>
            <RepeatIcon className={classes.icon}/>
            <Typography variant="subtitle2" className={classes.date}>
              {date_post}
            </Typography>
            &nbsp;&nbsp;
            <PublishIcon className={classes.icon}/>
            <Typography variant="subtitle2" className={classes.date}>
              {date_original}
            </Typography>
          </Fragment>
        ):(
          <Fragment>
            <PublishIcon className={classes.icon}/>
            <Typography variant="subtitle2" className={classes.date}>
              {date_post}
            </Typography>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

// props validation
BoxLinkedIn.defaultProps = {
  item: {}
};

BoxLinkedIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BoxLinkedIn);
