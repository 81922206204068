// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';

// Components
import ButtonScroll from '../../Global/ButtonScroll';
import NavButton from '../../Global/NavButton';

// Assets
import logo from '../../../assets/img/icon-logo.svg';

// Auth
//import auth from '../../lib/auth';


const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -10,
    marginRight: 10,
  },
});

const AdminBar = (props) => {

  const { classes } = props;
  const date = moment().format("dd MM/DD");

  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar>
        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Typography className={classes.flex} variant="h6" color="inherit">
          <FormattedMessage id="admin.title" />
        </Typography>
        <ButtonScroll>
          <img src={logo} alt="logo" />
        </ButtonScroll>
        <Typography variant="button" color="inherit">
          {date}
        </Typography>
        <Button color="inherit" component={NavButton} to="/" exact>
          <HomeIcon/>
          Home
        </Button>
        <Button color="inherit" component={NavButton} to="/admin" exact>
          <AppsIcon/>
          Admin
        </Button>
        {/*
        {logged && (
          <Link to="/dashboard">Dashboard</Link>
        )}
        */}
      </Toolbar>
    </AppBar>
  )
}

// props validation
AdminBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminBar);
