// Dependencies
import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  slide: {
    color: '#fff',
    height: '220px',
    padding: '30px',
    backgroundColor: '#eee',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    zIndex: 1
  },
  text: {
    position: 'relative',
    fontSize: '30px',
    lineHeight: '30px',
  },
  price: {
    display: 'block',
    margin: '5px 0 0',
  }
};


class PriceSlider extends Component {
  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {};
    // Bind functions
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes, data } = this.props;

    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      adaptiveHeight: true,
      centerPadding: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    //const top_items = data.slice(10);
    const top_items = _.shuffle(_.take(_.orderBy(data, ['properties.price'], ['desc']), 15));
    const listItems = _.map(top_items, (item, key) => {
      return (
        <div key={'slide-'+key}>
          <div className={classes.slide} style={{ backgroundImage: `url("${item.properties.image}")` }}>
            <div className="img_black"></div>
            <div className={classes.text}>
              <span>{item.properties.name}</span>
              <span className={classes.price}>{item.properties.price}€</span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <Slider {...settings}>
          {data.length > 0 ? listItems : ''}
        </Slider>
      </Fragment>
    );
  }
}

// props validation
PriceSlider.defaultProps = {
  data: []
};

PriceSlider.propTypes = {
};

export default withStyles(styles)(PriceSlider);
