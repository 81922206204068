// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Map from './Map';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';

const styles = theme => ({
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      result: []
    };
    // Bind functions
    this.loadInfo = this.loadInfo.bind(this);
  }

  // custom functions

  loadInfo() {
    axios.get(
      '/api/opendata/load_data',
      {
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        this.setState({ result: res.data.result });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadInfo();
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    //const { classes } = this.props;
    const { result } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              {result.points?result.points.length:0} pisos turístics
            </Typography>
            <Typography variant="h3" component="h3">
              Concentració en sectors propers al Barri Vell de Girona
            </Typography>
            <div className="map">
              <FullScreen>
                <Map
                  points={result.points}
                  barris={result.barris}
                  sectors={result.sectors}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography component="div">
            Les dades es recullen del portal de dades obertes de l'Ajuntament de Girona <a href="http://www.girona.cat/opendata/dataset" target="_blank" rel="noopener noreferrer">girona.cat/opendata/dataset</a>:<br/>
            <ul>
            <li>Límits geogràfics del municipi, barris i sectors en format SHP. Conversió a GeoJSON amb QGIS.</li>
            <li>Llistat d'activitats econòmiques en format CSV. Les dades s'han parsejat, manipulat i validat per guardar-ne la seva versió modificada en una base de dades <strong>MongoDB</strong>. Abans de guardar cadascuna de les activitats econòmiques se la ha  relacionat amb un barri i sector.</li>
            <li>Al filtrar per un únic tipus d'activitat econòmica i veure les dades ampliades sobre el mapa veiem que existeixen punts duplicats, caldria validar bé les dades.</li>
            </ul>
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
            <Typography component="div">
            Les dades es visualitzen sobre un mapa amb diferents capes i nivells de visualització:
            <ul>
            <li>Mapa de calor segons la posició dels habitatges.</li>
            <li>Mapa en escala de color segons barris i sectors de la ciutat.</li>
            <li>Marcadors i clústers per a una millor navegació, en l'últim nivell de zoom desapareixen els clústers per poder visualitzar tots els marcadors i la informació de cadascún.</li>
            </ul>
            </Typography>
          </Container>
        </section>
        <DataSet data={result} />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
