import * as types from './actionTypes';
import projectsApi from '../../api/projectsApi';

// Action creators
export function createProject(project) {
  return {type: types.CREATE_PROJECT, project}
}

export function loadProjectsSuccess(projects) {
  return {type: types.LOAD_PROJECTS_SUCCESS, projects}
}

// Thunks
export function loadProjects() {
  return function(dispatch) {
    return projectsApi.getAll().then(projects => {
      dispatch(loadProjectsSuccess(projects));
    }).catch(error => {
      throw(error);
    });
  }
}
