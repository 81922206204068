// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Components
import Container from '../../Global/Container';
import Svg from './Svg';
import DataSet from '../DataSet';
import FullScreen from '../FullScreen';

const styles = theme => ({
  svg: {
    margin: '30px 0',
    height: '450px'
  }
});

const data = [{
  id: 1,
  text: 'Pharetra recusandae ullamcorper? Aliquet? Dolore earum! Nihil perspiciatis, repellendus architecto penatibus vitae? Massa torquent placerat anim, aperiam netus alias. Temporibus mattis. Orci sed reprehenderit, laudantium egestas porta, do, dapibus aliquip parturient senectus risus. Corporis, aliquam diam, venenatis, voluptatibus, optio irure. Quidem ab mauris dolores dolore nec. Perspiciatis nibh, quas sociosqu.'
},{
  id: 2,
  text: 'Imperdiet commodo phasellus sapien penatibus, eaque! Eleifend a, quas, aliquet dolore provident odit similique, diamlorem perferendis expedita in sodales vitae occaecat cubilia ultricies in! Excepturi facere pharetra, amet atque tempor reiciendis velit venenatis, vivamus quod, consequat, temporibus phasellus voluptate accusamus labore id, tortor proident, veniam doloremque, etiam molestie? Per vitae.'
},{
  id: 3,
  text: 'Mollitia velit deleniti condimentum nisl distinctio. Diamlorem quis, curae unde, ipsa phasellus fermentum malesuada, leo, senectus maiores debitis aspernatur! Ea. Ullam autem rutrum. Aliquid dictum etiam nonummy cupidatat. Lectus. Conubia fringilla arcu lectus consectetuer illo anim perferendis felis, cursus irure, interdum ratione, accusantium fuga nostrum reprehenderit, nostrud ab class laboris.'
},{
  id: 4,
  text: 'Repellat accusantium reprehenderit fringilla deserunt per eleifend explicabo massa fames lorem architecto tenetur risus. Sapien tortor tempus cubilia magna sapiente, rerum? Eu purus do, ultricies! Suspendisse, eligendi nihil augue adipisicing eget per. Hic! Orci pulvinar vitae repellendus fames! Sapien aliquet, augue illum. Maiores aut vitae aliqua voluptates metus, est, ultrices.'
},{
  id: 5,
  text: 'Explicabo, alias mi eveniet, voluptas ut non id, quod porttitor, quas quisque! Architecto placeat diam, nullam, error. Aperiam at magni, accusamus per interdum, urna! Scelerisque dolorum. Voluptatum, architecto nulla cillum eget curabitur quo voluptas exercitationem imperdiet! Habitasse aliquam nostra. Per diamlorem accusantium esse, perferendis, interdum porro, possimus cubilia animi scelerisque.'
},{
  id: 6,
  text: 'Occaecati laborum, rhoncus convallis suspendisse nostra! Nulla interdum luctus. Minima dis necessitatibus, aliquip faucibus modi officia, leo, purus impedit velit veniam corporis asperiores taciti cum soluta quo! Occaecati dapibus vel mauris integer quidem vulputate porro risus eu numquam pretium temporibus! Error suscipit maiores, itaque fermentum, vel voluptatibus recusandae alias wisi.'
}];


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      open: false,
      dialogTitle: 'Button x',
      dialogText: 'Text x'
    };
    // Bind functions
    this.triggerAction = this.triggerAction.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // custom functions

  triggerAction(action, value) {
    const val = _.find(data, ['id', parseInt(value)]);
    if (val) {
      const title = `Button ${val.id}`;
      const text = val.text;
      this.setState({ open: true, dialogTitle: title, dialogText: text });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  // lifecycle methods

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  // render
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{this.state.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              SVG Browser
            </Typography>
            <Typography variant="h3" component="h3">
              Navegar per un svg i disparar accions des de la capa vectorial.
            </Typography>
            <div className={classes.svg}>
              <FullScreen>
                <Svg
                  triggerAction={this.triggerAction}
                />
              </FullScreen>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} title="SVG App Data"/>
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
