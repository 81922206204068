// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import {isMobile} from 'react-device-detect';

// Components
import FormLogin from './FormLogin';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import LockIcon from '@material-ui/icons/LockOutlined';
import Button from '@material-ui/core/Button';

// Auth
import auth from '../../../lib/auth';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
});


class Index extends Component {
  // construct
  constructor(props) {
    super(props);
    document.title = 'Login';
    // Innit state
    this.state = {
      error: false
    };
    this.popup = null
    // Bind functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginFacebook = this.loginFacebook.bind(this);
    this.loginTwitter = this.loginTwitter.bind(this);
    this.ensureLogged = this.ensureLogged.bind(this);

    // redirect if logged
    const { history, location } = props;
    const { from } = _.merge({}, { from: { pathname: '/admin' }}, location.state );

    console.log(location.state);
    localStorage.fromLogin = JSON.stringify(from);

    if (auth.loggedIn()) {
      console.log(from);
      return history.replace(from);
    }
  }

  // custom methods
  handleSubmit(values) {
    const { history } = this.props;

    const username = _.get(values, 'email', false);
    const password = _.get(values, 'password', false);

    this.setState({ error: false });

    // check is user
    auth.login(username, password, (result, error) => {
      if (result) {
        const from =  JSON.parse(localStorage.fromLogin);
        delete localStorage.fromLogin;
        return history.replace(from);
      }
      this.setState({ error: error.status + ' - ' + error.message });
    });
  }

  openPopup(url) {
    // mobil?
    if (isMobile) {
      // open window
      return window.open(`${url}`);
    } else {
      // popup sizes
      const width = 600, height = 600;
      const left = (window.innerWidth / 2) - (width / 2);
      const top = (window.innerHeight / 2) - (height / 2);
      // open popup
      return window.open(`${url}`, 'loginPopup',
        `toolbar=no, location=no, directories=no, status=no, menubar=no,
        scrollbars=no, resizable=no, copyhistory=no, width=${width},
        height=${height}, top=${top}, left=${left}`
      );
    }
  }

  loginFacebook(e) {
    e.preventDefault();
    e.stopPropagation();
    this.popup = this.openPopup('/auth/facebook')
  }

  loginTwitter(e) {
    e.preventDefault();
    e.stopPropagation();
    this.popup = this.openPopup('/auth/twitter')
  }

  ensureLogged() {
    const { history, location } = this.props;
    const { from } = _.merge({}, { from: { pathname: '/admin' }}, location.state );

    if (this.popup) this.popup.close();

    auth.ensureLogged((result) => {
      if (result) {
        history.replace(from);
      }
    });
  }

  // lifecycle methods
  componentDidMount() {
    // add listener
    window.addEventListener('message', this.ensureLogged);
  }

  componentWillUnmount() {
    // remove the same listener
    window.removeEventListener('message', this.ensureLogged);
  }

  // render
  render() {
    const { classes, intl } = this.props;
    const { error } = this.state;

    return (
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <div className={classes.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.loginFacebook}
            fullWidth
          >
            {intl.formatMessage({ id: 'login.facebook' })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.loginTwitter}
            fullWidth
          >
            {intl.formatMessage({ id: 'login.twitter' })}
          </Button>
        </div>
        <FormLogin
          onSubmit={this.handleSubmit}
          error={error}
        />
        <div className={classes.text}>
          <Typography variant="caption" align="center">
            {intl.formatMessage({ id: 'login.text' })}
          </Typography>
          <Typography variant="caption" align="center">
            <Link to="/recover_password">{intl.formatMessage({ id: 'login.link_recover' })}</Link>
          </Typography>
        </div>
      </Paper>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withStyles(styles)(injectIntl(Index));
