// Dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components
import Container from '../../Global/Container';
import Actual from './Actual';
import Graph from './Graph';
import DataSet from '../DataSet';

const styles = theme => ({
  actual: {
    height: '160px',
    margin: '30px 0 0'
  },
  graph: {
    height: '300px',
    margin: '0 0 30px'
  },
  wrap: {
    maxWidth: '900px',
    margin: '0 auto'
  }
});

// axios cancel
let cancel;


class Index extends Component {

  // construct
  constructor(props) {
    super(props);
    // Innit state
    this.state = {
      last: {},
      days: []
    };
    // Bind functions
    this.loadInfo = this.loadInfo.bind(this);
  }

  // custom functions

  loadInfo() {
    axios.get(
      '/api/weather/lab_data',
      {
        cancelToken: new CancelToken( (c) => { cancel = c; })
      }
    )
      .then(res => {
        this.setState({
          last: res.data.result.last[0],
          days: res.data.result.days,
        });
      });
  }

  // lifecycle methods

  componentDidMount() {
    this.loadInfo();
  }

  componentWillUnmount() {
    // Cancel ajax
    if (typeof cancel === 'function') cancel('Operation canceled on unmount');
  }

  // render
  render() {
    const { classes } = this.props;
    const { last, days } = this.state;

    return (
      <Fragment>
        <section className="lab">
          <Container fixed>
            <Typography variant="h1" component="h1">
              Temperatura en Santiago
            </Typography>
            <Typography variant="h3" component="h3">
              Temperatura actual i històric de variació tèrmica
            </Typography>
            <div className={classes.wrap}>
              <div className={classes.actual}>
                <Actual
                  temp={last}
                />
              </div>
              <div className={classes.graph}>
                <Graph
                  data={days}
                />
              </div>
            </div>
            <Typography variant="h5" component="h5">
              Dades
            </Typography>
            <Typography>
              Mitjançant un cron (un script que s'executa periòdicament en el servidor) es recullen automàticament diferents valors climatològics de la ciutat de Santiago de Chile a través de la API de <a href="https://openweathermap.org/" target="_blank" rel="noopener noreferrer">OpenWeatherMap</a> i es guarden en una base de dades <strong>MongoDB</strong> per al seu posterior anàlisis.
            </Typography>
            <Typography variant="h5" component="h5">
              Visualització
            </Typography>
            <Typography>
              Les dades d'oscil·lació tèrmica es mostren en una gràfica que ens permet veure i consultar de forma còmoda els valors diaris observats.
            </Typography>
          </Container>
        </section>
        <DataSet data={this.state} />
      </Fragment>
    );
  }
}

// props validation
Index.defaultProps = {
};

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
