// Dependencies
import _ from 'lodash';


/**
 * Flatten objet to dot notation
 */

export function flatten(obj, prefix) {

  var propName = (prefix) ? prefix + '.' :  '',
  ret = {};

  for(var attr in obj){

    if (_.isArray(obj[attr])) {
      ret[attr] = obj[attr].join(',');
    } else if(typeof obj[attr] === 'object') {
      _.extend(ret, flatten(obj[attr], propName + attr));
    } else{
      ret[propName + attr] = obj[attr];
    }
  }

  return ret;
}


/**
 * Time ago
 */

export function timeAgo(seconds) {

  let interval = 0;

  if (seconds && seconds>0) {

    interval = Math.floor(seconds / 31536000);
    if ( interval > 1) return interval + " years";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + " months";

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return interval + " days";

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return interval + " hours";

    interval = Math.floor(seconds / 60);
    if (interval > 1) return interval + " minutes";

    return Math.floor(seconds) + " seconds";
  }

  return "now";
}
